import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import AccordionWithArrow, { CustomContent, CustomTitle } from 'components/AccordionWithArrow';
import LoaderComponent from 'components/LoaderComponent';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import i18n from 'i18next';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Dropdown, DropdownItemProps, Grid, Icon, Input, Popup } from 'semantic-ui-react';
import {
  getExtendedSearchExerciseConfs,
  getExtendedSearchExerciseConfsForDoctor,
} from 'service/exerciseConfExtendedSearchService';
import { getAllClinicCodesWithDescription } from 'service/testConfExtendedSearchService';
import styled from 'styled-components';
import {
  ClinicDto,
  DomainDto,
  ExerciseConfExtendedSearchDto,
  ExerciseConfSearchRequest,
  ExerciseResultExtendedSearchDto,
  TestResultParamDto,
} from 'ts-types/api.types';
import { supportedLanguages } from 'util/localizationUtils';

const PopupTriggerContainer = styled.div`
    display: inline-block;
    position: relative;
    margin-inline: 5px;
`;

const StyledTextDisplayDiv = styled.div`
    display: inline-block;
    position: absolute;
    font-size: 17px;
    left: 40%;
    transform: translateX(-45%);
    top: 5%;
`;

const StyledPre = styled.div`
    white-space: pre-line;
    font: unset;
`;

const Label = styled.span`
    font-weight: bold;
    font-size: 15px;
`;

const FormBarLabel = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    margin-right: 5px;
`;

const ScrollableTsaGrid = styled.div`
    position: fixed;
    overflow: auto;
    top: 125px;
    bottom: 20px;
    width: calc(100% - 255px);
`;

const FormBarContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;

    .ui.checkbox {
        border-radius: 4px;
        margin: -4px -4px;
`;

export interface ExerciseSearchSettings {
  searchValues: Partial<ExerciseConfSearchRequest>;
  searchByExerciseResultParams: boolean;
  searchByExercises: boolean;
  searchByExerciseResults: boolean;
  searchByAll: boolean;
  expandAll: boolean | undefined;
  expandExercises: boolean | undefined;
  expandExerciseResults: boolean | undefined;
  expandExerciseResultParams: boolean | undefined;
  includeInactive: boolean;
  selectedClinics: Array<string>;
}

const ExerciseConfExtendedSearchView = () => {
  const [exerciseConfs, setExerciseConfs] = useState<{
    [index: string]: ExerciseConfExtendedSearchDto
  } | undefined>(undefined);
  const [selectedClinics, setSelectedClinics] = useState<Array<string>>([]);
  const [clinics, setClinics] = useState<Array<ClinicDto>>([]);
  const [langOptions, setLangOptions] = useState<DropdownItemProps[]>([]);
  const [filteredExerciseConfs, setFilteredExerciseConfs] = useState<{
    [index: string]: ExerciseConfExtendedSearchDto
  }>({});
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(false);
  const [searchSettings, setSearchSettings] = useState<ExerciseSearchSettings>({
    expandAll: false,
    expandExerciseResultParams: false,
    expandExerciseResults: false,
    expandExercises: false,
    searchByAll: true,
    searchByExerciseResultParams: false,
    searchByExerciseResults: false,
    searchByExercises: false,
    includeInactive: false,
    searchValues: { exerciseConfSearchTerm: '' },
    selectedClinics: [],
  });


  const isIphone = useIsIpadWidthOrBelow(430);
  const isIpad = useIsIpadWidthOrBelow();
  const isLaptop = useIsIpadWidthOrBelow(1600);

  const currentLanguage = i18n.language;
  const cancelTokenSource = axios.CancelToken.source();
  const { updateLanguage } = useAuthContext();
  const { language } = useAuthContext();
  const { t } = useTranslation('teresa');
  const { currentUser } = useAuthContext();
  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);
  const clinicOptions = clinics.map((clinic) => ({
    key: clinic.clinicCode,
    value: clinic.clinicCode,
    text: `${clinic.clinicCode} - ${clinic.name}`,
  }));

  const renderLabel = (label: any) => ({
    content: label.value,
  });


  useEffect(() => {
    const handler = setTimeout(() => {
      searchExerciseConfs();
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchSettings.searchValues.exerciseConfSearchTerm, exerciseConfs, formDataLoaded]);

  useEffect(() => {
    searchExerciseConfs();
  }, [formDataLoaded]);

  useEffect(() => {
    isSystemAdmin && getAllClinicCodesWithDescription(cancelTokenSource).then(r => setClinics(r));
    const updatedLangOptions = supportedLanguages.map((lang) => ({
      key: lang,
      value: lang,
      text: t(`language.${lang}`),
    }));
    setLangOptions(updatedLangOptions);

    isSystemAdmin
      ? getExtendedSearchExerciseConfs(cancelTokenSource).then(r => setExerciseConfs(r.exerciseConfExtendedSearchDtoMap))
      : getExtendedSearchExerciseConfsForDoctor(cancelTokenSource).then(r => setExerciseConfs(r.exerciseConfExtendedSearchDtoMap));

  }, []);

  useEffect(() => {
    if (exerciseConfs) {
      setFormDataLoaded(true);
    }
  }, [exerciseConfs]);

  const handleIndeterminateOrFalseExerciseResults = (newSearchSettings: ExerciseSearchSettings) => {
    if (newSearchSettings.expandExerciseResults == true) {newSearchSettings.expandExerciseResults = undefined;}
    return newSearchSettings.expandExerciseResults;
  };

  const handleIndeterminateOrFalseExerciseResultParams = (newSearchSettings: ExerciseSearchSettings) => {
    if (newSearchSettings.expandExerciseResultParams == true) {newSearchSettings.expandExerciseResultParams = undefined;}
    return newSearchSettings.expandExerciseResultParams;
  };

  const updateSearchSettings = (key: keyof ExerciseSearchSettings, value: any) => {
    const newSearchSettings = {
      ...searchSettings,
      [key]: value,
    };
    if (key != 'searchValues') {
      if (key == 'expandAll') {
        newSearchSettings.expandExercises = value;
        newSearchSettings.expandExerciseResults = value;
        newSearchSettings.expandExerciseResultParams = value;
        newSearchSettings.expandAll = value;
      }
      if (key == 'expandExercises'
        || key == 'expandExerciseResults'
        || key == 'expandExerciseResultParams'
        && value == false) {
        newSearchSettings.expandAll = undefined;
      }
      if (key == 'expandExercises' && value == false) {
        newSearchSettings.expandExerciseResults = false;
        newSearchSettings.expandExerciseResultParams = false;
      }
      if (key == 'expandExerciseResults' && value == false) {
        newSearchSettings.expandExerciseResultParams = false;
      }
      if (key == 'expandExercises' && value == undefined) {
        newSearchSettings.expandExerciseResults = handleIndeterminateOrFalseExerciseResults(newSearchSettings);
        newSearchSettings.expandExerciseResultParams = handleIndeterminateOrFalseExerciseResultParams(newSearchSettings);
      }
      if (key == 'expandExerciseResults' && value == undefined) {
        newSearchSettings.expandExerciseResultParams = handleIndeterminateOrFalseExerciseResultParams(newSearchSettings);
      }
      if ((key == 'searchByExercises'
          || key == 'searchByExerciseResults'
          || key == 'searchByExerciseResultParams')
        && value == true) {
        newSearchSettings.searchByAll = false;
      }
      if (key == 'searchByAll') {
        newSearchSettings.searchByAll = value;
        newSearchSettings.searchByExerciseResultParams = value ? !value : value;
        newSearchSettings.searchByExerciseResults = value ? !value : value;
        newSearchSettings.searchByExercises = !value;
      }
      if (!newSearchSettings.searchByExerciseResults
        && !newSearchSettings.searchByExercises
        && !newSearchSettings.searchByExerciseResultParams) {
        newSearchSettings.searchByAll = true;
      }
    }
    setSearchSettings(newSearchSettings);
  };

  const handleShowInactive = (value?: boolean) => {
    const newIncludeInactive = value ? value : !searchSettings.includeInactive;
    (value == undefined) && updateSearchSettings('includeInactive', newIncludeInactive);
  };

  const handleExpandAll = () => {
    const newExpandAll = !searchSettings.expandAll;
    updateSearchSettings('expandAll', newExpandAll);
    const updatedExerciseConfs: { [index: string]: ExerciseConfExtendedSearchDto } = { ...filteredExerciseConfs };
    Object.keys(filteredExerciseConfs).forEach(key => {
      const conf = filteredExerciseConfs[key];
      updatedExerciseConfs[key] = {
        ...conf,
        expanded: newExpandAll,
        exerciseResultMapExpanded: newExpandAll,
        exerciseResultMap: Object.keys(conf.exerciseResultMap).reduce((resultMap, resultKey) => {
          const result = conf.exerciseResultMap[resultKey];
          resultMap[resultKey] = {
            ...result,
            expanded: newExpandAll,
            exerciseResultParamsExpanded: newExpandAll,
          };
          return resultMap;
        }, {} as { [index: string]: ExerciseResultExtendedSearchDto }),
      };
    });
    setFilteredExerciseConfs(updatedExerciseConfs);
  };

  const handleExpandExercises = () => {
    const newExpandExercises = !searchSettings.expandExercises;
    updateSearchSettings('expandExercises', newExpandExercises);
    const updatedExerciseConfs = { ...filteredExerciseConfs };
    Object.keys(updatedExerciseConfs).forEach(key => {
      updatedExerciseConfs[key] = {
        ...updatedExerciseConfs[key],
        expanded: newExpandExercises,
      };
    });
    setFilteredExerciseConfs(updatedExerciseConfs);
  };

  const handleExpandExerciseResults = () => {
    const newExpandExerciseResults = !searchSettings.expandExerciseResults;
    updateSearchSettings('expandExerciseResults', newExpandExerciseResults);
    const updatedExerciseConfs = { ...filteredExerciseConfs };
    Object.keys(updatedExerciseConfs).forEach(key => {
      const conf = updatedExerciseConfs[key];
      updatedExerciseConfs[key] = {
        ...conf,
        exerciseResultMapExpanded: newExpandExerciseResults,
        exerciseResultMap: Object.keys(conf.exerciseResultMap).reduce((resultMap, resultKey) => {
          const result = conf.exerciseResultMap[resultKey];
          resultMap[resultKey] = {
            ...result,
            expanded: newExpandExerciseResults,
          };
          return resultMap;
        }, {} as { [index: string]: ExerciseResultExtendedSearchDto }),
      };
    });
    setFilteredExerciseConfs(updatedExerciseConfs);
  };

  const handleExpandExerciseResultParams = () => {
    const newExpandExerciseResultParams = !searchSettings.expandExerciseResultParams;
    updateSearchSettings('expandExerciseResultParams', newExpandExerciseResultParams);
    const updatedExerciseConfs = { ...filteredExerciseConfs };
    Object.keys(updatedExerciseConfs).forEach(key => {
      const conf = updatedExerciseConfs[key];
      if (conf.exerciseResultMapExpanded) {
        updatedExerciseConfs[key] = {
          ...conf,
          exerciseResultMap: Object.keys(conf.exerciseResultMap).reduce((resultMap, resultKey) => {
            const result = conf.exerciseResultMap[resultKey];
            if (result.expanded) {
              resultMap[resultKey] = {
                ...result,
                exerciseResultParamsExpanded: newExpandExerciseResultParams,
              };
            } else {
              resultMap[resultKey] = { ...result };
            }
            return resultMap;
          }, {} as { [index: string]: ExerciseResultExtendedSearchDto }),
        };
      }
    });
    setFilteredExerciseConfs(updatedExerciseConfs);
  };

  const searchExerciseConfs = useCallback(() => {
    const newExerciseConfs = { ...exerciseConfs };

    if (!searchSettings.searchValues.exerciseConfSearchTerm) {
      const filtered = Object.keys(newExerciseConfs).map((key) => {
        let testConf = newExerciseConfs[key];
        if (filteredExerciseConfs[key]) {
          testConf = filteredExerciseConfs[key];
        }
        return testConf;
      });
      let res = _.keyBy(filtered, 'id');
      setFilteredExerciseConfs(res);
      return;
    }

    const filtered = Object.keys(newExerciseConfs).map((key) => {
      let exerciseConf = newExerciseConfs[key];
      if (filteredExerciseConfs[key]) {
        exerciseConf = filteredExerciseConfs[key];
      }

      const { exerciseResultMap } = exerciseConf;
      const newExerciseResultMap: { [p: string]: ExerciseResultExtendedSearchDto } = { ...exerciseResultMap };
      let exerciseConfMatches = false;
      let resultMatches = false;
      let resultParamMatches = false;

      if (searchSettings.searchByAll || searchSettings.searchByExercises) {
        if (getExerciseConfDescription(exerciseConf)
        .toLowerCase()
        .includes(searchSettings.searchValues.exerciseConfSearchTerm!.toLowerCase())) {
          exerciseConfMatches = true;
        }
      }

      if (searchSettings.searchByAll || searchSettings.searchByExerciseResults) {
        Object.keys(exerciseResultMap).forEach(resultKey => {
          const result = exerciseResultMap[resultKey];
          if (getExerciseResultDescription(result)
          .toLowerCase()
          .includes(searchSettings.searchValues.exerciseConfSearchTerm!.toLowerCase())) {
            resultMatches = true;
            newExerciseResultMap[resultKey] = { ...result, expanded: true };
          }
        });
      }

      if (searchSettings.searchByAll || searchSettings.searchByExerciseResultParams) {
        Object.keys(exerciseResultMap).forEach(resultKey => {
          const result = exerciseResultMap[resultKey];
          if (result.exerciseResultParamMap && Object.values(result.exerciseResultParamMap).some(param => {
            return getExerciseResultParamDescription(param)
            .toLowerCase()
            .includes(searchSettings.searchValues.exerciseConfSearchTerm!.toLowerCase());
          })) {
            resultParamMatches = true;
            newExerciseResultMap[resultKey] = { ...result, exerciseResultParamsExpanded: true, expanded: true };
          }
        });
      }

      if (resultMatches || resultParamMatches) {
        return {
          ...exerciseConf,
          expanded: true,
          exerciseResultMapExpanded: true,
          exerciseResultMap: { ...newExerciseResultMap },
        };
      } else if (exerciseConfMatches) {
        return { ...exerciseConf, expanded: true, exerciseResultMap: { ...newExerciseResultMap } };
      }

      return null;
    }).filter(item => item !== null).reduce((acc, key) => {
      const a = key as ExerciseConfExtendedSearchDto;
      acc[a!.id] = a;
      return acc;
    }, {} as { [index: string]: ExerciseConfExtendedSearchDto });

    setFilteredExerciseConfs(filtered);
  }, [language, exerciseConfs, searchSettings]);

  const highlightText = (fullText: string, searchTerm: string) => {
    if (!searchTerm) return fullText;
    const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');
    const parts = fullText.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part,
    );
  };

  const instructionContent = (instruction: string) => {
    return (
      <StyledPre>
        {instruction}
      </StyledPre>
    );
  };

  const handleClinicDropdownChange = (value: any) => {
    if (Array.isArray(value)) {
      const selected = value as string[];
      setSelectedClinics(selected);
    }
  };

  const getExerciseResultParamDescription = (exerciseResultParam: TestResultParamDto) => {
    const texts: { [key: string]: string } = {
      'de': exerciseResultParam.description || '',
      'en': exerciseResultParam.descriptionEn || exerciseResultParam.description || '',
      'fr': exerciseResultParam.descriptionFr || exerciseResultParam.description || '',
      'it': exerciseResultParam.descriptionIt || exerciseResultParam.description || '',
    };
    return texts[language];
  };

  const getDomainDescription = (domain: DomainDto) => {
    const texts: { [key: string]: string } = {
      'de': domain.description || '',
      'en': domain.descriptionEn || domain.description || '',
      'fr': domain.descriptionFr || domain.description || '',
      'it': domain.descriptionIt || domain.description || '',
    };
    return texts[language];
  };

  const getExerciseResultDescription = (exerciseResult: ExerciseResultExtendedSearchDto) => {
    const texts: { [key: string]: string } = {
      'de': exerciseResult.description || '',
      'en': exerciseResult.descriptionEn || exerciseResult.description || '',
      'fr': exerciseResult.descriptionFr || exerciseResult.description || '',
      'it': exerciseResult.descriptionIt || exerciseResult.description || '',
    };
    return texts[language];
  };

  const getExerciseConfDescription = (exerciseConf: ExerciseConfExtendedSearchDto) => {
    const texts: { [key: string]: string } = {
      'de': exerciseConf.description || '',
      'en': exerciseConf.descriptionEn || exerciseConf.description || '',
      'fr': exerciseConf.descriptionFr || exerciseConf.description || '',
      'it': exerciseConf.descriptionIt || exerciseConf.description || '',
    };
    return texts[language];
  };

  const getInstructionTherapist = (exerciseConf: ExerciseConfExtendedSearchDto) => {
    const texts: { [key: string]: string } = {
      'de': exerciseConf.instructionTherapist || '',
      'en': exerciseConf.instructionTherapistEn || exerciseConf.instructionTherapist || '',
      'fr': exerciseConf.instructionTherapistFr || exerciseConf.instructionTherapist || '',
      'it': exerciseConf.instructionTherapistIt || exerciseConf.instructionTherapist || '',
    };
    return texts[language];
  };

  const getInstructionPatient = (exerciseConf: ExerciseConfExtendedSearchDto) => {
    const texts: { [key: string]: string } = {
      'de': exerciseConf.instructionPatient || '',
      'en': exerciseConf.instructionPatientEn || exerciseConf.instructionPatient || '',
      'fr': exerciseConf.instructionPatientFr || exerciseConf.instructionPatient || '',
      'it': exerciseConf.instructionPatientIt || exerciseConf.instructionPatient || '',
    };
    return texts[language];
  };

  const instructionPopupTrigger = (buttonText: string, instructionTrigger?: boolean) => {

    if (!instructionTrigger) {
      return false;
    }

    return (
      <PopupTriggerContainer>
        <Icon name='circle outline'
              style={{
                fontSize: '1.55rem',
              }}
        >
        </Icon>
        <StyledTextDisplayDiv>
          {buttonText}
        </StyledTextDisplayDiv>
      </PopupTriggerContainer>
    );
  };

  const instructions = (exerciseConf: ExerciseConfExtendedSearchDto) => {
    return (
      <>
        {
          exerciseConf.instructionTherapist
            ? <Popup
              trigger={instructionPopupTrigger(t('testconf.therapistInstruction'), !!exerciseConf?.instructionTherapist)}
              content={instructionContent(getInstructionTherapist(exerciseConf))}
              on='hover'
              size='large'
              position='bottom right'
              wide='very'
            />
            : <> --- </>
        }
        <> /</>
        {
          exerciseConf.instructionPatient
            ? <Popup
              trigger={instructionPopupTrigger(t('testconf.patientInstruction'), !!exerciseConf?.instructionPatient)}
              content={instructionContent(getInstructionPatient(exerciseConf))}
              on='hover'
              size='large'
              position='bottom right'
              wide='very'
            />
            : <> --- </>
        }

      </>
    );
  };

  const handleIndeterminateCheckBox = (flag: boolean, value: keyof ExerciseSearchSettings) => {
    if (searchSettings.expandAll && flag) {
      updateSearchSettings('expandAll', undefined);
    }
    if (flag) {
      updateSearchSettings(value, undefined);
    }
  };

  const handleExerciseConfOnExpand = (id: string) => {
    setFilteredExerciseConfs(prevExerciseConfs => {
      const updatedExerciseConfs = { ...prevExerciseConfs };
      updatedExerciseConfs[id] = {
        ...updatedExerciseConfs[id],
        expanded: !updatedExerciseConfs[id].expanded,
      };
      return updatedExerciseConfs;
    });
    handleIndeterminateCheckBox(searchSettings.expandExercises!, 'expandExercises');
  };

  const handleExerciseConfExerciseResultsOnExpand = (id: string) => {
    setFilteredExerciseConfs(prevExerciseConfs => {
      const updatedExerciseConfs = { ...prevExerciseConfs };
      updatedExerciseConfs[id] = {
        ...updatedExerciseConfs[id],
        exerciseResultMapExpanded: !updatedExerciseConfs[id].exerciseResultMapExpanded,
      };
      return updatedExerciseConfs;
    });
    handleIndeterminateCheckBox(searchSettings.expandExerciseResults!, 'expandExerciseResults');
  };

  const handleExerciseResultOnExpand = (exerciseResultid: string, exerciseConfId: string) => {
    setFilteredExerciseConfs(prevExerciseConfs => {
      const updatedExerciseConfs = { ...prevExerciseConfs };
      const updatedExerciseResults = { ...updatedExerciseConfs[exerciseConfId].exerciseResultMap };
      updatedExerciseResults[exerciseResultid] = {
        ...updatedExerciseResults[exerciseResultid],
        expanded: !updatedExerciseResults[exerciseResultid].expanded,
      };
      updatedExerciseConfs[exerciseConfId] = {
        ...updatedExerciseConfs[exerciseConfId],
        exerciseResultMap: { ...updatedExerciseResults },
      };
      return updatedExerciseConfs;
    });
    handleIndeterminateCheckBox(searchSettings.expandExerciseResults!, 'expandExerciseResults');
  };

  const handleExerciseResultParamsOnExpand = (exerciseResultid: string, exerciseConfIndex: string) => {
    setFilteredExerciseConfs(prevExerciseConfs => {
      const updatedExerciseConfs = { ...prevExerciseConfs };
      const updatedExerciseResults = { ...updatedExerciseConfs[exerciseConfIndex].exerciseResultMap };
      updatedExerciseResults[exerciseResultid] = {
        ...updatedExerciseResults[exerciseResultid],
        exerciseResultParamsExpanded: !updatedExerciseResults[exerciseResultid].exerciseResultParamsExpanded,
      };
      updatedExerciseConfs[exerciseConfIndex] = {
        ...updatedExerciseConfs[exerciseConfIndex],
        exerciseResultMap: { ...updatedExerciseResults },
      };
      return updatedExerciseConfs;
    });
    handleIndeterminateCheckBox(searchSettings.expandExerciseResultParams!, 'expandExerciseResultParams');
  };

  const renderExerciseResultParamContent = useCallback((exerciseResult: ExerciseResultExtendedSearchDto) => {
    const filteredExerciseResultParams = Object.keys(exerciseResult.exerciseResultParamMap).filter((exerciseResultParamId) => {
      const exerciseResultParam = exerciseResult.exerciseResultParamMap[exerciseResultParamId];
      return searchSettings.includeInactive || exerciseResultParam.active;
    });
    return filteredExerciseResultParams.map((exerciseResultParamId: string) => {
      const exerciseResultParam: TestResultParamDto = exerciseResult.exerciseResultParamMap[exerciseResultParamId];
      return <div style={{ marginTop: '5px' }} key={exerciseResultParam.id}>
        {(searchSettings.searchByExerciseResultParams || searchSettings.searchByAll)
          ? highlightText(
            getExerciseResultParamDescription(exerciseResultParam),
            searchSettings.searchValues.exerciseConfSearchTerm ? searchSettings.searchValues.exerciseConfSearchTerm : '',
          )
          : getExerciseResultParamDescription(exerciseResultParam)} / {exerciseResultParam.unit} / {exerciseResultParam.code}
      </div>;
    });
  }, [exerciseConfs, searchSettings, language]);

  const renderExerciseResultContent = useCallback((exerciseConf: ExerciseConfExtendedSearchDto, exerciseConfId: string) => {
    const filteredExerciseResults = Object.keys(exerciseConf.exerciseResultMap).filter((exerciseResultId) => {
      const exerciseResult = exerciseConf.exerciseResultMap[exerciseResultId];
      return searchSettings.includeInactive || exerciseResult.active;
    });
    return filteredExerciseResults.map((exerciseResultId: string, exerciseResultIx: number) => {
      const exerciseResult: ExerciseResultExtendedSearchDto = exerciseConf.exerciseResultMap[exerciseResultId];
      const paramsMapLength = Object.keys(exerciseResult.exerciseResultParamMap).filter((key) => {
        return searchSettings.includeInactive || exerciseResult.exerciseResultParamMap[key].active;
      }).length;
      return <AccordionWithArrow expanded={exerciseResult.expanded}
                                 onExpand={() => handleExerciseResultOnExpand(exerciseResultId, exerciseConfId)}>
        <CustomTitle>
          {(searchSettings.searchByExerciseResults || searchSettings.searchByAll)
            ? highlightText(getExerciseResultDescription(exerciseResult), searchSettings.searchValues.exerciseConfSearchTerm
              ? searchSettings.searchValues.exerciseConfSearchTerm
              : '',
            )
            : getExerciseResultDescription(exerciseResult)}
          {exerciseResult.domain != null && <> / {getDomainDescription(exerciseResult.domain)}</>}
        </CustomTitle>
        <CustomContent>
          {
            paramsMapLength > 0 &&
            <AccordionWithArrow
              expanded={exerciseResult.exerciseResultParamsExpanded}
              onExpand={() => handleExerciseResultParamsOnExpand(exerciseResultId, exerciseConfId)}
            >
              <CustomTitle>
                <Label>{t('testresult.params')} ({paramsMapLength}) : </Label>
              </CustomTitle>
              <CustomContent>
                {renderExerciseResultParamContent(exerciseResult)}
              </CustomContent>
            </AccordionWithArrow>
          }
        </CustomContent>
      </AccordionWithArrow>;
    });
  }, [exerciseConfs, searchSettings, filteredExerciseConfs, language]);

  const renderExerciseConfigRows = useCallback(() => {
    const map = { ...filteredExerciseConfs };
    const filteredExercises = Object.keys(map).filter((exerciseConfId) => {
      const exerciseConf = map[exerciseConfId];
      return (searchSettings.includeInactive || exerciseConf.active) &&
        (selectedClinics.length < 1 || exerciseConf.clinics.some(clinic => selectedClinics.includes(clinic)));
    });
    return (
      <ScrollableTsaGrid style={
        isIphone ? { marginTop: '130px', width: '100%' } :
          isIpad ? { marginTop: '60px', width: 'calc(100% - 35px)' } :
            { marginTop: '40px', width: 'calc(100% - 245px)' }}>
        {filteredExercises.map((exerciseConfId) => {
          const exerciseConf = map[exerciseConfId];
          const exerciseResultMapLength = Object.keys(exerciseConf.exerciseResultMap).filter((key) => {
            return searchSettings.includeInactive || exerciseConf.exerciseResultMap[key].active;
          }).length;

          return (
            <AccordionWithArrow
              key={exerciseConfId}
              expanded={exerciseConf.expanded}
              onExpand={() => handleExerciseConfOnExpand(exerciseConfId)}
            >
              <CustomTitle>
                {(searchSettings.searchByExercises || searchSettings.searchByAll)
                  ? highlightText(getExerciseConfDescription(exerciseConf), searchSettings.searchValues.exerciseConfSearchTerm
                    ? searchSettings.searchValues.exerciseConfSearchTerm
                    : '',
                  )
                  : getExerciseConfDescription(exerciseConf)}
                <> /</>
                {instructions(exerciseConf)}
              </CustomTitle>
              <CustomContent>
                {exerciseResultMapLength > 0 &&
                  <AccordionWithArrow expanded={exerciseConf.exerciseResultMapExpanded}
                                      onExpand={() => handleExerciseConfExerciseResultsOnExpand(exerciseConfId)}>
                    <CustomTitle>
                      <Label>{t('patientExercising.exerciseResultTitle')} ({exerciseResultMapLength}): </Label>
                    </CustomTitle>
                    <CustomContent>
                      {renderExerciseResultContent(exerciseConf, exerciseConfId)}
                    </CustomContent>
                  </AccordionWithArrow>
                }
              </CustomContent>
            </AccordionWithArrow>
          );
        })}
      </ScrollableTsaGrid>
    );
  }, [
    exerciseConfs,
    filteredExerciseConfs,
    selectedClinics,
    searchSettings,
    language,
  ]);
  {t('clinic.config.exerciseConf');}
  const renderFormBar = () => {
    const expandAll = searchSettings.expandAll;
    const expandExercises = searchSettings.expandExercises;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={isIphone ? 8 : isIpad ? 8 : isLaptop ? 4 : 5}>
            <h2>{t('clinic.config.exerciseConf')}</h2>
          </Grid.Column>
          <Grid.Column width={isIphone ? 8 : isIpad ? 8 : 3} floated={'right'}>
            {
              isSystemAdmin && <FormBarContainer>
                <Checkbox
                  id='includeInactive'
                  checked={searchSettings.includeInactive}
                  onChange={() => handleShowInactive()}
                />
                <label htmlFor='includeInactive'>{t('button.includeInactive')}</label>
              </FormBarContainer>}
          </Grid.Column>
          <Grid.Column width={isIphone ? 16 : isIpad ? 2 : 1}>
            <FormBarLabel>
              <div>{t('testconf.expand')}:</div>
            </FormBarLabel>
          </Grid.Column>
          <Grid.Column width={isIphone ? 3 : isIpad ? 2 : 1}>
            <FormBarContainer>
              <Checkbox
                indeterminate={expandAll == undefined}
                id='expandAll'
                checked={searchSettings.expandAll}
                onChange={handleExpandAll}
              />
              <label htmlFor='expandAll'>{t('sex.ALL')}</label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 5 : isIpad ? 3 : 2}>
            <FormBarContainer>
              <Checkbox
                indeterminate={expandExercises == undefined}
                id='expandExercises'
                checked={searchSettings.expandExercises}
                onChange={handleExpandExercises}
              />
              <label htmlFor='expandExercises'>{t('clinic.config.exerciseConf')}</label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 8 : isIpad ? 5 : isLaptop ? 3 : 2}>
            <FormBarContainer>
              <Checkbox
                indeterminate={searchSettings.expandExerciseResults == undefined}
                id='expandExerciseResults'
                checked={searchSettings.expandExerciseResults}
                onChange={handleExpandExerciseResults}
              />
              <label
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                htmlFor='expandExerciseResults'
              >
                {t('patientExercising.exerciseResultTitle')}
              </label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 7 : isIpad ? 4 : isLaptop ? 2 : 2}>
            <FormBarContainer>
              <Checkbox
                indeterminate={searchSettings.expandExerciseResultParams == undefined}
                id='expandExerciseResultParams'
                checked={searchSettings.expandExerciseResultParams}
                onChange={handleExpandExerciseResultParams}
              />
              <label
                htmlFor='expandExerciseResultParams'
              >
                {t('patientTesting.params')}
              </label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column
            width={isIphone ? 16 : isIpad ? 2 : 1}
            floated={isIphone || isIpad ? undefined : 'right'}
          >
            <FormBarLabel>
              <div>{t('button.search')}:</div>
            </FormBarLabel>
          </Grid.Column>
          <Grid.Column width={isIphone ? 3 : isIpad ? 2 : 1}>
            <FormBarContainer>
              <Checkbox
                id='searchByAll'
                checked={searchSettings.searchByAll}
                onChange={() => {
                  updateSearchSettings('searchByExercises', false);
                  updateSearchSettings('searchByExerciseResults', false);
                  updateSearchSettings('searchByAll', !searchSettings.searchByAll);
                }}
              />
              <label htmlFor='searchByAll'>{t('sex.ALL')}</label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 5 : isIpad ? 3 : 2}>
            <FormBarContainer>
              <Checkbox
                id='searchByExercises'
                checked={searchSettings.searchByExercises}
                onChange={() => {
                  if (
                    !searchSettings.searchByExerciseResults
                    && searchSettings.searchByExercises
                    && !searchSettings.searchByExerciseResultParams
                  ) {
                    updateSearchSettings('searchByAll', true);
                  } else updateSearchSettings('searchByAll', false);
                  updateSearchSettings('searchByExercises', !searchSettings.searchByExercises);
                }}
              />
              <label htmlFor='searchByExercises'>{t('clinic.config.exerciseConf')}</label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 8 : isIpad ? 5 : isLaptop ? 3 : 2}>
            <FormBarContainer>
              <Checkbox
                id='searchByExerciseResults'
                checked={searchSettings.searchByExerciseResults}
                onChange={() => {
                  if (
                    searchSettings.searchByExerciseResults
                    && !searchSettings.searchByExercises
                    && !searchSettings.searchByExerciseResultParams
                  ) {
                    updateSearchSettings('searchByAll', true);
                  } else updateSearchSettings('searchByAll', false);
                  updateSearchSettings('searchByExerciseResults', !searchSettings.searchByExerciseResults);
                }}
              />
              <label
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                htmlFor='searchByExerciseResults'
              >
                {t('patientExercising.exerciseResultTitle')}
              </label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 7 : isIpad ? 4 : isLaptop ? 2 : 2}>
            <FormBarContainer>
              <Checkbox
                id='searchByExerciseResultParams'
                checked={searchSettings.searchByExerciseResultParams}
                onChange={() => {
                  if (
                    !searchSettings.searchByExerciseResults
                    && !searchSettings.searchByExercises
                    && searchSettings.searchByExerciseResultParams
                  ) {
                    updateSearchSettings('searchByAll', true);
                  } else updateSearchSettings('searchByAll', false);
                  updateSearchSettings('searchByExerciseResultParams', !searchSettings.searchByExerciseResultParams);
                }}
              />
              <label htmlFor='searchByExerciseResultParams'>{t('patientTesting.params')}</label>
            </FormBarContainer>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '0px' }}>
          <Grid.Column width={isIphone ? 8 : isIpad ? 6 : 3} floated={isIphone ? 'left' : 'right'}
                       style={{ paddingRight: '0px' }}>
            {isSystemAdmin && <FormBarContainer>
              <Dropdown
                style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', width: '300px' }}
                placeholder={t('clinic.selectClinics')}
                fluid
                multiple
                search
                selection
                options={clinicOptions}
                value={selectedClinics}
                onChange={(e, { value }) => handleClinicDropdownChange(value)}
                renderLabel={renderLabel}
              />
            </FormBarContainer>}
          </Grid.Column>
          <Grid.Column width={isIphone ? 8 : isIpad ? 5 : isLaptop ? 3 : 2}>
            <Input
              style={{ marginLeft: '10px' }}
              type='text'
              placeholder={t('button.search')}
              value={searchSettings.searchValues.exerciseConfSearchTerm}
              onChange={(e) => updateSearchSettings('searchValues', {
                ...searchSettings.searchValues,
                exerciseConfSearchTerm: e.target.value,
              })}
            />
          </Grid.Column>
          <Grid.Column width={isIphone ? 1 : isIpad ? 5 : isLaptop ? 2 : 1} verticalAlign={'middle'}>
            {!isIphone &&
              <Dropdown
                style={{
                  marginLeft: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className='language-menu-item'
                text={t(`language.${currentLanguage}`)}
                direction='right'
                icon='chevron up'
                value={currentLanguage}
                options={langOptions}
                onChange={(evt, data) => {
                  const value = data.value as string;
                  updateLanguage(value);
                }}
                selectOnBlur
                selectOnNavigation={false}
                fluid
              />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <div>
      {renderFormBar()}
      {formDataLoaded
        ? <React.Fragment>
          {renderExerciseConfigRows()}
        </React.Fragment>
        : <LoaderComponent message={t('general.loading')} />
      }
    </div>
  );
};
export default ExerciseConfExtendedSearchView;