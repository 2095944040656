import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React from 'react';
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const InputViewStyle = styled.div<{ isIphone?: boolean }>`
    padding-left: ${({ isIphone }) => (isIphone ? '.5rem' : '1rem')};
    padding-right: ${({ isIphone }) => (isIphone ? '.2rem' : '')};
    font-weight: 600;
    min-height: 35px;
    line-height: 35px;
    background-color: var(--very-light-gray);
    border-radius: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const InputView = (props: DividerProps) => {
  const { children, ...rest } = props;
  const isIphone = useIsIpadWidthOrBelow(430);
  return (
    <InputViewStyle isIphone={isIphone} {...rest}>
      {children}
    </InputViewStyle>
  );
};

export default InputView;
