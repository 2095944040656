import { useAuthContext } from 'auth/AuthContext';
import React, { Fragment, useMemo } from 'react';
import { DisplayImage } from 'routes/patienttesting/StartTestingForm';
import styled from 'styled-components';
import { DisplayTestImageDto, UpsertPatientTestImageNoteDto } from 'ts-types/api.types';
import { noop } from 'util/functionUtils';
import { isKeyCheck } from 'util/keyUtils';

const ImageContainer = styled.div`
  position: relative;
  width: 100%; /* Set the container to be the full width of its parent element */
  height: auto; /* Set the height to be automatically calculated based on the aspect ratio of the image */
  display: block; /* Make the container a block-level element */
  overflow: hidden; /* Hide any part of the image that extends beyond the container */
  
  & > img {
    width: 100%; /* Set the width of the image to be the full width of the container */
    height: auto; /* Set the height to be automatically calculated based on the aspect ratio of the image */
  }
`;

const StyledTextArea = styled.textarea<{ offsetX: number, offsetY: number }>`
  position: absolute;
  left: ${(props) => props.offsetX}%;
  top: ${(props) => props.offsetY}%;
  background: transparent;
  border: none;
`;

const StyledTextContainer = styled.div`
  position: absolute;
  cursor: pointer;
  font-weight: bold;
  white-space: pre-wrap;
  
  & > p {
    word-break: break-all;
  }
`;

interface Props {
  testImage: DisplayTestImageDto,
  notes: Partial<UpsertPatientTestImageNoteDto>[],
  saveNote: (upsertPatientTestImageNoteDto: Partial<UpsertPatientTestImageNoteDto>, index?: number) => void,
}

const TextInputOverImage = ({ testImage, notes, saveNote }: Props) => {

  const { language } = useAuthContext();

  const inputRefs = React.useRef([]);
  const isDraggingRef = React.useRef(false);

  const [inputFields, setInputFields] = React.useState<Partial<UpsertPatientTestImageNoteDto>[]>([]);
  const [isInputFocused, setIsInputFocused] = React.useState<boolean>(false);
  const [activeIndex, setActiveIndex] = React.useState<number | undefined>(undefined);
  const [draggingIndex, setDraggingIndex] = React.useState<number | null>(null);
  const [dragStart, setDragStart] = React.useState<{ x: number; y: number } | null>(null);

  React.useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, notes.length);
    setInputFields(notes);
  }, [notes]);

  React.useEffect(() => {
    if (draggingIndex !== null) {
      document.body.classList.add('no-select');
      window.addEventListener('mousemove', handlePointerMove);
      window.addEventListener('mouseup', handlePointerUp);

      window.addEventListener('touchmove', handlePointerMove, { passive: false });
      window.addEventListener('touchend', handlePointerUp);
    }

    return () => {
      document.body.classList.remove('no-select');
      window.removeEventListener('mousemove', handlePointerMove);
      window.removeEventListener('mouseup', handlePointerUp);

      window.removeEventListener('touchmove', handlePointerMove);
      window.removeEventListener('touchend', handlePointerUp);
    };
  }, [draggingIndex, dragStart]);

  const handlePointerDown = (index: number) => (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation();

    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

    setDraggingIndex(index);
    setDragStart({ x: clientX, y: clientY });
  };

  const handlePointerMove = (e: MouseEvent | TouchEvent) => {
    if ('touches' in e) e.preventDefault();
    const clientX = 'touches' in e ? e.touches[0].clientX : e.clientX;
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;

    if (draggingIndex !== null && dragStart) {
      const container = document.querySelector<HTMLDivElement>('div[data-image-container]');
      if (!container) return;

      const bounds = container.getBoundingClientRect();
      const deltaX = clientX - dragStart.x;
      const deltaY = clientY - dragStart.y;

      setInputFields(prevFields => {
        const updated = [...prevFields];
        const field = updated[draggingIndex];
        if (!field) return prevFields;

        const currentLeft = (field.offsetX || 0) * bounds.width / 100;
        const currentTop = (field.offsetY || 0) * bounds.height / 100;

        const newLeft = currentLeft + deltaX;
        const newTop = currentTop + deltaY;

        const newOffsetX = (newLeft / bounds.width) * 100;
        const newOffsetY = (newTop / bounds.height) * 100;

        field.offsetX = Math.max(0, Math.min(newOffsetX, 100));
        field.offsetY = Math.max(0, Math.min(newOffsetY, 100));

        return updated;
      });

      setDragStart({ x: clientX, y: clientY });
      isDraggingRef.current = true;
    }
  };

  const handlePointerUp = () => {
    if (draggingIndex !== null) {
      saveNote(inputFields[draggingIndex], draggingIndex);
    }
    setDraggingIndex(null);
    setDragStart(null);

    setTimeout(() => {
      isDraggingRef.current = false;
    }, 50);
  };

  const handleClick = (event: any) => {

    const { offsetX, offsetY } = event.nativeEvent;
    const { offsetHeight, offsetWidth } = event.target;

    if (inputRefs.current) {
      // @ts-ignore
      inputRefs.current.push(React.createRef());
    }

    const newArr: any = [
      ...inputFields,
      {
        testImageId: testImage.id,
        height: offsetHeight,
        width: offsetWidth,
        offsetX: offsetX / offsetWidth * 100,
        offsetY: offsetY / offsetHeight * 100,
        note: '',
      },
    ];
    setInputFields(newArr);
    setIsInputFocused(true);
    setActiveIndex(newArr.length - 1);
  };

  const handleChange = (index: number) => (event: any) => {
    setInputFields((prevInputFields) => {
      const newInputFields = [...prevInputFields];
      newInputFields[index].note = event.target.value;
      return newInputFields;
    });
  };

  const handleBlur = () => {
    saveNote(inputFields[activeIndex!], activeIndex);
    setIsInputFocused(false);
    setActiveIndex(undefined);
  };

  const handleEdit = (index: number) => (event: any) => {
    if (isDraggingRef.current) return;

    if (!inputRefs.current[index]) {
      // @ts-ignore
      inputRefs.current.push(React.createRef());
    }

    setActiveIndex(index);
    setIsInputFocused(true);
  };

  const onEscKeyDown = (event: any) => {
    event.persist();
    const isKey = isKeyCheck(event);

    // @ts-ignore
    if (isKey.escape) {
      handleBlur();
    }
  };

  React.useEffect(() => {
    if (activeIndex !== undefined && activeIndex >= 0) {
      if (isInputFocused && inputRefs.current[activeIndex]) {
        // @ts-ignore
        inputRefs.current[activeIndex].focus();
      }
    }
  }, [activeIndex, isInputFocused, inputRefs]);

  const imageViewMap = useMemo((): { [lang: string]: DisplayImage } => {

    if (testImage) {
      const defaultImage = { image: testImage.imageView, fileType: testImage.imageFileType };
      return {
        'de': defaultImage,
        'en': { image: testImage.imageViewEn || testImage.imageView, fileType: testImage.imageEnFileType || testImage.imageFileType },
        'fr': { image: testImage.imageViewFr || testImage.imageView, fileType: testImage.imageFrFileType || testImage.imageFileType },
        'it': { image: testImage.imageViewIt || testImage.imageView, fileType: testImage.imageItFileType || testImage.imageFileType },
      };
    }

    return {};

  }, [language, testImage]);

  return (
      <ImageContainer data-image-container>
        <img src={`data:${imageViewMap[language].fileType};base64, ${imageViewMap[language].image}`}
             alt=''
             onClick={isInputFocused ? noop : handleClick}
        />
      {inputFields.map((field: Partial<UpsertPatientTestImageNoteDto>, index: number) => {
        if (field && testImage.id === field.testImageId) {
          const note = field.note;
          return (
            <div key={index}>
              {index === activeIndex && isInputFocused ? (
                <StyledTextArea
                  rows={4}
                  cols={20}
                  wrap='hard'
                  value={note}
                  onChange={handleChange(index)}
                  // @ts-ignore
                  ref={el => inputRefs.current[index] = el}
                  onBlur={handleBlur}
                  onKeyDown={onEscKeyDown}
                  offsetX={field.offsetX ? field.offsetX : 0}
                  offsetY={field.offsetY ? field.offsetY : 0}
                />
              ) : (
                <StyledTextContainer
                  style={{
                    left: `${field.offsetX ?? 0}%`,
                    top: `${field.offsetY ?? 0}%`,
                  }}
                  onClick={handleEdit(index)}
                  onMouseDown={handlePointerDown(index)}
                  onTouchStart={handlePointerDown(index)}
                >
                  <p>{note}</p>
                </StyledTextContainer>
              )}
            </div>
          )
        } else {
          return <Fragment key={index} />
        }
      })}
    </ImageContainer>
  );
};

export default TextInputOverImage;
