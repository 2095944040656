import { useAuthContext } from 'auth/AuthContext';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import TsaGrid from 'components/TsaGrid';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import _ from 'lodash';
import React, { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Header, Icon, Input, InputOnChangeData, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { PatientExercisingQuestionSetDto, PatientTestingQuestionSetDto, QuestionSetDto } from 'ts-types/api.types';

const StyledIcon = styled(Icon)`
  cursor: pointer;
  border-radius: 4px;
`;

const StyledBtnContainer = styled.div`
  margin-bottom: 1.5rem;
  white-space: nowrap !important;
`;

const StyledButtons = styled.div<{ isIphone?: boolean}>`
  padding: 0 !important;
  margin: 0 0 0 4px !important;
  margin-top: ${({ isIphone }) => (isIphone ? '75px' : '40px')} !important;  
`;

const StyledSelectableTable = styled.div`
  flex: 50 50 auto;

  .header-row {
      align-items: baseline !important;

      .search-field {
          padding-right: 0 !important;
      }
  }

    @media only screen and (max-width: 767px) {
        & .ui.table:not(.unstackable) tr>td {
            width: 140px !important;
        }
    }
`;

const StyledTableRow = styled(Table.Row)<{ isIphone?: boolean}>`
    cursor: pointer;

    & td, td .wout-overflow {
        width: ${({ isIphone }) => (isIphone ? '120px' : '')} !important;
        overflow: ${({ isIphone }) => (isIphone ? 'hidden' : '')} !important;
        white-space: ${({ isIphone }) => (isIphone ? 'nowrap' : '')} !important;
        text-overflow: ${({ isIphone }) => (isIphone ? 'ellipsis' : '')} !important;
    }
`;

const ScrollableTableWrapper = styled.div`
  height: 206px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  border: 1px solid rgba(34, 36, 38, .15);
  box-shadow: none;
  border-radius: .28571429rem;
  flex: 50 50 auto;

  & .ui.table {
    border: none;
  }

  & .ui.table thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    z-index: 2;
    background: white;
  }

  & .ui.table tbody tr:last-child > td {
    border-bottom: 1px solid rgba(34, 36, 38, .15)
  }
`;

interface Props {
  questionSetMap: { [key: number]: QuestionSetDto }
  selectedQuestionSets: number[],
  availableQuestionSets: number[],
  setData: (selectedQuestionSets: number[], availableQuestionSets: number[]) => void,
  patientQuestionSets?: Array<PatientTestingQuestionSetDto | PatientExercisingQuestionSetDto>
}

const PatientTestingQuestionSetSegment = (props: Props) => {

  const { t } = useTranslation('teresa');
  const { language } = useAuthContext();
  const isIphone = useIsIpadWidthOrBelow(430);

  const contextRef = React.useRef<any>(undefined);

  const {
    questionSetMap,
    selectedQuestionSets = [],
    availableQuestionSets = [],
    setData,
    patientQuestionSets,
  } = props;

  const [selectedAddQuestionSets, setSelectedAddQuestionSets] = React.useState<number[]>([]);
  const [availableAddQuestionSets, setAvailableAddQuestionSets] = React.useState<number[]>([]);
  const [markedQuestionSet, setMarkedQuestionSet] = React.useState<number | undefined>(undefined);
  const [availableSearchQuery, setAvailableSearchQuery] =
    React.useState<string | undefined>(undefined);
  const [selectedSearchQuery, setSelectedSearchQuery] =
    React.useState<string | undefined>(undefined);

  useEffect(() => {
    setSelectedAddQuestionSets(selectedQuestionSets);
    setAvailableAddQuestionSets(availableQuestionSets);
  }, [selectedQuestionSets, availableQuestionSets]);

  const markQuestionSet = (questionSetId: number) => {
    setMarkedQuestionSet(questionSetId);
  };

  const changeOrderUp = () => {
    if (markedQuestionSet && selectedAddQuestionSets.includes(markedQuestionSet)) {

      const index = selectedAddQuestionSets.indexOf(markedQuestionSet);
      if (index === 0) {return;}

      let newList: number[] = [...selectedAddQuestionSets];
      let element = newList.splice(index, 1)[0];
      newList.splice(index - 1, 0, element);

      setSelectedAddQuestionSets(newList);
      setData(newList, availableAddQuestionSets);
    }
  };

  const changeOrderDown = () => {
    if (markedQuestionSet && selectedAddQuestionSets.includes(markedQuestionSet)) {

      const index = selectedAddQuestionSets.indexOf(markedQuestionSet);
      if (index + 1 === selectedAddQuestionSets.length) {return;}

      let newList: number[] = [...selectedAddQuestionSets];
      let element = newList.splice(index, 1)[0];
      newList.splice(index + 1, 0, element);

      setSelectedAddQuestionSets(newList);
      setData(newList, availableAddQuestionSets);
    }
  };

  const addRemoveQuestionSet = () => {

    if (markedQuestionSet) {
      let selected = [...selectedAddQuestionSets];
      let available = [...availableAddQuestionSets];
      if (selectedAddQuestionSets.includes(markedQuestionSet)) {

        selected = selectedAddQuestionSets.filter(qs => markedQuestionSet !== qs);
        available.push(markedQuestionSet);

      } else if (availableAddQuestionSets.includes(markedQuestionSet)) {

        selected.push(markedQuestionSet);
        available = availableAddQuestionSets.filter(qs => markedQuestionSet !== qs);
      }

      let availableNames: QuestionSetDto[] = [];
      available.forEach(availableQuestionSet => {
        const questionSet = questionSetMap[availableQuestionSet];

        if (!questionSet.deleted) {
          availableNames.push(questionSet);
        }
      })

      const sortedAvailableNames = availableNames
      .sort((qs1, qs2) => {
        const qs1Name = translateName(qs1).toLowerCase();
        const qs2Name = translateName(qs2).toLowerCase();

        return qs1Name > qs2Name ? 1 : qs1Name === qs2Name ? 0 : -1;
      })

      const sortedAvailable = sortedAvailableNames.map(questionSet => questionSet.id);

      setSelectedAddQuestionSets(selected);
      setAvailableAddQuestionSets(sortedAvailable);
      setMarkedQuestionSet(undefined);

      setData(selected, sortedAvailable);
    }
  };

  const translateName = (questionSetDto: QuestionSetDto) => {
    const name = questionSetDto.name;

    const questionSetNames: { [key: string]: string } = {
      'de': questionSetDto.name || name,
      'en': questionSetDto.nameEn || name,
      'fr': questionSetDto.nameFr || name,
      'it': questionSetDto.nameIt || name,
    };
    return questionSetNames[language];
  }

  const renderContent = (): React.ReactNode => {

    const selectable: Array<JSX.Element> = [];
    const available: Array<JSX.Element> = [];

    availableAddQuestionSets.forEach(questionSetId => {
      const active = markedQuestionSet === questionSetId;
      const questionSet: QuestionSetDto = questionSetMap[questionSetId];
      const name = translateName(questionSet);

      const availableSearchByQuery = availableSearchQuery
        && !_.isEmpty(availableSearchQuery)
        && name.toLowerCase().includes(availableSearchQuery.toLowerCase());


      if (availableSearchByQuery || !availableSearchQuery) {
        const availableTestConfDescription = (
          <StyledTableRow key={questionSetId} onClick={() => markQuestionSet(questionSetId)} active={active} isIphone>
            <Table.Cell>{name}</Table.Cell>
          </StyledTableRow>
        );

        available.push(availableTestConfDescription);
      }
    });

    if (selectedAddQuestionSets && selectedAddQuestionSets.length > 0) {
      selectedAddQuestionSets.forEach(questionSetId => {
        const active = markedQuestionSet === questionSetId;
        const questionSet: QuestionSetDto = questionSetMap[questionSetId];
        const name = translateName(questionSet);

        let completed = false;
        if (patientQuestionSets) {
          const completedPatientTests =
            patientQuestionSets.filter(pt => pt.questionSetId === questionSetId && pt.completed);

          completed = completedPatientTests.length > 0;
        }

        const selectedSearchByQuery = selectedSearchQuery
          && !_.isEmpty(selectedSearchQuery)
          && name.toLowerCase().includes(selectedSearchQuery.toLowerCase());

        if (selectedSearchByQuery || !selectedSearchQuery) {
          const selectableQuestionSetName = (
            <StyledTableRow key={questionSetId} onClick={() => markQuestionSet(questionSetId)} active={active} isIphone>
              {completed
                ? isIphone
                  ? <Table.Cell>
                    <div className='wout-overflow'>{`${name}`}</div>
                    <div>({t('patientTesting.completed')})</div>
                  </Table.Cell>
                  : <Table.Cell>{`${name} (${t('patientTesting.completed')})`}</Table.Cell>
                : <Table.Cell>{name}</Table.Cell>
              }
            </StyledTableRow>
          );

          selectable.push(selectableQuestionSetName);
        }
      });
    }

    let testCompleted = false;
    if (patientQuestionSets) {
      const completedPatientTests =
        patientQuestionSets.filter(pt => pt.questionSetId === markedQuestionSet && pt.completed);

      testCompleted = completedPatientTests.length > 0;
    }

    const isInSelected = markedQuestionSet ? selectedAddQuestionSets.includes(markedQuestionSet) : false;
    const isInAvailable = markedQuestionSet ? availableAddQuestionSets.includes(markedQuestionSet) : false;

    const selectedIndex = markedQuestionSet ? selectedAddQuestionSets.indexOf(markedQuestionSet) : undefined;

    const disabledRight = !markedQuestionSet || isInAvailable;
    const disabledLeft = !markedQuestionSet || isInSelected;

    const disabledOrderUp = !markedQuestionSet || isInAvailable || testCompleted
      || (isInSelected && selectedIndex === 0);
    const disabledOrderDown = !markedQuestionSet || isInAvailable || testCompleted
      || (isInSelected && selectedIndex !== undefined && selectedIndex + 1 === selectedAddQuestionSets.length);

    return (
      <React.Fragment>
        <Grid.Column width={7}>
          <StyledSelectableTable>
            {renderLeftTableSegment(t('patientTesting.selected'), selectable)}
          </StyledSelectableTable>
        </Grid.Column>
        <Grid.Column width={isIphone ? 2 : 1} textAlign='center' style={{ height: '100%' }}>
          <StyledButtons isIphone>
            <StyledBtnContainer>
              <StyledIcon bordered
                          disabled={disabledLeft}
                          onClick={addRemoveQuestionSet}
                          name='angle double left'
              />
            </StyledBtnContainer>
            <StyledBtnContainer ref={contextRef}>
              {testCompleted
                ? <DeleteRecordConfirmation triggerButton={
                  <StyledIcon bordered
                              disabled={disabledRight}
                              name='angle double right'
                  />
                }
                                            confirmAction={() => addRemoveQuestionSet()}
                                            deleteConfirmationText={t('patientTesting.deleteQuestionSet')}
                                            position='top center'
                                            contextRef={contextRef}
                />
                : <StyledIcon bordered
                              disabled={disabledRight}
                              onClick={addRemoveQuestionSet}
                              name='angle double right'
                />
              }
            </StyledBtnContainer>
            <StyledBtnContainer>
              <StyledIcon bordered
                          disabled={disabledOrderUp}
                          onClick={changeOrderUp}
                          name='angle up'
              />
            </StyledBtnContainer>
            <StyledBtnContainer>
              <StyledIcon bordered
                          disabled={disabledOrderDown}
                          onClick={changeOrderDown}
                          name='angle down'
              />
            </StyledBtnContainer>
          </StyledButtons>
        </Grid.Column>
        <Grid.Column width={7}>
          <StyledSelectableTable>
            {renderRightTableSegment(t('testTemplate.available'), available)}
          </StyledSelectableTable>
        </Grid.Column>
      </React.Fragment>
    );
  };

  const renderLeftTableSegment = (headerText: string, selectedTestConfs: Array<React.ReactNode>): React.ReactNode => {

    return (
      <React.Fragment>
        <TsaGrid>
          <Grid.Row className='header-row'>
            <Grid.Column width={isIphone ? 16 : 6}>
              <Header as='h5'>
                {headerText}
              </Header>
            </Grid.Column>
            <Grid.Column width={isIphone ? 16 : 10} className='search-field'>
              <Input
                placeholder={t('patientTesting.searchSelectedQuestionSet.placeholder')}
                type='search'
                value={selectedSearchQuery}
                onChange={(evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
                  setSelectedSearchQuery(data.value)
                }
                icon='search'
                iconPosition='left'
                autoFocus
                fluid
              />
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
        <ScrollableTableWrapper>
          <Table celled selectable size='small'>
            <Table.Body>
              {selectedTestConfs}
            </Table.Body>
          </Table>
        </ScrollableTableWrapper>
      </React.Fragment>
    );
  };

  const renderRightTableSegment = (headerText: string, availableTestConfs: Array<React.ReactNode>): React.ReactNode => {

    return (
      <React.Fragment>
        <TsaGrid>
          <Grid.Row className='header-row'>
            <Grid.Column width={isIphone ? 16 : 6}>
              <Header as='h5'>
                {headerText}
              </Header>
            </Grid.Column>
            <Grid.Column width={isIphone ? 16 : 10} className='search-field'>
              <Input
                placeholder={t('patientTesting.searchAvailableQuestionSet.placeholder')}
                type='search'
                value={availableSearchQuery}
                onChange={(evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>
                  setAvailableSearchQuery(data.value)
                }
                icon='search'
                iconPosition='left'
                autoFocus
                fluid
              />
            </Grid.Column>
          </Grid.Row>
        </TsaGrid>
        <ScrollableTableWrapper>
          <Table celled selectable size='small'>
            <Table.Body>
              {availableTestConfs}
            </Table.Body>
          </Table>
        </ScrollableTableWrapper>
      </React.Fragment>
    );
  };

  return <>{renderContent()}</>;
};

export default PatientTestingQuestionSetSegment;