import { useAuthContext } from 'auth/AuthContext';
import axios, { AxiosRequestConfig } from 'axios';
import React, { useEffect, useState } from 'react';

interface State {
  clinicId?: number;
  setClinicId: (clinicId: number | undefined) => void;
}

const ClinicHeaderContext = React.createContext<State>({
  clinicId: undefined,
  setClinicId: (clinicId: number | undefined) => {},
});

interface Props extends JSX.ElementChildrenAttribute {}

const ClinicHeaderContextProvider = (props: Props) => {

  const [
    clinicId,
    setClinicId,
  ] = useState<number | undefined>(undefined);

  const {currentUser} = useAuthContext();

  useEffect(() => {
    let cleanup = undefined;

    if (clinicId) {
      const clinicIdHeaderInterceptor = (config: AxiosRequestConfig) => {
        config.headers['x-clinic-id'] = clinicId;
        return config;
      };

      const interceptorId = axios.interceptors.request.use(clinicIdHeaderInterceptor);

      cleanup = () => {
        axios.interceptors.request.eject(interceptorId);
      };
    }

    return cleanup;
  }, [clinicId]);

  useEffect(() => {
    if (!currentUser || currentUser.roles.includes('ROLE_APP_ADMIN')) {
      return;
    }

    setClinicId(currentUser.clinicId);
  }, [currentUser]);

  const handleSetClinicId = (clinicId: number|undefined) => {
    if (!currentUser || !currentUser.roles.includes('ROLE_APP_ADMIN')) {
      return;
    }

    setClinicId(clinicId);
  }

  const state: State = React.useMemo(() => {
    return { setClinicId: handleSetClinicId };
  }, [handleSetClinicId]);

  return (
    <ClinicHeaderContext.Provider value={state}>
      {props.children}
    </ClinicHeaderContext.Provider>
  );
};

export const useClinicHeaderContext = () => React.useContext(ClinicHeaderContext);

export default ClinicHeaderContextProvider;