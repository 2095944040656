import { useAuthContext } from 'auth/AuthContext';
import DeleteRecordConfirmation from 'components/DeleteRecordConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import InnerTableActionButton from 'components/InnerTableActionButton';
import InnerTsaGrid from 'components/InnerTsaGrid';
import InputView from 'components/InputView';
import LoaderComponent from 'components/LoaderComponent';
import SendActivationKeyPopup from 'components/SendActivationKeyPopup';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import ViewContentWrapperDiv from 'components/ViewContentWrapperDiv';
import VirtualizedTable from 'components/VirtualizedTable';
import { useClinicDataContext } from 'context/ClinicDataContext';
import { useClinicInfoContext } from 'context/ClinicInfoContext';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid, Header, Icon, Popup } from 'semantic-ui-react';
import { deactivateAdmin, generateRegKey, resendInvitation } from 'service/accountService';
import { getClinicDetails, getDoctorsForClinic } from 'service/adminService';
import { getClinicAdminClinicDetails } from 'service/clinicService';
import { deleteDoctor, getDoctorsForClinicAdmin } from 'service/doctorService';
import axios from 'service/http';
import { getClinicConfigs } from 'service/teresaConfService';
import styled from 'styled-components';
import { ClinicConfDetailsDto, ClinicDto, DoctorDto, TriggerAccountActivationRequest } from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { emptyTableCell, shortLabel } from 'util/tableUtils';

const CompactGrid = styled(Grid)`
    &.ui.stackable.grid > .column.iphone:not(.row) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
`;

const PopupStyle = {
  borderRadius: 'unset',
  padding: '10px',
};

const StyledPopupContentText = styled.div`
  font-size: 12px;

  width: 300px;
  
  & > strong {
    display: block;
  }
`;

const ActiveActions = styled.span`
  float: right;
`;

const cancelTokenSource = axios.CancelToken.source();

const ClinicDetailsView = () => {

    const { currentUser } = useAuthContext();
    const isIpad = useIsIpadWidthOrBelow(1023);
    const isIphone = useIsIpadWidthOrBelow(430);

    const { state } = useLocation();

    const clinicId: number | undefined = state?.id ? Number(state?.id) : undefined;

    const { t } = useTranslation('teresa');
    const { push, location } = useHistory();
    const { setClinicData } = useClinicDataContext();
    const { clinicInfo, setClinicId } = useClinicInfoContext();

    const isSystemAdmin = useMemo(() => currentUser
      && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

  const isTestAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_TEST_ADMIN'), [currentUser]);

    const isAdmin = useMemo(() => currentUser
      && currentUser.clinicAdmin, [currentUser]);

    const [clinic, setClinic] = React.useState<ClinicDto | undefined>(undefined);
    const [clinicConfig, setClinicConfig] =
      React.useState<ClinicConfDetailsDto | undefined>(undefined);
    const [foundDoctors, setFoundDoctors] = useState<DoctorDto[]>([]);
    const [dataLoaded, setDataLoaded] = React.useState<boolean>(true);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
      fetchData();
    }, []);

    const fetchData = useCallback(async () => {

      setDataLoaded(false);

      if(clinicId != undefined){
        setClinicId(clinicId);
      }

      const onFinally = () => setDataLoaded(true);

      try {
        let clinicData = undefined;

        if (isSystemAdmin && clinicId) {
          clinicData = await getClinicDetails(clinicId, cancelTokenSource);
          setClinicData(clinicData)
        } else if (isAdmin) {
          clinicData = await getClinicAdminClinicDetails(cancelTokenSource);
          setClinicData(clinicData)
        }

        setClinic(clinicData);
        await fetchDoctors();

        if (clinicData?.id) {
          const clinicConfigData = await getClinicConfigs(clinicData?.clinicCode, cancelTokenSource);
          setClinicConfig(clinicConfigData);
        }


      } catch (e: any) {
        handleError(e.response.data);
      } finally {
        onFinally();
      }
    }, []);

    const fetchDoctors = async () => {

        let doctors: DoctorDto[] = [];

        try {
          if (isSystemAdmin && clinicId) {
            doctors = await getDoctorsForClinic(clinicId, cancelTokenSource);
          } else if (isAdmin) {
            doctors = await getDoctorsForClinicAdmin(cancelTokenSource);
          }

          setFoundDoctors(doctors);
        } catch (e: any) {
          handleError(e.response.data);
        }
    };

    const goToPreviousPage = useCallback(() => {
      if (isSystemAdmin) {
        push('/');
      }
      setClinicData(undefined)
    }, [push]);

    const goToUpsertClinic = (): void => {
      push('/clinic', { id: clinic?.id });
    };

  const openClinicConfig = (): void => {
    push('/clinic/config', { clinicId: clinic?.id, prevPath: location.pathname });
  };

    const handleError = (error: any) => {

      if (error) {
        const errorCode = error.errorCode;
        const knownErrors: Array<string> = [
          errorUtils.userNotAdmin,
          errorUtils.invalidEmail,
          errorUtils.clinicNotFound,
          errorUtils.doctorNotFound
        ];

        const violations: Array<any> = error.violations;

        if (violations && violations.length > 0) {
          violations.forEach(violation => {
            if (knownErrors.includes(violation.errorCode)) {
              setErrorMessage(t(`error.${violation.errorCode}`));
            }
          });
        } else {
          if (knownErrors.includes(errorCode)) {
            setErrorMessage(t(`error.${errorCode}`));
          } else {
            setErrorMessage(t('error.general'));
          }
        }
      }
    };

    const setErrorMessage = useCallback((errorMessage?: string) => {

      if (errorMessage) {

        const errMsgs = [...errorMessages];
        errMsgs.push(errorMessage);
        setErrorMessages(errMsgs);
      } else {
        setErrorMessages([]);
      }
    }, []);

    const renderClinicDetails = () => {
      if (clinic) {

        const showConfigBtn = isSystemAdmin || isTestAdmin;
        const clinicName = clinicInfo ? `(${clinicInfo.name})` : "";
        const clinicAddress: string = _.compact([clinic.street, clinic.zip, clinic.city]).join(", ");
        const clinicTitle = isSystemAdmin
          ? `${t('clinic.details.title')} ${clinicName}`
          : `${t('clinic.details.title')}`

        return (
            <TsaGrid verticalAlign='middle'>
              <Grid.Row style={ isIphone ? { paddingBottom: 0 } : {}}>
                <Grid.Column width={16}>
                  <div className='title-h1'>{clinicTitle}</div>
                </Grid.Column>
              </Grid.Row>

              {errorMessages.length > 0 &&
              <Grid.Row>
                <Grid.Column width={16}>
                  <div className='error-message'>
                    <StyledErrorMessage onDismiss={() => setErrorMessage()}>
                      {errorMessages.map((err: string) => <div key={err}>{err}</div>)}
                    </StyledErrorMessage>
                  </div>
                </Grid.Column>
              </Grid.Row>
              }

              {successMessage !== undefined &&
                <Grid.Row>
                  <Grid.Column width={16}>
                    <StyledErrorMessage
                      onDismiss={() => setSuccessMessage(undefined)}
                      color='green'
                    >
                      <div>{successMessage}</div>
                    </StyledErrorMessage>
                  </Grid.Column>
                </Grid.Row>
              }

              <Grid.Row>
                <Grid.Column>
                <CompactGrid stackable doubling columns={2}>
                  <Grid.Column width={4} className={isIphone ? 'iphone': ''}>
                    <InnerTsaGrid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinic.name')}</DataLabel>
                          <InputView>{clinic.name}</InputView>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinic.details.address')}</DataLabel>
                          <InputView>{clinicAddress}</InputView>
                        </Grid.Column>
                      </Grid.Row>
                    </InnerTsaGrid>
                  </Grid.Column>

                  <Grid.Column width={4} className={isIphone ? 'iphone': ''}>
                    <InnerTsaGrid>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinic.email')}</DataLabel>
                          <InputView>{clinic.email}</InputView>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <DataLabel>{t('clinic.tel')}</DataLabel>
                          <InputView>{clinic.tel}</InputView>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={isIpad ? isIphone ? 8 : 4 : 16}>
                          <CompositeButton
                            type='button'
                            className='action-button'
                            primary
                            onClick={goToUpsertClinic}
                          >
                            {t('clinic.details.updateButton')}
                          </CompositeButton>
                        </Grid.Column>
                        {showConfigBtn && isIpad &&
                          <Grid.Column width={isIphone ? 8 : 4}>
                            <CompositeButton
                              type='button'
                              className='action-button'
                              primary
                              onClick={openClinicConfig}
                            >
                              {t('clinic.details.updateConfig')}
                            </CompositeButton>
                          </Grid.Column>
                        }
                      </Grid.Row>
                    </InnerTsaGrid>
                  </Grid.Column>

                  <Grid.Column width={8}>
                    {!isIpad &&
                      <InnerTsaGrid>
                        <Grid.Row>
                          <Grid.Column width={6}>
                            <DataLabel>{t('clinic.configuration')}</DataLabel>
                            <b>{t('clinic.configurationTests')}: {clinicConfig?.testConfNumber}</b>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <DataLabel>&nbsp;</DataLabel>
                            <b>{t('clinic.configurationExercises')}: {clinicConfig?.exerciseConfNumber}</b>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row verticalAlign={'middle'}>
                          <Grid.Column width={6}>
                            <b>{t('clinic.configurationTestTemplates')}: {clinicConfig?.testTemplateNumber}</b>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <b>{t('clinic.configurationExerciseTemplates')}: {clinicConfig?.exerciseTemplateNumber}</b>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row verticalAlign={'top'} style={{ minHeight: '44px' }}>
                          <Grid.Column width={6}>
                            <b>{t('clinic.configurationTestQuestions')}: {clinicConfig?.testQuestionSetNumber}</b>
                          </Grid.Column>
                          <Grid.Column width={6}>
                            <b>{t('clinic.configurationExerciseQuestions')}: {clinicConfig?.exerciseQuestionSetNumber}</b>
                          </Grid.Column>
                        </Grid.Row>
                        {showConfigBtn &&
                          <Grid.Row textAlign={isIpad ? 'left' : 'right'}>
                            <Grid.Column width={12}>
                              <CompositeButton
                                type='button'
                                className='action-button'
                                primary
                                onClick={openClinicConfig}
                              >
                                {t('clinic.details.updateConfig')}
                              </CompositeButton>
                            </Grid.Column>
                          </Grid.Row>
                        }
                      </InnerTsaGrid>
                    }
                  </Grid.Column>
                </CompactGrid>
              </Grid.Column>
              </Grid.Row>
            </TsaGrid>
        );
      }
    };

    const renderDoctorTable = () => {
      return (
        <>
          <div className='title-h1'>{t('doctor.viewTitle')}
            ( {(foundDoctors.length)} )
          </div>
          <div className='data-table'>
            {renderDoctorsTable()}
          </div>
        </>
      );

    };

    const openAddDoctor = (): void => {
      push('/doctor', {
        clinicId: clinic?.id,
      });
    };

    const openEditDoctor = (id: number): void => {
      push('/doctor', {
        id: id,
        clinicId: clinic?.id,
      });
    };

    const openSearchPatient = (): void => {
      if (isSystemAdmin && clinicId) {
        push('/patient/search', { clinicId: clinicId });
      } else {
        push('/patient/search', {clinicId: clinicId});
      }
    };

    const goToDoctorDetails = (id: number) => {
      push('/doctor/details', { id: id, clinicId: clinic?.id });
    };

  const goToInvoiceDetails = () => {
    push('/clinic/invoice', {
      clinicCode: clinic?.clinicCode,
      clinicId: clinic?.id,
      prevPath: '/clinic/details'
    });
  };

  const openClinicStats = (): void => {
    push('/clinic/stats', {
      clinicId: clinic?.id,
      prevPath: '/clinic/details'
    });
  };

    const goToImportPage = (): void => {
      push('/import', {
        clinicId: clinic?.id,
      });
    };

    const removeDoctor = (id: number) => {
      deleteDoctor(id, cancelTokenSource)
      .then(() => {
        setSuccessMessage(t("doctor.successfullyDeleted.message"));
        fetchData();
      })
      .catch((e: any) => handleError(e.response.data));
    }

    const doctorsRowGetter = ({ index }: any) => {

      Object.assign(foundDoctors[index], { index: index + 1 });

      return foundDoctors[index];
    };

    const doctorsRowRenderer = ({ className, columns, index, key, style }: any) => {
      const a11yProps = { 'aria-rowindex': index + 1 };

      const doctorId = foundDoctors[index].id;

      return (
        <div
          {...a11yProps}
          className={className}
          key={key}
          role='row'
          style={style}
          onDoubleClick={() => openEditDoctor(doctorId!)}
        >
          {columns}
        </div>
      );
    };

  const staffTypeCellRenderer = ({ cellData }: any) => {
    if (cellData) {
      return t(t(`staffType.${cellData}`));
    }
    return emptyTableCell();
  };

    const renderDoctorsTable = (): JSX.Element => {

      let columns: React.ReactNode[] = [
        {
          width: 80,
          label: t('doctor.doctorId'),
          dataKey: 'id',
        },
        {
          width: 180,
          flexGrow: 1,
          label: t('doctor.name'),
          dataKey: 'name',
          cellRenderer: (doctorCellRenderer),
        }
      ];

      if (!isIpad) {
        columns.push(
          {
            width: 150,
            flexGrow: 1,
            label: t('doctor.staffType'),
            dataKey: 'staffType',
            cellRenderer: staffTypeCellRenderer
          },
          {
            width: 150,
            flexGrow: 1,
            label: t('doctor.email'),
            dataKey: 'email',
          }
        )
      }

      if (!isIphone) {
        columns.push(
          {
            width: 40,
            label: shortLabel(t("clinic.patientNumber"), t("clinic.shortPatientNumber")),
            dataKey: 'numberOfPatients',
            cellRenderer: (patientNumberCellRenderer),
          },
          {
            width: 40,
            label: shortLabel(t("clinic.testNumber"), t("clinic.shortTestNumber")),
            dataKey: 'numberOfTests',
            cellRenderer: (testNumberCellRenderer),
          },
          {
            width: 40,
            label: shortLabel(t("doctor.admin"), t("doctor.shortAdmin")),
            dataKey: 'clinicAdmin',
            cellRenderer: (clinicAdminCellRenderer),
          },
          {
            width: 70,
            label: t('doctor.active'),
            dataKey: 'active',
            cellRenderer: (activeCellRenderer),
          },
        );
      }

      columns.push(
        {
          width: !isIpad ? 270 : 50,
          flexShrink: 0,
          label: t('doctor.activation'),
          dataKey: 'accountActive',
          cellRenderer: (activationCellRenderer),
        },
        {
          width: !isIphone ? 200 : 170,
          flexShrink: 0,
          label: t('doctor.actions'),
          dataKey: 'id',
          cellRenderer: (actionsCellRenderer)
        }
      )

      return (
        <VirtualizedTable
          rowCount={foundDoctors.length}
          rowGetter={doctorsRowGetter}
          rowRenderer={doctorsRowRenderer}
          columns={columns}
        />
      );
    };

    const popupContent = (
      id: number,
      resend: boolean,
      func: (id: number, request: Partial<TriggerAccountActivationRequest>) => void,
      email?: string) => {

      return {
        id: id,
        resend: resend,
        func: func,
        email: email,
      };
    };

    const activateAccount = (id: number, request: Partial<TriggerAccountActivationRequest>) => {
      generateRegKey(id, request)
      .then(() => {
        fetchDoctors();
      })
      .catch((e) => handleError(e.response.data));
    };

    const resendActivateAccount = (id: number, request: Partial<TriggerAccountActivationRequest>) => {
      resendInvitation(id, request)
      .then(() => {
        fetchDoctors();
      })
      .catch((e) => handleError(e.response.data));
    };

    const deactivateAccountButton = (id: number) => {

      deactivateAdmin(id).then(() => {
        fetchDoctors();
      })
      .catch((e) => handleError(e.response.data));
    };

    const renderRegInfoPopup = (regKey: any) => {

      return (
        <div>
          <TsaGrid>
            <Grid.Row>
              <Grid.Column>
                <Header as='h4'>
                  {t('doctor.registrationKey')}
                </Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <StyledPopupContentText>
                  {t('doctor.regInfo.desc')} <strong>{regKey}</strong>
                </StyledPopupContentText>
              </Grid.Column>
            </Grid.Row>
          </TsaGrid>
        </div>

      );
    };

    const activeCellRenderer = ({ cellData }: any) => {
      if (cellData) {
        return <Icon name='check' size='small' />;
      }
      return emptyTableCell();
    };

    const activationCellRenderer = ({ rowData }: any) => {

      const style = { color: 'var(--primary-color)' };

      const activeIcon = rowData.accountActive ?
        (<Popup
          trigger={<Icon name='check circle' style={style} />}
          content={t('doctor.active')}
          size='small'
          position='top center'
          on='click'
          style={PopupStyle}
        />) : !!rowData.regKey ?
          (<Popup
            style={PopupStyle}
            trigger={<Icon name='key' style={style} />}
            size='huge'
            position='top center'
            on='click'
            content={renderRegInfoPopup(rowData.regKey)} />) :
          (<Popup
            trigger={<Icon name='circle outline' />}
            content={t('doctor.inactive')}
            size='small'
            position='top center'
            on='click'
            style={PopupStyle}
          />);

      const activeActions = rowData.accountActive ?
        <InnerTableActionButton message={t('doctor.deactivate')}
                                onConfirm={() => deactivateAccountButton(rowData.accountId)}
                                divider={true}
        />
        : !!rowData.regKey
          ? <React.Fragment>
            <InnerTableActionButton message={t('doctor.removeKey')}
                                    onConfirm={() => deactivateAccountButton(rowData.accountId)}
                                    divider={true}
            />
            <SendActivationKeyPopup
              triggerButtonText='doctor.resendInv'
              content={popupContent(rowData.accountId, true, resendActivateAccount, rowData.email)}
              position='left center'
              divider={true}
              tableButton={true}
            />
          </React.Fragment> :
          <SendActivationKeyPopup
            triggerButtonText='doctor.activate'
            content={popupContent(rowData.accountId, false, activateAccount, rowData.email)}
            position='left center'
            divider={true}
            tableButton={true}
          />;
      return (
        <div>
          {activeIcon}
          {!isIpad &&
            <ActiveActions>
              {activeActions}
            </ActiveActions>
          }
        </div>
      );
    };

    const clinicAdminCellRenderer = ({ cellData }: any) => {
      if (cellData) {
        return <Icon name='check' size='small' />;
      }
      return emptyTableCell();

    };

    const patientNumberCellRenderer = ({ cellData }: any) => {
      if (!cellData || cellData === 0) {
        return emptyTableCell();
      }
      return cellData;
    };

  const testNumberCellRenderer = ({ cellData }: any) => {
    if (!cellData || cellData === 0) {
      return emptyTableCell();
    }
    return cellData;
  };

  const doctorCellRenderer = ({rowData}: any) => {
    const name = rowData.name;
    const lastName = rowData.lastName;
    const title = rowData.title;

    return (
      <div>{title} {name} {lastName}</div>
    )
  }

    const actionsCellRenderer = ({ rowData, cellData }: any) => {

    const disabled = !isSystemAdmin && !isAdmin;

      return (
        <div className='row-actions'>
          <InnerTableActionButton
            message={t('doctor.detail')}
            onConfirm={() => goToDoctorDetails(rowData.id)}
            disabled={disabled}
            divider={true}
          />
          <InnerTableActionButton
            message={t('doctor.edit')}
            onConfirm={() => openEditDoctor(cellData)}
            divider={true}
          />
          <DeleteRecordConfirmation
            triggerButtonText={t('doctor.delete')}
            confirmAction={() => removeDoctor(rowData.id)}
            deleteConfirmationText={t('doctor.confirmDelete', {name: rowData.name})}
            position={"top left"}
          />
        </div>
      );
    };

    const renderActions = () => {
      return (
        <>
          <div className='page-actions'>
            <CompositeButton
              type='button'
              className='action-button'
              onClick={openAddDoctor}
              primary
            >
              {t('doctor.addNew')}
            </CompositeButton>

            {!isIpad &&
              <CompositeButton
                type='button'
                className='action-button'
                onClick={goToImportPage}
                primary
              >
                {t('button.import')}
              </CompositeButton>
            }

            {isSystemAdmin &&
            <CompositeButton
              type='button'
              className='action-button'
              onClick={openSearchPatient}
              primary
            >
              {t('patient.searchTitle')}
            </CompositeButton>
            }

            {isSystemAdmin &&
              <CompositeButton
                type='button'
                className='action-button'
                primary
                onClick={goToInvoiceDetails}
              >
                {t('clinic.invoiceDetails')}
              </CompositeButton>
            }

            {isSystemAdmin &&
              <CompositeButton
                type='button'
                className='action-button'
                primary
                onClick={openClinicStats}
              >
                {t('clinic.actionStats')}
              </CompositeButton>
            }

            {isSystemAdmin &&
              <>
                <div className='spacer'></div>
                <CompositeButton
                  type='button'
                  className='action-button'
                  secondary
                  onClick={goToPreviousPage}
                  style={{ float: 'right' }}
                >
                  {t('action.back')}
                </CompositeButton>
              </>
            }
          </div>
        </>
      );
    };

    return (
      <ViewContentWrapperDiv>
        {dataLoaded
          ? <React.Fragment>
            {renderClinicDetails()}
            {renderDoctorTable()}
            {renderActions()}
          </React.Fragment>
          : <LoaderComponent message={t('clinic.loading')} />
        }
      </ViewContentWrapperDiv>
    );
  }
;

export default ClinicDetailsView;