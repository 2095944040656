import { useAuthContext } from 'auth/AuthContext';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import _ from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { DisplayImage } from 'routes/patienttesting/StartTestingForm';
import styled from 'styled-components';
import { DisplayTestImageDto, UpsertPatientTestImageDrawDto } from 'ts-types/api.types';

const ImageContainer = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;
`;

const EraserCursor = styled.div<{ isErasing: boolean }>`
  position: absolute;
  width: 20px; /* Customize eraser size */
  height: 20px;
  background: rgba(255, 0, 0, 0.5); /* Red semi-transparent eraser */
  border-radius: 50%;
  pointer-events: none; /* Prevents interfering with drawing */
  transform: translate(-50%, -50%);
  z-index: 999;
  display: ${(props) => (props.isErasing ? "block" : "none")}; /* Hide when not erasing */
`;

interface Point {
  x: number;
  y: number;
}

interface Stroke {
  points: Point[];
  color: string;
  width: number;
}

interface Props {
  testImage: DisplayTestImageDto;
  color: string;
  drawing: Partial<UpsertPatientTestImageDrawDto>;
  saveDrawing: (upsertPatientTestImageDrawDto: Partial<UpsertPatientTestImageDrawDto>) => void;
  isErasing: boolean;
}

const PaintOverImage = forwardRef(({ testImage, color = '#000000', drawing, saveDrawing, isErasing = false }: Props, ref) => {
  const { language } = useAuthContext();
  const isIphone = useIsIpadWidthOrBelow(430);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [canvasSize, setCanvasSize] = useState({ width: 600, height: 400 });
  const [strokes, setStrokes] = useState<Stroke[]>([]);
  const isDrawing = useRef(false);
  const currentStroke = useRef<Stroke | null>(null);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  useImperativeHandle(ref, () => ({
    handleUndo: () => {
      setStrokes((prevStrokes) => {
        if (prevStrokes.length === 0) return prevStrokes; // No strokes to undo

        const newStrokes = prevStrokes.slice(0, -1); // Remove only the last full stroke
        redrawCanvas(newStrokes);
        handleSaveDrawing(newStrokes);
        return newStrokes;
      });
    },
    handleClear: () => {
      setStrokes([]);
      clearCanvas();
      handleSaveDrawing([]);
    },
  }));

  const imageViewMap = useMemo((): { [lang: string]: DisplayImage } => {

    if (testImage) {
      const defaultImage = { image: testImage.imageView, fileType: testImage.imageFileType };
      return {
        'de': defaultImage,
        'en': {
          image: testImage.imageViewEn || testImage.imageView,
          fileType: testImage.imageEnFileType || testImage.imageFileType,
        },
        'fr': {
          image: testImage.imageViewFr || testImage.imageView,
          fileType: testImage.imageFrFileType || testImage.imageFileType,
        },
        'it': {
          image: testImage.imageViewIt || testImage.imageView,
          fileType: testImage.imageItFileType || testImage.imageFileType,
        },
      };
    }

    return {};

  }, [testImage]);

  useEffect(() => {
    const disableTouchScroll = (event: TouchEvent) => {
      if (isDrawing.current) {
        event.preventDefault();
      }
    };

    document.addEventListener('touchmove', disableTouchScroll, { passive: false });

    return () => {
      document.removeEventListener('touchmove', disableTouchScroll);
    };
  }, []);

  useEffect(() => {
    const updateCanvasSize = () => {
      if (!testImage || !testImage.imageView) return;
      if (!containerRef.current) return;

      // Clear the canvas before update
      setStrokes([]);
      clearCanvas();

      const img = new Image();
      img.src = `data:${imageViewMap[language]?.fileType};base64, ${imageViewMap[language]?.image}`;
      img.onload = () => {
        const imageWidth = isIphone ? window.innerWidth - 12 : imageRef.current!.clientWidth;
        const aspectRatio = imageRef.current!.clientWidth / imageRef.current!.clientHeight;
        const newHeight = imageWidth / aspectRatio;

        setCanvasSize({ width: imageWidth, height: newHeight });

        if (canvasRef.current) {
          canvasRef.current.width = imageWidth;
          canvasRef.current.height = newHeight;
        }

        // Scale strokes to fit new dimensions
        if (drawing?.drawing && drawing.width && drawing.height) {
          try {
            const parsedStrokes: Stroke[] = JSON.parse(drawing.drawing);
            const scaleX = imageWidth / drawing.width;
            const scaleY = newHeight / drawing.height;

            const scaledStrokes = parsedStrokes.map((stroke) => ({
              ...stroke,
              points: stroke.points.map((point) => ({
                x: point.x * scaleX, // Adjust X coordinate
                y: point.y * scaleY, // Adjust Y coordinate
              })),
            }));

            setStrokes(scaledStrokes);
            redrawCanvas(scaledStrokes);
          } catch (error) {
            console.error("Error parsing drawing data:", error);
            setStrokes([]);
          }
        }
      };
    };

    // Run once on initial load
    updateCanvasSize();

    // Listen for window resize
    window.addEventListener("resize", updateCanvasSize);

    return () => {
      window.removeEventListener("resize", updateCanvasSize); // Cleanup
    };
  }, [testImage, imageViewMap, language, isIphone]);

  useEffect(() => {
    if (isErasing) {
      handleSaveDrawing(strokes);
    }
  }, [isErasing, strokes]);

  const getPointerPosition = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return { x: 0, y: 0 };

    const rect = canvas.getBoundingClientRect(); // Get the canvas's actual position on the screen
    const scaleX = canvas.width / rect.width; // Scale factor for width
    const scaleY = canvas.height / rect.height; // Scale factor for height

    if ('touches' in event) {
      // Touch event
      return {
        x: (event.touches[0].clientX - rect.left) * scaleX,
        y: (event.touches[0].clientY - rect.top) * scaleY,
      };
    } else {
      // Mouse event
      return {
        x: (event.clientX - rect.left) * scaleX,
        y: (event.clientY - rect.top) * scaleY,
      };
    }
  };

  const updateCursorPosition = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    const { x, y } = getPointerPosition(event);
    setCursorPos({ x, y }); // Always update cursor position
  };

  const handleSaveDrawing = (newStrokes?: Stroke[]) => {
    const newDrawing: Partial<UpsertPatientTestImageDrawDto> = {
      width: canvasRef.current?.width || canvasSize.width,  // Store the original canvas width
      height: canvasRef.current?.height || canvasSize.height, // Store the original canvas height
      drawing: JSON.stringify(newStrokes ? newStrokes : strokes),
    };

    saveDrawing(_.hasIn(drawing, 'testImageId') ? { ...drawing, ...newDrawing } : { testImageId: testImage.id, ...newDrawing });
  }

  const eraseAtPoint = (x: number, y: number) => {
    const eraserRadius = 3; // Smaller eraser size for precise control

    setStrokes((prevStrokes) => {
      const newStrokes: Stroke[] = [];

      prevStrokes.forEach((stroke) => {
        const newStroke1: Point[] = [];
        const newStroke2: Point[] = [];
        let erasing = false;

        stroke.points.forEach((point) => {
          const distance = Math.hypot(point.x - x, point.y - y);

          if (distance < eraserRadius) {
            erasing = true; // Start erasing only at exact touch point
          } else {
            if (erasing) {
              newStroke2.push(point); // Split stroke where erasing happened
            } else {
              newStroke1.push(point);
            }
          }
        });

        if (newStroke1.length > 1) newStrokes.push({ ...stroke, points: newStroke1 });
        if (newStroke2.length > 1) newStrokes.push({ ...stroke, points: newStroke2 });
      });

      redrawCanvas(newStrokes);
      return newStrokes;
    });
  };

  const startDrawing = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    const { x, y } = getPointerPosition(event);

    if (isErasing) {
      isDrawing.current = true; // Track that erasing is happening only when pressed
      eraseAtPoint(x, y);
    } else {
      isDrawing.current = true;
      currentStroke.current = { points: [{ x, y }], color, width: 5 };
    }
  };
  const draw = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    updateCursorPosition(event); // Always move the cursor

    if (!isDrawing.current) return;

    const { x, y } = getPointerPosition(event);

    if (isErasing) {
      setCursorPos({ x, y });
      eraseAtPoint(x, y);
      return;
    }

    if (!isDrawing.current || !currentStroke.current) return;
    currentStroke.current.points.push({ x, y });

    redrawCanvas([...strokes, currentStroke.current]);
  };

  const stopDrawing = () => {
    // @ts-ignore
    if (isDrawing.current && currentStroke.current?.points.length > 1) {
      const newStroke = {
        points: [...currentStroke.current!.points],
        color: currentStroke.current!.color,
        width: currentStroke.current!.width,
      }
      setStrokes((prevStrokes) => {
        const newStrokes = [...prevStrokes, newStroke];
        handleSaveDrawing(newStrokes);
        return newStrokes;
      });

      isDrawing.current = false; // Stop drawing/erasing when mouse is lifted
      currentStroke.current = null;
    }

    isDrawing.current = false;
    currentStroke.current = null;
  };

  const redrawCanvas = (strokesToDraw: Stroke[]) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    strokesToDraw.forEach((stroke) => {
      if (!stroke || !Array.isArray(stroke.points) || stroke.points.length === 0) return;

      ctx.beginPath();
      ctx.strokeStyle = stroke.color || '#000000';
      ctx.lineWidth = 1;
      ctx.lineCap = 'round';
      ctx.lineJoin = 'round';

      stroke.points.forEach((point, index) => {
        if (!point || typeof point.x !== 'number' || typeof point.y !== 'number') return;

        if (index === 0) {
          ctx.moveTo(point.x, point.y);
        } else {
          ctx.lineTo(point.x, point.y);
        }
      });

      ctx.stroke();
    });
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (ctx) ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  return (
    <ImageContainer ref={containerRef} style={{ width: '100%', height: 'auto' }}>
      <img ref={imageRef} src={`data:${imageViewMap[language].fileType};base64, ${imageViewMap[language].image}`}
           alt='image'
           style={{ width: '100%', height: 'auto' }}
      />
      <canvas
        ref={canvasRef}
        width={canvasSize.width}
        height={canvasSize.height}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          cursor: isErasing ? "none" : "default", //Hide cursor when erasing
        }}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseOut={stopDrawing}
        onTouchStart={startDrawing}
        onTouchMove={draw}
        onTouchEnd={stopDrawing}
      />
      <EraserCursor
        isErasing={isErasing}
        style={{ left: `${cursorPos.x}px`, top: `${cursorPos.y}px` }}
      />
    </ImageContainer>
  );
});

export default PaintOverImage;
