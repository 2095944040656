import CompositeButton from 'components/final-form/CompositeButton';
import TsaGrid from 'components/TsaGrid';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledPopupContentText = styled.div`
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
`;

const StyledContainer = styled.div`
  display: inline-block;
  
  .ui.button {
    font-family: 'Manrope', sans-serif;
    padding: 0.7rem 4rem 0.7rem !important;
    margin-left: 14px;
    margin-right: 4px;
  }

  & > .ui.large.button {
    font-weight: 500;
  }

  .ui.primary.button, .ui.primary.buttons .button {
    background-color: var(--primary-color);
    
    :active {
      background-color: var(--primary-color);
    }

    :focus {
      background-color: var(--primary-color);
    }

    :hover {
      background-color: var(--primary-color);
    }
  }

  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: var(--secondary-color);
  }
  
`;

const PopupStyle = {
  borderRadius: 'unset',
  minWidth: '450px',
};

interface Props  {
  disabled?: boolean,
  updateConfirmationText: string,
  title?: string,
  position?:
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center',
  onOpen?: () => void,
  submit: () => void
}

const ArchiveTestingConfirmation = (props: Props) => {

  const { disabled = false, onOpen, position, updateConfirmationText, title } = props;
  const { t } = useTranslation('teresa');
  const isIphone = useIsIpadWidthOrBelow(430);

  const [popupOpen, setPopupOpen] = useState<boolean>(false);

  const popupTrigger = () => {
    return (
      <CompositeButton
        primary
        type='button'
        size='large'
        disabled={disabled}
        onClick={togglePopup}
      >
        {title ? title : t('button.archive')}
      </CompositeButton>
    );
  };

  const togglePopup = () => {
    setPopupOpen(!popupOpen);
  };

  const submit = () => {
    props.submit();
    togglePopup();
  };

  const renderContent = () => {
    return (
      <StyledContainer>
        <TsaGrid>
          <Grid.Row style={isIphone ? {maxWidth: '290px'} : {}}>
            <Grid.Column width={16}>
              <StyledPopupContentText>
                {updateConfirmationText}
              </StyledPopupContentText>
            </Grid.Column>
          </Grid.Row>

          <Grid.Column
            width={16}
            textAlign={'center'}
            style={isIphone ? {display: 'inline-flex', paddingLeft: '0'} : {}}
          >
            <Button
              primary
              type='button'
              className='action-button'
              size='tiny'
              onClick={submit}
              style={isIphone ? {marginLeft: 0} : {}}
            >
              {t('button.yes')}
            </Button>
            <Button
              type='button'
              className='action-button'
              size='tiny'
              secondary
              onClick={togglePopup}
              style={isIphone ? {marginLeft: 0} : {}}
            >
              {t('button.no')}
            </Button>
          </Grid.Column>
        </TsaGrid>
      </StyledContainer>
    );
  };


  return (
    <Popup
      style={isIphone ? {
          borderRadius: 'unset',
          minWidth: '290px'
        }
        : PopupStyle}
      trigger={popupTrigger()}
      content={renderContent}
      open={popupOpen}
      onClose={() => !disabled && togglePopup()}
      onOpen={() => {
        if (!disabled) {
          togglePopup();

          if (onOpen) {
            onOpen();
          }
        }
      }}
      on='click'
      position={position || 'top center'}
    />

  );

};

export default ArchiveTestingConfirmation;