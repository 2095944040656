import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import AccordionWithArrow, { CustomContent, CustomTitle } from 'components/AccordionWithArrow';
import LoaderComponent from 'components/LoaderComponent';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import i18n from 'i18next';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Checkbox, Dropdown, DropdownItemProps, Grid, Icon, Input, Popup } from 'semantic-ui-react';
import {
  getAllClinicCodesWithDescription,
  getExtendedSearchTestConfs,
  getExtendedSearchTestConfsForDoctor,
} from 'service/testConfExtendedSearchService';
import styled from 'styled-components';
import { Sex } from 'ts-types/api.enums';
import {
  ClinicDto,
  DomainDto,
  RawScoreResultExtendedSearchDto,
  TestConfExtendedSearchDto,
  TestConfExtendedSearchResponseDto,
  TestConfSearchRequest,
  TestResultExtendedSearchDto,
  TestResultParamDto,
  TrueScoreResultExtendedSearchDto,
} from 'ts-types/api.types';
import { supportedLanguages } from 'util/localizationUtils';

enum RangeEnum {
  AGE_RANGE,
  EDUCATIONAL_YEARS,
  RAW_SCORE,
  TRUE_SCORE,
  TEST_PARAM,
}

const PopupTriggerContainer = styled.div`
    display: inline-block;
    position: relative;
    margin-inline: 5px;
`;

const StyledTextDisplayDiv = styled.div`
    display: inline-block;
    position: absolute;
    font-size: 17px;
    left: 40%;
    transform: translateX(-45%);
    top: 5%;
`;

const StyledPre = styled.div`
    white-space: pre-line;
    font: unset;
`;

const Label = styled.span`
    font-weight: bold;
    font-size: 15px;
`;

const FormBarLabel = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    margin-right: 5px;
`;

const FormBarContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;

    .ui.checkbox {
        border-radius: 4px;
        margin: -4px -4px;
`;

const ScrollableTsaGrid = styled.div`
    position: fixed;
    overflow: auto;
    top: 125px;
    bottom: 20px;
`;

const StyledRangeRow = styled.div`
    display: grid;
    grid-template-columns: 50px 30px 50px 25px 60px;
    grid-auto-rows: min-content;
    gap: 3px; /* Space between columns */
    margin-left: -15px;

    &.raw-score {
        grid-template-columns: 45px 30px 45px 45px 30px 45px 50px 50px;
        gap: 1px;
    }

    &.raw-score-wout-formula {
        grid-template-columns: 50px 30px 50px 50px 30px 50px 50px;
        gap: 1px;
    }

    & .grid-item {
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;

        &.align-left {
            justify-content: left !important;
        }

        &.align-right {
            justify-content: right !important;
        }
    }
`;

export interface TestSearchSettings {
  searchValues: Partial<TestConfSearchRequest>;
  searchByTestResultParams: boolean;
  searchByTests: boolean;
  searchByTestResults: boolean;
  searchByAll: boolean;
  expandAll: boolean | undefined;
  expandTests: boolean | undefined;
  expandTestResults: boolean | undefined;
  expandRawScoreRules: boolean | undefined;
  expandTrueScoreRules: boolean | undefined;
  expandTestResultParams: boolean | undefined;
  includeInactive: boolean;
  selectedClinics: Array<string>;
}

const TestConfExtendedSearchView = () => {

  const isIphone = useIsIpadWidthOrBelow(430);
  const isIpad = useIsIpadWidthOrBelow();
  const isLaptop = useIsIpadWidthOrBelow(1600);

  const [testConfs, setTestConfs] = useState<{ [index: string]: TestConfExtendedSearchDto } | undefined>(undefined);
  const [clinics, setClinics] = useState<Array<ClinicDto>>([]);
  const [langOptions, setLangOptions] = useState<DropdownItemProps[]>([]);
  const [filteredTestConfs, setFilteredTestConfs] = useState<{ [index: string]: TestConfExtendedSearchDto }>({});
  const [formDataLoaded, setFormDataLoaded] = React.useState<boolean>(false);
  const [searchSettings, setSearchSettings] = useState<TestSearchSettings>({
    searchValues: {
      testConfSearchTerm: '',
    },
    searchByTestResultParams: false,
    searchByTests: false,
    searchByTestResults: false,
    searchByAll: true,
    expandAll: false,
    expandTests: false,
    expandTestResults: false,
    expandRawScoreRules: false,
    expandTrueScoreRules: false,
    expandTestResultParams: false,
    includeInactive: false,
    selectedClinics: [],
  });

  const currentLanguage = i18n.language;
  const cancelTokenSource = axios.CancelToken.source();
  const { updateLanguage } = useAuthContext();
  const { language } = useAuthContext();
  const { t } = useTranslation('teresa');
  const history = useHistory();
  const { currentUser } = useAuthContext();
  const isSystemAdmin = useMemo(() => currentUser
    && currentUser.roles.includes('ROLE_APP_ADMIN'), [currentUser]);

  const clinicOptions = clinics.map((clinic) => ({
    key: clinic.clinicCode,
    value: clinic.clinicCode,
    text: `${clinic.clinicCode} - ${clinic.name}`,
  }));

  const renderLabel = (label: any) => ({
    content: label.value,
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      searchTestConfs();
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [searchSettings.searchValues.testConfSearchTerm, testConfs, formDataLoaded]);

  useEffect(() => {
    searchTestConfs();
  }, [formDataLoaded]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isSystemAdmin) {
          const clinicsResponse = await getAllClinicCodesWithDescription(cancelTokenSource);
          setClinics(clinicsResponse);
        }

        const updatedLangOptions = supportedLanguages.map(lang => ({
          key: lang,
          value: lang,
          text: t(`language.${lang}`),
        }));
        setLangOptions(updatedLangOptions);

        const testConfExtendedSearchResponse: TestConfExtendedSearchResponseDto = isSystemAdmin
          ? await getExtendedSearchTestConfs(cancelTokenSource)
          : await getExtendedSearchTestConfsForDoctor(cancelTokenSource);
        setTestConfs(testConfExtendedSearchResponse.testConfExtendedSearchDtoMap);
      } catch (error) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (testConfs) {
      setFormDataLoaded(true);
    }
  }, [testConfs]);

  const handleIndeterminateOrFalseTestResults = (newSearchSettings: TestSearchSettings) => {
    if (newSearchSettings.expandTestResults == true) {newSearchSettings.expandTestResults = undefined;}
    return newSearchSettings.expandTestResults;
  };

  const handleIndeterminateOrFalseRawScoreRules = (newSearchSettings: TestSearchSettings) => {
    if (newSearchSettings.expandRawScoreRules == true) {newSearchSettings.expandRawScoreRules = undefined;}
    return newSearchSettings.expandRawScoreRules;
  };

  const handleIndeterminateOrFalseTrueScoreRules = (newSearchSettings: TestSearchSettings) => {
    if (newSearchSettings.expandTrueScoreRules == true) {newSearchSettings.expandTrueScoreRules = undefined;}
    return newSearchSettings.expandTrueScoreRules;
  };

  const handleIndeterminateOrFalseTestResultParams = (newSearchSettings: TestSearchSettings) => {
    if (newSearchSettings.expandTestResultParams == true) {newSearchSettings.expandTestResultParams = undefined;}
    return newSearchSettings.expandTestResultParams;
  };

  const updateSearchSettings = (key: keyof TestSearchSettings, value: any) => {
    let newSearchSettings = {
      ...searchSettings,
      [key]: value,
    };
    if (key != 'searchValues') {
      if (key == 'expandAll') {
        newSearchSettings.expandTests = value;
        newSearchSettings.expandTestResults = value;
        newSearchSettings.expandTestResultParams = value;
        newSearchSettings.expandTrueScoreRules = value;
        newSearchSettings.expandRawScoreRules = value;
        newSearchSettings.expandAll = value;
      }
      if (key == 'expandTests'
        || key == 'expandTestResults'
        || key == 'expandTrueScoreRules'
        || key == 'expandRawScoreRules'
        || key == 'expandTestResultParams'
        && value == false) {
        newSearchSettings.expandAll = undefined;
      }
      if (key == 'expandTests' && value == false) {
        newSearchSettings.expandTestResults = false;
        newSearchSettings.expandRawScoreRules = false;
        newSearchSettings.expandTrueScoreRules = false;
        newSearchSettings.expandTestResultParams = false;
      }
      if (key == 'expandTestResults' && value == false) {
        newSearchSettings.expandRawScoreRules = false;
        newSearchSettings.expandTrueScoreRules = false;
        newSearchSettings.expandTestResultParams = false;
      }
      if (key == 'expandTests' && value == undefined) {
        newSearchSettings.expandTestResults = handleIndeterminateOrFalseTestResults(newSearchSettings);
        newSearchSettings.expandRawScoreRules = handleIndeterminateOrFalseRawScoreRules(newSearchSettings);
        newSearchSettings.expandTrueScoreRules = handleIndeterminateOrFalseTrueScoreRules(newSearchSettings);
        newSearchSettings.expandTestResultParams = handleIndeterminateOrFalseTestResultParams(newSearchSettings);
      }
      if (key == 'expandTestResults' && value == undefined) {
        newSearchSettings.expandRawScoreRules = handleIndeterminateOrFalseRawScoreRules(newSearchSettings);
        newSearchSettings.expandTrueScoreRules = handleIndeterminateOrFalseTrueScoreRules(newSearchSettings);
        newSearchSettings.expandTestResultParams = handleIndeterminateOrFalseTestResultParams(newSearchSettings);
      }
      if ((key == 'searchByTests'
          || key == 'searchByTestResults'
          || key == 'searchByTestResultParams')
        && value == true) {
        newSearchSettings.searchByAll = false;
      }
      if (key == 'searchByAll') {
        newSearchSettings.searchByAll = value;
        newSearchSettings.searchByTestResultParams = value ? !value : value;
        newSearchSettings.searchByTestResults = value ? !value : value;
        newSearchSettings.searchByTests = !value;
      }
      if (!newSearchSettings.searchByTestResults
        && !newSearchSettings.searchByTests
        && !newSearchSettings.searchByTestResultParams) {
        newSearchSettings.searchByAll = true;
      }
    }
    setSearchSettings(newSearchSettings);
  };

  const handleShowInactive = (value?: boolean) => {
    const newIncludeInactive = value ? value : !searchSettings.includeInactive;
    (value == undefined) && updateSearchSettings('includeInactive', newIncludeInactive);
  };

  const handleExpandAll = (value?: boolean) => {
    const newExpandAll = value ? value : !searchSettings.expandAll;
    const updatedTestConfs: { [index: string]: TestConfExtendedSearchDto } = { ...filteredTestConfs };
    (value == undefined) && updateSearchSettings('expandAll', newExpandAll);
    Object.keys(filteredTestConfs).forEach(key => {
      const conf = filteredTestConfs[key];
      updatedTestConfs[key] = {
        ...conf,
        expanded: newExpandAll,
        testResultMapExpanded: newExpandAll,
        testResultMap: Object.keys(conf.testResultMap).reduce((resultMap, resultKey) => {
          const result = conf.testResultMap[resultKey];
          resultMap[resultKey] = {
            ...result,
            expanded: newExpandAll,
            rawScoresExpanded: newExpandAll,
            trueScoresExpanded: newExpandAll,
            testResultParamsExpanded: newExpandAll,
          };
          return resultMap;
        }, {} as { [index: string]: TestResultExtendedSearchDto }),
      };
    });
    setFilteredTestConfs(updatedTestConfs);
  };

  const handleExpandTests = (value?: boolean) => {
    const newExpandTests = value ? value : !searchSettings.expandTests;
    (value == undefined) && updateSearchSettings('expandTests', newExpandTests);

    const updatedTestConfs = { ...filteredTestConfs };
    Object.keys(updatedTestConfs).forEach(key => {
      updatedTestConfs[key] = {
        ...updatedTestConfs[key],
        expanded: newExpandTests,
      };
    });

    setFilteredTestConfs(updatedTestConfs);
  };

  const handleExpandTestResults = (value?: boolean) => {
    const newExpandTestResults = value ? value : !searchSettings.expandTestResults;
    (value == undefined) && updateSearchSettings('expandTestResults', newExpandTestResults);

    const updatedTestConfs = { ...filteredTestConfs };
    Object.keys(updatedTestConfs).forEach(key => {
      const conf = updatedTestConfs[key];
      updatedTestConfs[key] = {
        ...conf,
        testResultMapExpanded: newExpandTestResults,
        testResultMap: Object.keys(conf.testResultMap).reduce((resultMap, resultKey) => {
          const result = conf.testResultMap[resultKey];
          resultMap[resultKey] = {
            ...result,
            expanded: newExpandTestResults,
          };
          return resultMap;
        }, {} as { [index: string]: TestResultExtendedSearchDto }),
      };
    });

    setFilteredTestConfs(updatedTestConfs);
  };

  const handleExpandRawScoreRules = (value?: boolean) => {
    const newExpandRawScoreRules = value ? value : !searchSettings.expandRawScoreRules;
    updateSearchSettings('expandRawScoreRules', newExpandRawScoreRules);

    const updatedTestConfs = { ...filteredTestConfs };
    Object.keys(updatedTestConfs).forEach(key => {
      const conf = updatedTestConfs[key];
      if (conf.testResultMapExpanded) {
        updatedTestConfs[key] = {
          ...conf,
          testResultMap: Object.keys(conf.testResultMap).reduce((resultMap, resultKey) => {
            const result = conf.testResultMap[resultKey];
            if (result.expanded) {
              resultMap[resultKey] = {
                ...result,
                rawScoresExpanded: newExpandRawScoreRules,
              };
            } else {
              resultMap[resultKey] = { ...result };
            }
            return resultMap;
          }, {} as { [index: string]: TestResultExtendedSearchDto }),
        };
      }
    });

    setFilteredTestConfs(updatedTestConfs);
  };

  const handleExpandTrueScoreRules = (value?: boolean) => {
    const newExpandTrueScoreRules = value ? value : !searchSettings.expandTrueScoreRules;
    updateSearchSettings('expandTrueScoreRules', newExpandTrueScoreRules);

    const updatedTestConfs = { ...filteredTestConfs };
    Object.keys(updatedTestConfs).forEach(key => {
      const conf = updatedTestConfs[key];
      if (conf.testResultMapExpanded) {
        updatedTestConfs[key] = {
          ...conf,
          testResultMap: Object.keys(conf.testResultMap).reduce((resultMap, resultKey) => {
            const result = conf.testResultMap[resultKey];
            if (result.expanded) {
              resultMap[resultKey] = {
                ...result,
                trueScoresExpanded: newExpandTrueScoreRules,
              };
            } else {
              resultMap[resultKey] = { ...result };
            }
            return resultMap;
          }, {} as { [index: string]: TestResultExtendedSearchDto }),
        };
      }
    });

    setFilteredTestConfs(updatedTestConfs);
  };

  const handleExpandTestResultParams = (value?: boolean) => {
    const newExpandTestResultParams = value ? value : !searchSettings.expandTestResultParams;
    updateSearchSettings('expandTestResultParams', newExpandTestResultParams);

    const updatedTestConfs = { ...filteredTestConfs };
    Object.keys(updatedTestConfs).forEach(key => {
      const conf = updatedTestConfs[key];
      if (conf.testResultMapExpanded) {
        updatedTestConfs[key] = {
          ...conf,
          testResultMap: Object.keys(conf.testResultMap).reduce((resultMap, resultKey) => {
            const result = conf.testResultMap[resultKey];
            if (result.expanded) {
              resultMap[resultKey] = {
                ...result,
                testResultParamsExpanded: newExpandTestResultParams,
              };
            } else {
              resultMap[resultKey] = { ...result };
            }
            return resultMap;
          }, {} as { [index: string]: TestResultExtendedSearchDto }),
        };
      }
    });

    setFilteredTestConfs(updatedTestConfs);
  };

  const searchTestConfs = useCallback(() => {
    const newTestConfs = { ...testConfs };
    if (!searchSettings.searchValues.testConfSearchTerm) {
      const filtered = Object.keys(newTestConfs).map((key) => {
        let testConf = newTestConfs[key];
        if (filteredTestConfs[key]) {
          testConf = filteredTestConfs[key];
        }
        return testConf;
      });
      let res = _.keyBy(filtered, 'id');
      setFilteredTestConfs(res);
      return;
    }

    const filtered = Object.keys(newTestConfs).map((key) => {
      let testConf = newTestConfs[key];
      if (filteredTestConfs[key]) {
        testConf = filteredTestConfs[key];
      }
      const { testResultMap } = testConf;
      const newTestResultMap: { [p: string]: TestResultExtendedSearchDto } = { ...testResultMap };
      let testConfMatches = false;
      let resultMatches = false;
      let resultParamMatches = false;

      if (searchSettings.searchByAll || searchSettings.searchByTests) {
        if (getTestConfDescription(testConf)
        .toLowerCase()
        .includes(searchSettings.searchValues.testConfSearchTerm!.toLowerCase())) {
          testConfMatches = true;
        }
      }

      if (searchSettings.searchByAll || searchSettings.searchByTestResults) {
        Object.keys(testResultMap).forEach(resultKey => {
          const result = testResultMap[resultKey];
          if (getTestResultDescription(result)
          .toLowerCase()
          .includes(searchSettings.searchValues.testConfSearchTerm!.toLowerCase())) {
            resultMatches = true;
            newTestResultMap[resultKey] = { ...result, expanded: true };
          }
        });
      }

      if (searchSettings.searchByAll || searchSettings.searchByTestResultParams) {
        Object.keys(testResultMap).forEach(resultKey => {
          const result = testResultMap[resultKey];
          if (result.testResultParamMap && Object.values(result.testResultParamMap).some(param => {
            return getTestResultParamDescription(param)
            .toLowerCase()
            .includes(searchSettings.searchValues.testConfSearchTerm!.toLowerCase());
          })) {
            resultParamMatches = true;
            newTestResultMap[resultKey] = { ...result, testResultParamsExpanded: true, expanded: true };
          }
        });
      }

      if (resultMatches || resultParamMatches) {
        return {
          ...testConf,
          expanded: true,
          testResultMapExpanded: true,
          testResultMap: { ...newTestResultMap },
        };
      } else if (testConfMatches) {
        return { ...testConf, expanded: true, testResultMap: { ...newTestResultMap } };
      }

      return null;
    }).filter(item => item !== null).reduce((acc, key) => {
      const a = key as TestConfExtendedSearchDto;
      acc[a!.id] = a;
      return acc;
    }, {} as { [index: string]: TestConfExtendedSearchDto });

    setFilteredTestConfs(filtered);
  }, [testConfs, searchSettings, language]);

  const highlightText = (fullText: string, searchTerm: string) => {
    if (!searchTerm) return fullText;
    const escapedSearchTerm = searchTerm.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const regex = new RegExp(`(${escapedSearchTerm})`, 'gi');
    const parts = fullText.split(regex);
    return parts.map((part, index) =>
      regex.test(part) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part,
    );
  };

  const instructionContent = (instruction: string) => {
    return (
      <StyledPre>
        {instruction}
      </StyledPre>
    );
  };

  const getTestResultParamDescription = (testResultParam: TestResultParamDto) => {
    const texts: { [key: string]: string } = {
      'de': testResultParam.description || '',
      'en': testResultParam.descriptionEn || testResultParam.description || '',
      'fr': testResultParam.descriptionFr || testResultParam.description || '',
      'it': testResultParam.descriptionIt || testResultParam.description || '',
    };
    return texts[language];
  };

  const getDomainDescription = (domain: DomainDto) => {
    const texts: { [key: string]: string } = {
      'de': domain.description || '',
      'en': domain.descriptionEn || domain.description || '',
      'fr': domain.descriptionFr || domain.description || '',
      'it': domain.descriptionIt || domain.description || '',
    };
    return texts[language];
  };

  const getTestResultDescription = (testResult: TestResultExtendedSearchDto) => {
    const texts: { [key: string]: string } = {
      'de': testResult.description || '',
      'en': testResult.descriptionEn || testResult.description || '',
      'fr': testResult.descriptionFr || testResult.description || '',
      'it': testResult.descriptionIt || testResult.description || '',
    };
    return texts[language];
  };

  const getTestConfDescription = (testConf: TestConfExtendedSearchDto) => {
    const texts: { [key: string]: string } = {
      'de': testConf.description || '',
      'en': testConf.descriptionEn || testConf.description || '',
      'fr': testConf.descriptionFr || testConf.description || '',
      'it': testConf.descriptionIt || testConf.description || '',
    };
    return texts[language];
  };

  const getInstructionTherapist = (testConf: TestConfExtendedSearchDto) => {
    const texts: { [key: string]: string } = {
      'de': testConf.instructionTherapist || '',
      'en': testConf.instructionTherapistEn || testConf.instructionTherapist || '',
      'fr': testConf.instructionTherapistFr || testConf.instructionTherapist || '',
      'it': testConf.instructionTherapistIt || testConf.instructionTherapist || '',
    };
    return texts[language];
  };

  const getInstructionPatient = (testConf: TestConfExtendedSearchDto) => {
    const texts: { [key: string]: string } = {
      'de': testConf.instructionPatient || '',
      'en': testConf.instructionPatientEn || testConf.instructionPatient || '',
      'fr': testConf.instructionPatientFr || testConf.instructionPatient || '',
      'it': testConf.instructionPatientIt || testConf.instructionPatient || '',
    };
    return texts[language];
  };

  const instructionPopupTrigger = (buttonText: string, instructionTrigger?: boolean) => {

    if (!instructionTrigger) {
      return false;
    }

    return (
      <PopupTriggerContainer>
        <Icon name='circle outline'
              style={{
                fontSize: '1.55rem',
              }}
        >
        </Icon>
        <StyledTextDisplayDiv>
          {buttonText}
        </StyledTextDisplayDiv>
      </PopupTriggerContainer>
    );
  };

  const instructions = (testConf: TestConfExtendedSearchDto) => {
    return (
      <>
        {
          testConf.instructionTherapist
            ? <Popup
              trigger={instructionPopupTrigger(t('testconf.therapistInstruction'), !!testConf?.instructionTherapist)}
              content={instructionContent(getInstructionTherapist(testConf))}
              on='hover'
              size='large'
              position='bottom right'
              wide='very'
            />
            : <> --- </>
        }
        <> /</>
        {
          testConf.instructionPatient
            ? <Popup
              trigger={instructionPopupTrigger(t('testconf.patientInstruction'), !!testConf?.instructionPatient)}
              content={instructionContent(getInstructionPatient(testConf))}
              on='hover'
              size='large'
              position='bottom left'
              wide='very'
            />
            : <> --- </>
        }

      </>
    );
  };

  const formulaPopup = (rawScoreResultExportDto: RawScoreResultExtendedSearchDto) => {
    return (
      <Popup
        trigger={instructionPopupTrigger(t('rawscoreresultshort.formula'), !!rawScoreResultExportDto?.formula)}
        content={instructionContent(rawScoreResultExportDto.formula)}
        on='hover'
        size='large'
        position='bottom center'
        wide='very'
      />
    );
  };

  const handleClinicDropdownChange = (value: any) => {
    if (Array.isArray(value)) {
      const selected = value as string[];
      updateSearchSettings('selectedClinics', selected);
    }
  };

  const handleIndeterminateCheckBox = (flag: boolean, value: keyof TestSearchSettings) => {
    if (searchSettings.expandAll && flag) {
      updateSearchSettings('expandAll', undefined);
    }
    if (flag) {
      updateSearchSettings(value, undefined);
    }
  };

  const handleTestConfOnExpand = (id: string) => {
    setFilteredTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      updatedTestConfs[id] = {
        ...updatedTestConfs[id],
        expanded: !updatedTestConfs[id].expanded,
      };
      return updatedTestConfs;
    });
    handleIndeterminateCheckBox(searchSettings.expandTests!, 'expandTests');
  };

  const handleTestConfTestResultsOnExpand = (id: string) => {
    setFilteredTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      updatedTestConfs[id] = {
        ...updatedTestConfs[id],
        testResultMapExpanded: !updatedTestConfs[id].testResultMapExpanded,
      };
      return updatedTestConfs;
    });
    handleIndeterminateCheckBox(searchSettings.expandTestResults!, 'expandTestResults');
  };

  const handleTestResultOnExpand = (testResultid: string, testConfId: string) => {
    setFilteredTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      const updatedTestResults = { ...updatedTestConfs[testConfId].testResultMap };
      updatedTestResults[testResultid] = {
        ...updatedTestResults[testResultid],
        expanded: !updatedTestResults[testResultid].expanded,
      };
      updatedTestConfs[testConfId] = {
        ...updatedTestConfs[testConfId],
        testResultMap: { ...updatedTestResults },
      };
      return updatedTestConfs;
    });
    handleIndeterminateCheckBox(searchSettings.expandTestResults!, 'expandTestResults');
  };

  const handleRawScoresOnExpand = (testResultid: string, testConfIndex: string) => {
    setFilteredTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      const updatedTestResults = { ...updatedTestConfs[testConfIndex].testResultMap };
      updatedTestResults[testResultid] = {
        ...updatedTestResults[testResultid],
        rawScoresExpanded: !updatedTestResults[testResultid].rawScoresExpanded,
      };
      updatedTestConfs[testConfIndex] = {
        ...updatedTestConfs[testConfIndex],
        testResultMap: { ...updatedTestResults },
      };
      return updatedTestConfs;
    });
    handleIndeterminateCheckBox(searchSettings.expandRawScoreRules!, 'expandRawScoreRules');
  };

  const handleTrueScoresOnExpand = (testResultid: string, testConfIndex: string) => {
    setFilteredTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      const updatedTestResults = { ...updatedTestConfs[testConfIndex].testResultMap };
      updatedTestResults[testResultid] = {
        ...updatedTestResults[testResultid],
        trueScoresExpanded: !updatedTestResults[testResultid].trueScoresExpanded,
      };
      updatedTestConfs[testConfIndex] = {
        ...updatedTestConfs[testConfIndex],
        testResultMap: { ...updatedTestResults },
      };
      return updatedTestConfs;
    });
    handleIndeterminateCheckBox(searchSettings.expandTrueScoreRules!, 'expandTrueScoreRules');
  };

  const handleTestResultParamsOnExpand = (testResultid: string, testConfIndex: string) => {
    setFilteredTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      const updatedTestResults = { ...updatedTestConfs[testConfIndex].testResultMap };
      updatedTestResults[testResultid] = {
        ...updatedTestResults[testResultid],
        testResultParamsExpanded: !updatedTestResults[testResultid].testResultParamsExpanded,
      };
      updatedTestConfs[testConfIndex] = {
        ...updatedTestConfs[testConfIndex],
        testResultMap: { ...updatedTestResults },
      };
      return updatedTestConfs;
    });
    handleIndeterminateCheckBox(searchSettings.expandTestResultParams!, 'expandTestResultParams');
  };

  const handleRawScoreResultOnExpand = (testRawScoreResultId: string, testResultid: string, testConfIndex: string) => {
    setFilteredTestConfs(prevTestConfs => {
      const updatedTestConfs = { ...prevTestConfs };
      const updatedTestResults = { ...updatedTestConfs[testConfIndex].testResultMap };
      const updatedRawScoreResults = { ...updatedTestResults[testResultid].rawScoreResultMap };
      updatedRawScoreResults[testRawScoreResultId] = {
        ...updatedRawScoreResults[testRawScoreResultId],
        expanded: !updatedRawScoreResults[testRawScoreResultId].expanded,
      };
      updatedTestResults[testResultid].rawScoreResultMap = { ...updatedRawScoreResults };
      updatedTestConfs[testConfIndex] = {
        ...updatedTestConfs[testConfIndex],
        testResultMap: { ...updatedTestResults },
      };
      return updatedTestConfs;
    });
  };

  const renderRange = (
    fromValue: string | number,
    fromInclusive: boolean,
    toValue: string | number,
    toInclusive: boolean,
    labelInTheMiddle: string,
    rangeEnum: RangeEnum,
  ) => {
    const beforeLabel = fromValue != undefined || fromValue != null ? fromValue + (fromInclusive ? ' ≥' : ' >') : '';
    const afterLabel = toValue != undefined || toValue != null ? ((toInclusive ? '≤ ' : '< ') + toValue) : '';

    if (RangeEnum.TRUE_SCORE === rangeEnum || RangeEnum.EDUCATIONAL_YEARS === rangeEnum) {
      return <>
        <div className='grid-item align-right'>
          {beforeLabel}
        </div>
        <div className='grid-item'>
          {labelInTheMiddle}
        </div>
        <div className='grid-item align-left'>
          {afterLabel}
        </div>
      </>;
    }

    if (RangeEnum.AGE_RANGE === rangeEnum) {
      return <>
        <div className='grid-item align-right'>
          {fromValue != undefined || fromValue != null ? fromValue : ''}
        </div>
        <div className='grid-item'>
          {labelInTheMiddle}
        </div>
        <div className='grid-item align-left'>
          {toValue != undefined || toValue != null ? toValue : ''}
        </div>
      </>;
    }

    return beforeLabel + labelInTheMiddle + afterLabel;
  };

  const renderTestResultParamContent = (testResult: TestResultExtendedSearchDto) => {

    const filteredTestResultParam = Object.keys(testResult.testResultParamMap).filter((testResultParamId) => {
      const testResultParam = testResult.testResultParamMap[testResultParamId];
      return searchSettings.includeInactive || testResultParam.active;
    });
    return filteredTestResultParam.map((testResultParamId: string) => {
      const testResultParam: TestResultParamDto = testResult.testResultParamMap[testResultParamId];
      return <div style={{ marginTop: '5px' }} key={testResultParamId}>
        {(searchSettings.searchByTestResultParams || searchSettings.searchByAll)
          ? highlightText(
            getTestResultParamDescription(testResultParam),
            searchSettings.searchValues.testConfSearchTerm ? searchSettings.searchValues.testConfSearchTerm : '',
          )
          : getTestResultParamDescription(testResultParam)} / {
        renderRange(
          testResultParam.minimumValue,
          testResultParam.minimumInclusive,
          testResultParam.maximumValue,
          testResultParam.maximumInclusive,
          testResultParam.unit,
          RangeEnum.TEST_PARAM,
        )
      } / {testResultParam.code}
      </div>;
    });
  };

  const renderTrueScoreResultContent = (testResult: TestResultExtendedSearchDto) => {
    const filteredTrueScoreResults = Object.keys(testResult.trueScoreResultMap)
    .filter((trueScoreResultId) => {
      const trueScoreResult = testResult.trueScoreResultMap[trueScoreResultId];
      return searchSettings.includeInactive || trueScoreResult.active;
    })
    .sort((a, b) => {
      const trueScoreA = testResult.trueScoreResultMap[a];
      const trueScoreB = testResult.trueScoreResultMap[b];

      if (trueScoreA.fromScoreRaw === null && trueScoreB.fromScoreRaw === null) return 0;
      if (trueScoreA.fromScoreRaw === null) return -1;
      if (trueScoreB.fromScoreRaw === null) return 1;

      return trueScoreA.fromScoreRaw - trueScoreB.fromScoreRaw;
    });
    return filteredTrueScoreResults.map((trueScoreResultId: string) => {
      const trueScoreResult: TrueScoreResultExtendedSearchDto = testResult.trueScoreResultMap[trueScoreResultId];
      return <StyledRangeRow style={{ marginTop: '5px' }}
                             key={trueScoreResultId}
      >
        {renderRange(
          trueScoreResult.fromScoreRaw,
          trueScoreResult.fromInclusive,
          trueScoreResult.toScoreRaw,
          trueScoreResult.toInclusive,
          'X',
          RangeEnum.TRUE_SCORE,
        )}
        <div className='grid-item'>{t(`truescoreresult.trueScore.${trueScoreResult.trueResult}`)}</div>
        <div className='grid-item'>{t(`scoreRulesType.${trueScoreResult.trueScoreResultType}`)}</div>
      </StyledRangeRow>
        ;
    });
  };

  const renderRawScoreResultContent = (
    testResult: TestResultExtendedSearchDto,
    testResultId: string,
    testConfId: string,
  ) => {
    return Object.keys(testResult.rawScoreResultMap).map((rawScoreResultId: string) => {
      const rawScoreResult: RawScoreResultExtendedSearchDto = testResult.rawScoreResultMap[rawScoreResultId];
      const ageRange = rawScoreResult.ageRange.split('-');
      const education = rawScoreResult.education.split('-');
      const sex = rawScoreResult.sex == 'OTHER' ? Sex.ALL : rawScoreResult.sex;
      const ageRangeRendered = renderRange(ageRange[0], false, ageRange[1], true, 'X', RangeEnum.AGE_RANGE);
      // TODO: Change this when inclusive option is added
      const educationRendered = renderRange(education[0], false, education[1], true, 'X', RangeEnum.EDUCATIONAL_YEARS);
      return (isSystemAdmin
          ? <AccordionWithArrow expanded={rawScoreResult.expanded}
                                key={rawScoreResult.id}
                                onExpand={() => handleRawScoreResultOnExpand(rawScoreResultId, testResultId, testConfId)}>
            <CustomTitle>
              <StyledRangeRow className='raw-score'>
                {ageRangeRendered}{educationRendered}
                <div className='grid-item'>{sex}</div>
                <div className='grid-item'>{rawScoreResult.formula && <>{formulaPopup(rawScoreResult)}</>}</div>
              </StyledRangeRow>
            </CustomTitle>
            <CustomContent>
              <pre><code>{rawScoreResult.formula}</code></pre>
            </CustomContent>
          </AccordionWithArrow>
          : <StyledRangeRow className='raw-score-wout-formula'>
            {ageRangeRendered}
            {educationRendered}
            <div className='grid-item'>{sex}</div>
          </StyledRangeRow>
      );

    });
  };

  const renderTestResultContent = (testConf: TestConfExtendedSearchDto, testConfId: string) => {
    const filteredTestResults = Object.keys(testConf.testResultMap).filter((testResultId) => {
      const testResult = testConf.testResultMap[testResultId];
      return searchSettings.includeInactive || testResult.active;
    });

    return filteredTestResults.map((testResultId: string) => {
      const testResult: TestResultExtendedSearchDto = testConf.testResultMap[testResultId];
      const rawScoreMapLength = Object.keys(testResult.rawScoreResultMap).length;
      const trueScoreMapLength = Object.keys(testResult.trueScoreResultMap).filter((key) => {
        return searchSettings.includeInactive || testResult.trueScoreResultMap[key].active;
      }).length;
      const paramsMapLength = Object.keys(testResult.testResultParamMap).filter((key) => {
        return searchSettings.includeInactive || testResult.testResultParamMap[key].active;
      }).length;
      return <AccordionWithArrow expanded={testResult.expanded}
                                 key={testResultId}
                                 onExpand={() => handleTestResultOnExpand(testResultId, testConfId)}>
        <CustomTitle>
          {(searchSettings.searchByTestResults || searchSettings.searchByAll)
            ? highlightText(getTestResultDescription(testResult), searchSettings.searchValues.testConfSearchTerm
              ? searchSettings.searchValues.testConfSearchTerm
              : '',
            )
            : getTestResultDescription(testResult)}
          {testResult.domain != null && <> / {getDomainDescription(testResult.domain)}</>}
          {(testResult.scoreRulesType != null && testResult.overrideScoreRulesType)
            ? <> / {t(`scoreRulesType.${testResult.scoreRulesType}`)}</>
            : <> / ---</>}
          <> /</>
        </CustomTitle>
        <CustomContent>
          {
            rawScoreMapLength > 0 &&
            <AccordionWithArrow
              expanded={testResult.rawScoresExpanded}
              onExpand={() => handleRawScoresOnExpand(testResultId, testConfId)}
            >
              <CustomTitle>
                <Label>{t('rawscoreresult.rules')} ({rawScoreMapLength}): </Label>
              </CustomTitle>
              <CustomContent>
                {renderRawScoreResultContent(testResult, testResultId, testConfId)}
              </CustomContent>
            </AccordionWithArrow>
          }
          {
            trueScoreMapLength > 0 &&
            <AccordionWithArrow
              expanded={testResult.trueScoresExpanded}
              onExpand={() => handleTrueScoresOnExpand(testResultId, testConfId)}
            >
              <CustomTitle>
                <Label>{t('truescoreresult.rules')} ({trueScoreMapLength}) : </Label>
              </CustomTitle>
              <CustomContent>
                {renderTrueScoreResultContent(testResult)}
              </CustomContent>
            </AccordionWithArrow>
          }
          {
            paramsMapLength > 0 &&
            <AccordionWithArrow
              expanded={testResult.testResultParamsExpanded}
              onExpand={() => handleTestResultParamsOnExpand(testResultId, testConfId)}
            >
              <CustomTitle>
                <Label>{t('testresult.params')} ({paramsMapLength}) : </Label>
              </CustomTitle>
              <CustomContent>
                {renderTestResultParamContent(testResult)}
              </CustomContent>
            </AccordionWithArrow>
          }
        </CustomContent>
      </AccordionWithArrow>;
    });
  };

  const renderTestConfigRows = () => {
    const map = { ...filteredTestConfs };
    const filteredConfigs = Object.keys(map).filter((testConfId) => {
      const testConf = map[testConfId];
      return (
        (searchSettings.includeInactive || testConf.active) &&
        (searchSettings.selectedClinics.length < 1 ||
          testConf.clinics.some(clinic => searchSettings.selectedClinics.includes(clinic)))
      );
    });

    return (
      <ScrollableTsaGrid style={
        isIphone ? { marginTop: '130px' } :
          isIpad ? { marginTop: '60px', width: 'calc(100% - 35px)' } :
            { marginTop: '40px', width: 'calc(100% - 245px)' }}>
        {filteredConfigs.map((testConfId) => {
          const testConf = map[testConfId];
          const testResultMapLength = Object.keys(testConf.testResultMap).filter((key) => {
            return searchSettings.includeInactive || testConf.testResultMap[key].active;
          }).length;

          return (
            <AccordionWithArrow
              key={testConfId}
              expanded={testConf.expanded}
              onExpand={() => handleTestConfOnExpand(testConfId)}
            >
              <CustomTitle>
                {(searchSettings.searchByTests || searchSettings.searchByAll)
                  ? highlightText(
                    getTestConfDescription(testConf),
                    searchSettings.searchValues.testConfSearchTerm ? searchSettings.searchValues.testConfSearchTerm : '',
                  )
                  : getTestConfDescription(testConf)}
                <> /</>
                {instructions(testConf)}
                {testConf.scoreRulesType != null ? <> / {t(`scoreRulesType.${testConf.scoreRulesType}`)}</> : <> /
                  ---</>}
                {isSystemAdmin && <>
                  <> / </>
                  <a
                    style={{ textDecoration: 'underline' }}
                    onClick={() => {
                      history.push('/extended-tests-search/find', {
                        testConfId: testConfId,
                        scoreRulesType: testConf.scoreRulesType,
                        copy: false,
                        prevPath: 'extended-tests-search',
                      });
                    }}
                  >
                    {t('button.find')}
                  </a>
                </>}
              </CustomTitle>
              <CustomContent>
                {testResultMapLength > 0 && testConf.expanded && (
                  <AccordionWithArrow
                    expanded={testConf.testResultMapExpanded}
                    onExpand={() => handleTestConfTestResultsOnExpand(testConfId)}
                  >
                    <CustomTitle>
                      <Label>{t('patientTesting.testResultsTitle')} ({testResultMapLength}): </Label>
                    </CustomTitle>
                    <CustomContent>
                      {renderTestResultContent(testConf, testConfId)}
                    </CustomContent>
                  </AccordionWithArrow>
                )}
              </CustomContent>
            </AccordionWithArrow>
          );
        })}
      </ScrollableTsaGrid>
    );
  };

  const renderFormBar = () => {
    const expandAll = searchSettings.expandAll;
    const expandTests = searchSettings.expandTests;
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={isIphone ? 8 : isIpad ? 8 : 6}><h2>{t('testconf.searchTitle')}</h2></Grid.Column>
          <Grid.Column width={isIphone ? 8 : isIpad ? 8 : 2} floated={'right'}>
            {
              isSystemAdmin && <FormBarContainer>
                <Checkbox
                  id='includeInactive'
                  checked={searchSettings.includeInactive}
                  onChange={() => handleShowInactive()}
                />
                <label htmlFor='includeInactive'>{t('button.includeInactive')}</label>
              </FormBarContainer>
            }
          </Grid.Column>
          <Grid.Column width={isIphone ? 16 : isIpad ? 2 : 1}>
            <FormBarLabel>
              <div>{t('testconf.expand')}:</div>
            </FormBarLabel>
          </Grid.Column>
          <Grid.Column width={isIphone ? 5 : isIpad ? 2 : 1}>
            <FormBarContainer>
              <Checkbox
                indeterminate={expandAll == undefined}
                id='expandAll'
                checked={expandAll}
                onChange={() => handleExpandAll()}
              />
              <label htmlFor='expandAll'>{t('sex.ALL')}</label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 5 : isIpad ? 2 : 1}>
            <FormBarContainer>
              <Checkbox
                indeterminate={expandTests == undefined}
                id='expandTests'
                checked={expandTests}
                onChange={() => handleExpandTests()}
              />
              <label htmlFor='expandTests'>{t('clinic.config.testConf')}</label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 5 : isIpad ? 4 : 2}>
            <FormBarContainer>
              <Checkbox
                indeterminate={searchSettings.expandTestResults == undefined}
                id='expandTestResults'
                checked={searchSettings.expandTestResults}
                onChange={() => handleExpandTestResults()}
              />
              <label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='expandTestResults'
              >
                {t('patientTesting.testResultsTitle')}
              </label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 5 : isIpad ? 2 : 1}>
            <FormBarContainer>
              <Checkbox
                indeterminate={searchSettings.expandRawScoreRules == undefined}
                id='expandRawScoreRules'
                checked={searchSettings.expandRawScoreRules}
                onChange={() => handleExpandRawScoreRules()}
              />
              <label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='expandRawScoreRules'
              >
                {t('testconf.rawScoreRulesShort')}
              </label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 5 : isIpad ? 2 : 1}>
            <FormBarContainer>
              <Checkbox
                indeterminate={searchSettings.expandTrueScoreRules == undefined}
                id='expandTrueScoreRules'
                checked={searchSettings.expandTrueScoreRules}
                onChange={() => handleExpandTrueScoreRules()}
              />
              <label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='expandTrueScoreRules'
              >
                {t('testconf.scoreRulesType')}
              </label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 5 : isIpad ? 2 : 1}>
            <FormBarContainer>
              <Checkbox
                indeterminate={searchSettings.expandTestResultParams == undefined}
                id='expandTestResultParams'
                checked={searchSettings.expandTestResultParams}
                onChange={() => handleExpandTestResultParams()}
              />
              <label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='expandTestResultParams'
              >
                {t('patientTesting.params')}
              </label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column
            width={isIphone ? 16 : isIpad ? 2 : 1}
            floated={isIphone || isIpad ? undefined : 'right'}
          >
            <FormBarContainer>
              <FormBarLabel>{t('button.search')}:</FormBarLabel>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 5 : isIpad ? 2 : 1}>
            <FormBarContainer>
              <Checkbox
                id='searchByAll'
                checked={searchSettings.searchByAll}
                onChange={() => {
                  updateSearchSettings('searchByTests', false);
                  updateSearchSettings('searchByTestResults', false);
                  updateSearchSettings('searchByAll', !searchSettings.searchByAll);
                }}
              />
              <label htmlFor='searchByAll'>{t('sex.ALL')}</label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 5 : isIpad ? 2 : 1}>
            <FormBarContainer>
              <Checkbox
                id='searchByTests'
                checked={searchSettings.searchByTests}
                onChange={() => {
                  if (!searchSettings.searchByTestResults
                    && searchSettings.searchByTests
                    && !searchSettings.searchByTestResultParams) {
                    updateSearchSettings('searchByAll', true);
                  } else updateSearchSettings('searchByAll', false);
                  updateSearchSettings('searchByTests', !searchSettings.searchByTests);
                }}
              />
              <label htmlFor='searchByTests'>{t('clinic.config.testConf')}</label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={isIphone ? 5 : isIpad ? 4 : 4}>
            <FormBarContainer>
              <Checkbox
                id='searchByTestResults'
                checked={searchSettings.searchByTestResults}
                onChange={() => {
                  if (searchSettings.searchByTestResults
                    && !searchSettings.searchByTests
                    && !searchSettings.searchByTestResultParams) {
                    updateSearchSettings('searchByAll', true);
                  } else updateSearchSettings('searchByAll', false);
                  updateSearchSettings('searchByTestResults', !searchSettings.searchByTestResults);
                }}
              />
              <label
                style={{ whiteSpace: 'nowrap' }}
                htmlFor='searchByTestResults'
              >
                {t('patientTesting.testResultsTitle')}
              </label>
            </FormBarContainer>
          </Grid.Column>
          <Grid.Column width={1}>
            <FormBarContainer>
              <Checkbox
                id='searchByTestResultParams'
                checked={searchSettings.searchByTestResultParams}
                onChange={() => {
                  if (!searchSettings.searchByTestResults
                    && !searchSettings.searchByTests
                    && searchSettings.searchByTestResultParams) {
                    updateSearchSettings('searchByAll', true);
                  } else updateSearchSettings('searchByAll', false);
                  updateSearchSettings('searchByTestResultParams', !searchSettings.searchByTestResultParams);
                }}
              />
              <label htmlFor='searchByTestResultParams'>{t('patientTesting.params')}</label>
            </FormBarContainer>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '0px' }}>
          <Grid.Column width={isIphone ? 8 : isIpad ? 6 : 3} floated={isIphone ? 'left' : 'right'}
                       style={{ paddingRight: '0px' }}>
            {
              isSystemAdmin && <FormBarContainer>
                <Dropdown
                  style={{
                    marginLeft: isIphone ? '0px' : '10px',
                    paddingRight: isIphone ? '0px' : '10px',
                    display: 'flex',
                    alignItems: 'center',
                    width: '300px',
                  }}
                  placeholder={t('clinic.selectClinics')}
                  fluid
                  multiple
                  search
                  selection
                  options={clinicOptions}
                  value={searchSettings.selectedClinics}
                  onChange={(e, { value }) => handleClinicDropdownChange(value)}
                  renderLabel={renderLabel}
                />
              </FormBarContainer>
            }
          </Grid.Column>
          <Grid.Column width={isIphone ? 8 : isIpad ? 5 : isLaptop ? 3 : 2}>
            <Input
              style={{ marginLeft: isIphone ? '0px' : '10px' }}
              type='text'
              placeholder={t('button.search')}
              value={searchSettings.searchValues.testConfSearchTerm}
              onChange={(e) => updateSearchSettings('searchValues', {
                ...searchSettings.searchValues,
                testConfSearchTerm: e.target.value,
              })}
            />
          </Grid.Column>
          <Grid.Column width={isIphone ? 1 : isIpad ? 5 : isLaptop ? 2 : 1} verticalAlign={'middle'}>
            {!isIphone &&
              <Dropdown
                style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}
                className='language-menu-item'
                text={t(`language.${currentLanguage}`)}
                direction='right'
                icon='chevron up'
                value={currentLanguage}
                options={langOptions}
                onChange={(evt, data) => {
                  const value = data.value as string;
                  updateLanguage(value);
                }}
                selectOnBlur
                selectOnNavigation={false}
                fluid
              />
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  return (
    <Grid>
      {renderFormBar()}
      {formDataLoaded
        ? <React.Fragment>
          {renderTestConfigRows()}
        </React.Fragment>
        : <LoaderComponent message={t('general.loading')} />
      }
    </Grid>
  );
};

export default TestConfExtendedSearchView;