import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import ArchiveTestingConfirmation from 'components/ArchiveTestingConfirmation';
import CompositeButton from 'components/final-form/CompositeButton';
import PatientInfoRenderComponent from 'components/final-form/PatientInfoRenderComponent';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import SpecialLabel from 'components/final-form/SpecialLabel';
import InnerTsaGrid from 'components/InnerTsaGrid';
import LoaderComponent from 'components/LoaderComponent';
import ReportPdfModal from 'components/ReportPdfModal';
import TsaGrid from 'components/TsaGrid';
import { useClinicHeaderContext } from 'context/ClinicHeaderContext';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import _, { toInteger } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Grid, TextArea } from 'semantic-ui-react';
import { getPatientExercisingPreviewPdfUrl, previewExercisingResults } from 'service/patientExercisingResultService';
import {
  archivePatientExercising,
  getPatientExercising,
  saveExercisingEvaluation,
} from 'service/patientExercisingService';
import { getExerciseResultParams, getQuestionAnswerPairsForPatientExercising } from 'service/teresaConfService';


import styled from 'styled-components';
import { PatientTestingStatus } from 'ts-types/api.enums';
import {
  ExerciseDomainPreview,
  ExerciseResultPreview,
  ExercisingResultPreview,
  PatientDto,
  PdfResponse,
  QuestionDto,
  QuestionSetChartDto,
  QuestionSetDto,
  TestResultParamDto,
  UpsertPatientExercisingDto,
  UpsertQuestionSetRequest,
} from 'ts-types/api.types';


const TestLabel = styled.div`
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1rem;
  color: var(--light-blue);
  text-transform: uppercase;
`;

const CustomToolTipDiv = styled.div`
    background-color: white;
    border: 1px solid var(--light-gray);
    padding: 10px;

    & .first-qualitative-comment {
        padding-top: 10px;
        border-top: 1px solid rgb(212, 217, 224);
    }
`;

const ResultRaw = styled(Grid.Row)`
    min-height: 0 !important;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    padding-bottom: 0 !important;

    div.flexEnd {
        display: flex;
        align-items: flex-end;
    }

    .column {
        margin-left: 5px;
    }

    .column-width-right {
        width: 36.25% !important;
    }

    @media only screen and (max-width: 1898px) {
        .column-width-right .multiple-answers {
            line-height: 18px;
        }
    }

    @media only screen and (max-width: 1510px) {
        .column-width-right {
            width: 34.25% !important;
        }
    }

    @media only screen and (max-width: 1128px) {
        .column-width-right {
            width: 33.25% !important;
        }
    }
`;

const EvaluationSection = styled(Grid.Column)`
    display: flex;
    background-color: var(--very-light-gray);
    border-radius: 5px;
    padding-bottom: 10px !important;
    padding-left: 10px !important;
    padding-top: 0.5rem;
    margin-bottom: 0.5rem;
`;

const StyledLineChart = styled.div`
    & span.recharts-legend-item-text {
        display: inline-block !important;
        width: 15em !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        vertical-align: middle !important;
    }

    & .recharts-tooltip-item-list > li > .recharts-tooltip-item-name {
        display: inline-block !important;
        max-width: 30em !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        vertical-align: top !important;
    }
`;

const cancelTokenSource = axios.CancelToken.source();

const PreviewExerciseResultPage = () => {

  const { state } = useLocation();
  const { language } = useAuthContext();

  const {setClinicId} = useClinicHeaderContext();

  const patientExercisingId: number | undefined = state?.patientExercisingId ? Number(state?.patientExercisingId) : undefined;
  const patient: PatientDto = state.patient;
  const prevPath: string | undefined = state?.prevPath ? state?.prevPath : undefined;
  const testProgress: string | undefined = state.testProgress ? state.testProgress : undefined;
  const { t } = useTranslation('teresa');
  const history = useHistory();
  const [patientExercising, setPatientExercising] = useState<UpsertPatientExercisingDto>();
  const [exerciseResultParams, setExerciseResultParams] = useState<TestResultParamDto[]>([]);
  const [preview, setPreview] = useState<ExercisingResultPreview>();
  const [questionSetChart, setQuestionSetChart] = useState<QuestionSetChartDto[]>([]);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [evaluation, setEvaluation] = React.useState<string>('');
  const doctorId: number | undefined = state?.doctorId
    ? Number(state?.doctorId)
    : undefined;

  const isIphone = useIsIpadWidthOrBelow(430);
  const isIpad = useIsIpadWidthOrBelow(1280);

  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const [printing, setPrinting] = React.useState(false);

  const [previewReportPdfResponse, setPreviewReportPdfResponse] =
    useState<PdfResponse | undefined>(undefined);

  const [previewReportPdfModalOpen, setPreviewReportPdfModalOpen] = useState<boolean>(false);

  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    setClinicId(state?.clinicId ? Number(state.clinicId) : undefined)
    fetchPatientExercise();
    fetchQuestionSetChartData();
  }, []);

  const fetchPatientExercise = async () => {
    if (patientExercisingId) {
      setDataLoaded(false);
      try {
        const patientExercising =
          await getPatientExercising(patientExercisingId, true, cancelTokenSource);
        const patientExercisingPreview = await previewExercisingResults(patientExercisingId, cancelTokenSource);

        const exerciseResultParamsResponse = await getExerciseResultParams(true, cancelTokenSource);
        setExerciseResultParams(exerciseResultParamsResponse);
        setPreview(patientExercisingPreview);
        setPatientExercising(patientExercising);
        setEvaluation(patientExercising.evaluation);

      } catch (e) {
        handleError(e.response.data);
      } finally {
        setDataLoaded(true);
      }
    }
  };

  const handleOnBeforeGetContent = React.useCallback(() => {
    setPrinting(true);

    return new Promise((resolve): void => {
      // @ts-ignore
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        setPrinting(false);
        // @ts-ignore
        resolve();
      }, 2000);
    });
  }, [setPrinting]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const pdfDocumentTitle = () => {
    let title = t('patientExercising.docTitle');

    if (patientExercising) {

      if (patientExercising.patientId) {
        title += `_${patientExercising.patientId}`;
      }

      if (patientExercising.completeDate) {
        title += `_${moment(patientExercising.completeDate).format('YYYYMMDD')}`;
      } else if (patientExercising.creationDate) {
        title += `_${moment(patientExercising.creationDate).format('YYYYMMDD')}`;
      }
    }

    return title;
  };

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: pdfDocumentTitle(),
    onBeforeGetContent: handleOnBeforeGetContent,
    removeAfterPrint: true,
    pageStyle: `
      @media print {
        .page-break {
          page-break-after: always;
        }
        .page-number {
          display: inline-block !important;
        }
        
        .eval-container {
          display: inline-block !important;
        }
      }
      
      @page {
        size: A4 portrait;
        margin: 5%;
      }
    `,
  });

  const fetchQuestionSetChartData = async () => {
    try {
      const request: Partial<UpsertQuestionSetRequest> = {
        patientExercisingId: patientExercisingId,
      };
      const questionSetChartResponse = await getQuestionAnswerPairsForPatientExercising(request, cancelTokenSource);
      setQuestionSetChart(questionSetChartResponse);
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const handleError = (error: any) => {

    if (error) {
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            setErrorMessage(t(`error.${violation.errorCode}`));
          }
        });
      }

      if (!errorMessages.length) {
        if (knownErrors.includes(errorCode)) {
          setErrorMessage(t(`error.${errorCode}`));
        } else {
          setErrorMessage(t('error.general'));
        }
      }
    }
  };

  const setErrorMessage = (errorMessage?: string) => {

    if (errorMessage) {

      const errMsgs = [...errorMessages];
      errMsgs.push(errorMessage);
      setErrorMessages(errMsgs);
    } else {
      setErrorMessages([]);
    }
  };

  const goToPreviousPage = () => {
    if (prevPath === '/') {
      history.push(prevPath);
    } else if (prevPath === '/doctor/details') {
      history.push(prevPath!, { id: doctorId, clinicId: state.clinicId });
    } else if (patientExercising && patientExercising.patientId) {
      history.push('/patient/details', { id: patientExercising.patientId, clinicId: state.clinicId });
    } else {
      history.goBack();
    }
  };

  const archiveExercising = (patientExercising?: UpsertPatientExercisingDto) => {

    const archiveExerciseStatus = patientExercising?.status !== PatientTestingStatus.ARCHIVED;

    const onSave = () => {
      setSuccessMsg(archiveExerciseStatus
        ? t('patientTesting.archiveSuccess')
        : t('patientTesting.dearchiveSuccess'));
      setTimeout(() => {
        if (prevPath === '/') {
          history.push(prevPath);
        } else {
          history.push(prevPath!, { id: patientExercising?.patientId, clinicId: state.clinicId });
        }
      }, 1200);
    };

    if (patientExercisingId) {
      archivePatientExercising(patientExercisingId, archiveExerciseStatus, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }

  };

  const hidePreviewReportModal = () => {
    setPreviewReportPdfModalOpen(false);
    setPreviewReportPdfResponse(undefined);
  };

  const testDescWidth = () => {

    if (window.innerWidth <= 1642) {
      return 9;
    }

    return 10;
  };

  const handleChange = (e: any) => {
    setEvaluation(e.target.value);
  };

  const onSaveEvaluation = () => {
    const onSave = () => {
      setSuccessMsg(t('patientTesting.saveEvaluation'));
    };

    if (patientExercisingId) {
      saveExercisingEvaluation(patientExercisingId, evaluation, cancelTokenSource)
      .then(onSave)
      .catch((e: any) => handleError(e.response.data));
    }
  };

  const translateQuestionText = (question: QuestionDto) => {
    const defaultText = question.text;
    const qTexts: { [key: string]: string } = {
      'de': question.text || defaultText,
      'en': question.textEn || defaultText,
      'fr': question.textFr || defaultText,
      'it': question.textIt || defaultText,
    };

    return qTexts[language];
  };

  const translateExerciseResultDescription = (exResultPrev: ExerciseResultPreview) => {
    const defaultDomainDesc = exResultPrev.exerciseDescription;
    const domainDescriptions: { [key: string]: string } = {
      'de': exResultPrev.exerciseDescription || defaultDomainDesc,
      'en': exResultPrev.exerciseDescriptionEn || defaultDomainDesc,
      'fr': exResultPrev.exerciseDescriptionFr || defaultDomainDesc,
      'it': exResultPrev.exerciseDescriptionIt || defaultDomainDesc,
    };
    return `${domainDescriptions[language]}`;
  };

  const translateDescription = (exResultPrev: ExerciseDomainPreview) => {
    const defaultDomainDesc = exResultPrev.description;
    const domainDescriptions: { [key: string]: string } = {
      'de': exResultPrev.description || defaultDomainDesc,
      'en': exResultPrev.descriptionEn || defaultDomainDesc,
      'fr': exResultPrev.descriptionFr || defaultDomainDesc,
      'it': exResultPrev.descriptionIt || defaultDomainDesc,
    };
    return `${domainDescriptions[language]}`;
  };

  const translateParamsDescription = (param: TestResultParamDto) => {
    const paramDesc = param.description;
    const descriptions: { [key: string]: string } = {
      'de': param.description || paramDesc,
      'en': param.descriptionEn || paramDesc,
      'fr': param.descriptionFr || paramDesc,
      'it': param.descriptionIt || paramDesc,
    };

    return descriptions[language];
  };

  const translateQuestionSetName = (questionSet: QuestionSetDto) => {
    const questionSetName = questionSet.name;
    const names: { [key: string]: string } = {
      'de': questionSet.name || questionSetName,
      'en': questionSet.nameEn || questionSetName,
      'fr': questionSet.nameFr || questionSetName,
      'it': questionSet.nameIt || questionSetName,
    };

    return names[language];
  };

  const renderData = () => {

      let pageNumber = 1;
      let pageFooter = '';

      const totalNumQuestionSetPages = Math.ceil(questionSetChart.length / 3);
      const totalNumExercisingPages = Math.ceil(preview!.exerciseDomainPreviews.length / 3);
      const totalNumPages = totalNumQuestionSetPages + totalNumExercisingPages;

      const getQuestionData = (qsIx: number) => {
        const qsChart = questionSetChart[qsIx];
        let questionData: any[] = [];
        if (questionSetChart && qsChart.questionAnswerPairs) {
          qsChart!.questionAnswerPairs.forEach((qap) => {
            if (qap.questionAnswerPairs.length) {
              const result: any = { date: qap.date };
              const groups = _.groupBy(qap.questionAnswerPairs, 'questionId');
              Object.keys(groups).forEach((key) => {
                const filteredGroup = groups[key]
                .filter(g => g.selectedAnswers.filter(Number) !== undefined);
                if (filteredGroup.length) {
                  result[key] = {
                    scaled: filteredGroup[0].scaledValue,
                    result: filteredGroup[0].selectedAnswers.filter(Number),
                  };
                }
              });
              questionData.push(result);
            }
          });
        }

        return questionData;
      };

      const CustomQTooltip = (questionSet: QuestionSetDto, questionSetIndex: number) => ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
          const data = payload[0].payload;
          return (
            <CustomToolTipDiv>
              <p key={`${label}-${questionSetIndex}`} className='label'>{`${label}`}</p>
              {
                Object.keys(data).map((k, index) => {
                  const chapterQuestion = questionSet?.chapterQuestions.find(cq => cq.beforeQuestionIndex == index)
                  let chapterQuestionTextMap: { [lang: string]: string } = {};
                  if (chapterQuestion){
                    chapterQuestionTextMap = {
                      'de': chapterQuestion.text,
                      'en': chapterQuestion.textEn || chapterQuestion.text,
                      'fr': chapterQuestion.textFr || chapterQuestion.text,
                      'it': chapterQuestion.textIt || chapterQuestion.text,
                    };
                  }
                  if (k !== 'date') {
                    const q = questionSet ? questionSet.questions.filter(q => q.id === Number(k))[0] : undefined;
                    if (q !== undefined) {
                      const qText = translateQuestionText(q);
                      const qColor = q.color ? q.color : '#000000';
                      return (
                        <>
                        {chapterQuestion &&
                        <p key={chapterQuestion.id} style={{ fontSize: '15px', fontWeight: 'bold' }}>
                          {chapterQuestionTextMap[language]}
                        </p>
                    }
                        <p style={{ color: qColor }}>
                          {`${qText}: ${data[k].result}`}
                        </p>
                        </>
                      );
                    } else {
                      return <></>;
                    }
                  }
                })
              }
            </CustomToolTipDiv>
          );
        }
        return <></>;
      };
      return (
        <TsaGrid>
          <div ref={componentRef} className='main-pdf-container'>
            <Grid.Row>
              <Grid.Column width={16} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <InnerTsaGrid>
                  {isIphone ? (
                    <>
                      <Grid.Row style={{ paddingBottom: '10px' }}>
                        <div className="title-h1">
                          {t('patientExercising.exerciseResultTitle')}
                        </div>
                      </Grid.Row>
                      <Grid.Row>
                        <PatientInfoRenderComponent patient={patient} />
                      </Grid.Row>
                    </>
                  ) : (
                    <>
                      <Grid.Column width={10} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <div className="title-h1">
                          {t('patientExercising.exerciseResultTitle')}
                        </div>
                      </Grid.Column>
                      <Grid.Column width={6}>
                        <PatientInfoRenderComponent patient={patient} />
                      </Grid.Column>
                    </>
                  )}
                </InnerTsaGrid>
              </Grid.Column>
            </Grid.Row>

            {questionSetChart.length
              ? questionSetChart.map((qsChart, qsIx) => {

                const questionSet = qsChart.questionSet;
                const questionData = getQuestionData(qsIx);

                let numberQuestionIds: number[] = [];
                if (questionData && questionData[0]) {
                  numberQuestionIds = Object.keys(questionData[0])
                  .filter(Number)
                  .map(toInteger);
                }

                if (qsIx % 3 === 0 && qsIx !== 0) {
                  pageNumber++;
                }

                pageFooter = t('patientExercising.pageFooter', {
                  pageNumber,
                  totalNumPages,
                });

                const controlQuestionSetIndex = qsIx + 1;

                let pageFooterPosition = '-40mm';
                if (pageNumber === totalNumQuestionSetPages) {
                  if (controlQuestionSetIndex % 3 === 0) {
                    pageFooterPosition = '-40mm';
                  } else if (controlQuestionSetIndex % 3 === 1) {
                    pageFooterPosition = '-240mm';
                  } else if (controlQuestionSetIndex % 3 === 2) {
                    pageFooterPosition = '-130mm';
                  }
                }

                return <Grid.Row>
                  <Grid.Column width={16} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                    <InnerTsaGrid>
                      <ResultRaw>
                        <Grid.Column width={16}>
                          {qsIx === 0
                            ? <TestLabel style={{ display: 'flex', alignSelf: 'start' }}>
                              {t('previewResult.questions')}
                            </TestLabel>
                            : <></>
                          }
                          <SpecialLabel style={{
                            display: 'flex',
                            alignSelf: 'end',
                            paddingTop: qsIx === 0 ? '8px' : '0px',
                          }}>
                            {translateQuestionSetName(questionSet)}
                          </SpecialLabel>
                        </Grid.Column>
                      </ResultRaw>
                      {questionData.length
                        ? <Grid.Row
                          className={controlQuestionSetIndex % 3 === 0 || controlQuestionSetIndex === questionSetChart.length ? 'page-break' : ''}>
                          <Grid.Column width={16}>
                            <StyledLineChart>
                              <ResponsiveContainer width={printing ? 940 : '99%'} height={360}>
                                <LineChart data={questionData}>
                                  <XAxis dataKey={obj => moment(obj.date).format('DD.MM.YYYY- HH:mm')} />
                                  <YAxis display={'none'} />
                                  {printing
                                    ? <Tooltip content={<></>} />
                                    : <Tooltip content={CustomQTooltip(questionSet, qsIx)} />
                                  }
                                  {!isIpad && !isIphone && <Legend iconType='circle'
                                          layout='vertical'
                                          verticalAlign='middle'
                                          align='right'
                                          wrapperStyle={{ right: '-10px' }}
                                  />}
                                  <CartesianGrid stroke='#aaa' strokeDasharray='3 3' />
                                  {questionSet.questions.map((question, index) => {
                                      if (numberQuestionIds.includes(question.id))
                                        return (
                                          <Line
                                            key={question.id}
                                            type='monotone'
                                            dataKey={`${question.id}[scaled]`}
                                            name={translateQuestionText(question)}
                                            stroke={question.color ? question.color : '#000000'}
                                          />
                                        );
                                    },
                                  )}
                                </LineChart>
                              </ResponsiveContainer>
                            </StyledLineChart>
                            {controlQuestionSetIndex % 3 === 0
                            || controlQuestionSetIndex === questionSetChart.length
                              ? <div className='page-number'
                                     style={{
                                       display: 'none',
                                       position: 'absolute',
                                       bottom: `${pageFooterPosition}`,
                                       right: '10mm',
                                     }}
                              >
                                {pageFooter}
                              </div>
                              : <></>
                            }
                          </Grid.Column>
                        </Grid.Row>
                        : <></>
                      }
                    </InnerTsaGrid>
                  </Grid.Column>
                </Grid.Row>;
              })
              : <></>
            }

            <Grid.Row>
              <Grid.Column>
                <TsaGrid>
                  <Grid.Column width={16} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                    <InnerTsaGrid>
                      {
                        preview?.exerciseDomainPreviews?.map((exResultPrev, index) => {

                          if (index === 0 && questionSetChart.length) {
                            pageNumber++;
                          }

                          if (index % 3 === 0 && index !== 0) {
                            pageNumber++;
                          }

                          pageFooter = t('patientExercising.pageFooter', {
                            pageNumber,
                            totalNumPages,
                          });

                          const controlIndex = index + 1;

                          let pageFooterPosition = '-20mm';
                          if (pageNumber === totalNumPages) {
                            if (controlIndex % 3 === 0) {
                              pageFooterPosition = '-20mm';
                            } else if (controlIndex % 3 === 1) {
                              pageFooterPosition = '-250mm';
                            } else if (controlIndex % 3 === 2) {
                              pageFooterPosition = '-140mm';
                            }
                          }

                          let paramIds: number[] = [];

                          const data = exResultPrev!.resultPreviews.map((chartPreview) => {
                            const result: any = { date: chartPreview.date };
                            paramIds = [...paramIds, ..._.map(chartPreview.exerciseResultNumbers, 'paramId')];
                            const groups = _.groupBy(chartPreview.exerciseResultNumbers, 'paramId');
                            Object.keys(groups).forEach((key) => {
                              const filteredGroup = groups[key].filter(item => item.resultNumber !== null);
                              if (filteredGroup.length) {
                                result[key] = {
                                  scaled: filteredGroup[0].scaledValue,
                                  result: filteredGroup[0].resultNumber,
                                };
                              }
                            });
                            return result;
                          });

                          const CustomTooltip = ({ active, payload, label }: any) => {
                            if (active && payload && payload.length) {
                              const data = payload[0].payload;
                              return (
                                <CustomToolTipDiv>
                                  <p key={`${label}-${index}`} className='label'>{`${label}`}</p>
                                  {
                                    Object.keys(data).map(k => {
                                      if (k !== 'date') {
                                        const param = exerciseResultParams.filter(e => e.id === Number(k))[0];
                                        const description = translateParamsDescription(param);
                                        const textColor = param.paramColor ? param.paramColor : '#000000';
                                        return (
                                          <p style={{ color: textColor }}>
                                            {`${description}: ${data[k].result}`}
                                          </p>
                                        );
                                      } else {
                                        const date = data[k];
                                        const currentExerciseResultPreviews =
                                          exResultPrev.exerciseResultPreviews
                                          .filter(exrp => date === exrp.dateTime);

                                        return currentExerciseResultPreviews.map((e, ix) => {
                                          if (e.qualitativeComment) {
                                            return <p className={ix === 0 ? 'first-qualitative-comment' : ''}>
                                              {translateExerciseResultDescription(e)}: {e.qualitativeComment}
                                            </p>;
                                          }
                                        });
                                      }
                                    })
                                  }
                                </CustomToolTipDiv>
                              );
                            }
                            return <></>;
                          };

                          paramIds = _.uniq(paramIds);
                          let drawLines: any[] = [];
                          exerciseResultParams.forEach((param, index) => {
                            if (paramIds.includes(param.id)) {
                              drawLines.push(
                                <Line
                                  key={param.id}
                                  type='monotone'
                                  dataKey={`${param.id}[scaled]`}
                                  name={translateParamsDescription(param)}
                                  stroke={param.paramColor ? param.paramColor : '#000000'}
                                />
                              );
                            }
                          });

                          return (
                            <Grid.Row key={index} style={{ paddingBottom: '0px' }}>
                              <Grid.Column width={16}>
                                <InnerTsaGrid>
                                  <ResultRaw>
                                    <Grid.Column width={16}>
                                      {index === 0
                                        ? <TestLabel style={{ display: 'flex', alignSelf: 'start' }}>
                                          {t('exerciseConf.viewTitle')}
                                        </TestLabel>
                                        : <></>
                                      }
                                      <SpecialLabel style={{
                                        display: 'flex',
                                        alignSelf: 'end',
                                        paddingTop: index === 0 ? '8px' : '0px',
                                      }}>
                                        {translateDescription(exResultPrev)}
                                      </SpecialLabel>
                                    </Grid.Column>
                                  </ResultRaw>

                                  <Grid.Row className={controlIndex % 3 === 0
                                  || controlIndex === preview?.exerciseDomainPreviews.length
                                    ? 'page-break'
                                    : ''
                                  }
                                  >
                                    <Grid.Column width={16}>
                                      <StyledLineChart>
                                        <ResponsiveContainer width={printing ? 940 : '99%'} height={360}>
                                          <LineChart data={data}>
                                            <XAxis dataKey={obj => {return obj.date ? moment(obj.date).format('DD.MM.YYYY - HH:mm') : ""}} />
                                            <YAxis display={'none'} />
                                            {printing
                                              ? <Tooltip content={<></>} />
                                              : <Tooltip content={CustomTooltip} />
                                            }
                                            {!isIpad && !isIphone && <Legend iconType='circle'
                                                                             layout='vertical'
                                                                             verticalAlign='middle'
                                                                             align='right'
                                                                             wrapperStyle={{ right: '-10px' }}
                                            />}
                                            <CartesianGrid stroke='#aaa' strokeDasharray='3 3' />
                                            {drawLines}
                                          </LineChart>
                                        </ResponsiveContainer>
                                      </StyledLineChart>
                                      {controlIndex % 3 === 0
                                      || controlIndex === preview?.exerciseDomainPreviews.length
                                        ? <div className='page-number'
                                               style={{
                                                 display: 'none',
                                                 position: 'absolute',
                                                 bottom: `${pageFooterPosition}`,
                                                 right: '10mm',
                                               }}
                                        >
                                          {pageFooter}
                                        </div>
                                        : <></>
                                      }
                                    </Grid.Column>
                                  </Grid.Row>
                                </InnerTsaGrid>
                              </Grid.Column>
                            </Grid.Row>
                          );
                        })
                      }
                    </InnerTsaGrid>
                  </Grid.Column>
                </TsaGrid>
              </Grid.Column>
            </Grid.Row>
            <div className='eval-container'
                 style={{ display: 'none', pageBreakBefore: 'always' }}
            >
              <TestLabel>{t('previewResult.evaluation')}</TestLabel>
              {evaluation}
            </div>
          </div>

          <Grid.Row>
            <Grid.Column>
              <TsaGrid>
                <TestLabel>{t('previewResult.evaluation')}</TestLabel>
                <Grid.Row>
                  <Grid.Column width={!isIphone ? 5 : 16}>
                  <EvaluationSection width={testDescWidth()}>
                    <div style={{width: "98%"}} className='ui form'>
                      <TextArea
                        rows={5}
                        placeholder='Write evaluation...'
                        value={evaluation}
                        onChange={handleChange}
                        fluid
                      />
                    </div>
                  </EvaluationSection>
                  </Grid.Column>

                  <Grid.Column width={!isIphone ? 5 : 16}>
                    <CompositeButton
                      type='button'
                      primary
                      className='action-button'
                      size='small'
                      style={{ marginLeft: '10px' }}
                      onClick={onSaveEvaluation}
                    >
                      {t('button.saveEvaluation')}
                    </CompositeButton>
                  </Grid.Column>
                </Grid.Row>
              </TsaGrid>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}
                         style={{ borderTop: '1px solid var(--very-light-blue)', marginTop: '2rem' }}
            >
            </Grid.Column>
          </Grid.Row>

          <Grid.Row className='button-row-container'>
            <Grid.Column className='button-column-container' width={16}>
              {
                successMsg &&
                <SaveAndUpdateConfirmationMessage>
                  {successMsg}
                </SaveAndUpdateConfirmationMessage>
              }
              <CompositeButton
                type='button'
                primary
                className='action-button'
                onClick={() => history.push('/exercising', {
                  patientExercisingId: patientExercisingId,
                  patientId: patientExercising?.patientId,
                  prevPath: '/preview-exercising',
                  clinicId: state.clinicId
                })}
              >
                {t('button.edit')}
              </CompositeButton>
              <ArchiveTestingConfirmation disabled={patientExercising?.status == PatientTestingStatus.DONE}
                                          title={patientExercising?.status === PatientTestingStatus.ARCHIVED
                                            ? t('button.dearchive')
                                            : t('button.archive')}
                                          updateConfirmationText={patientExercising?.status === PatientTestingStatus.ARCHIVED
                                            ? t('patientTesting.dearchiveConfirmation')
                                            : t('patientTesting.archiveConfirmation')}
                                          submit={() => archiveExercising(patientExercising)} />

              <CompositeButton
                primary
                type='button'
                className='action-button'
                onClick={() => history.push('/exercise-testing', {
                  patientExercisingId: patientExercisingId,
                  patientId: patientExercising?.patientId,
                  exerciseTemplateId: patientExercising?.exerciseTemplateId,
                  testProgress: testProgress,
                  prevPath: prevPath,
                  clinicId: state.clinicId
                })}
                disabled={patientExercising?.status == PatientTestingStatus.ARCHIVED
                  || patientExercising?.status == PatientTestingStatus.DONE}
              >
                {t('button.editExerciseSet')}
              </CompositeButton>

              <CompositeButton
                type='button'
                primary
                className='action-button'
                onClick={handlePrint}
              >
                {t('button.createReport')}
              </CompositeButton>

              <CompositeButton
                type='button'
                className='action-button'
                secondary
                onClick={goToPreviousPage}
                style={{ float: 'right' }}
              >
                {t('action.back')}
              </CompositeButton>
            </Grid.Column>
          </Grid.Row>
          {
            previewReportPdfModalOpen && previewReportPdfResponse
              ? <ReportPdfModal
                pdfResponses={[previewReportPdfResponse]}
                modalOpen={previewReportPdfModalOpen}
                getPdfUrl={getPatientExercisingPreviewPdfUrl}
                onCloseModal={hidePreviewReportModal}
              />
              : null
          }
        </TsaGrid>
      );
    }
  ;

  return <div>{dataLoaded
    ? renderData()
    : <LoaderComponent message={t('previewResult.loading')} />}
  </div>
};

export default PreviewExerciseResultPage;