import { CancelTokenSource } from 'axios';
import {
  DoctorImportDto,
  DoctorImportRequest,
  UpsertDoctorImportDto,
} from 'ts-types/api.types';
import axios, { backendUrls } from './http';

const apiUri = `${backendUrls.apiRoot}/doctor-import`;

export const uploadDoctorsFile = (values: Partial<UpsertDoctorImportDto>, clinicId: number | undefined,
                                  source: CancelTokenSource): Promise<Array<DoctorImportDto>> => {
  let bodyFormData = new FormData();
  Object.keys(values).forEach((key) => {
    let value = values[key as keyof UpsertDoctorImportDto];

    if (value) {
      if (typeof value === "number") {
        //@ts-ignore
        value = value.toString();
      }
      //@ts-ignore
      bodyFormData.append(key, value);
    }

  });

  return axios
  .post(`${apiUri}`, bodyFormData, {params: { clinicId }, cancelToken: source.token})
  .then((response) => response.data);
};

export const importDoctors = (
  request: Partial<DoctorImportRequest>, clinicId: number | undefined, source: CancelTokenSource)
  : Promise<string> => {

  return axios
  .post(`${apiUri}/import`, request, {params: { clinicId }, cancelToken: source.token})
  .then(response => response.data);
};

export const exportDoctorFileExample = (
  source: CancelTokenSource): Promise<any> => {

  return axios
  .post(`${apiUri}/export-example`, {},{ cancelToken: source.token })
  .then(response => {
    const type = response.headers['content-type'];
    // @ts-ignore
    const BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
    const blob = new Blob([BOM, response.data], { type: type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = response.headers['content-disposition'];
    const filenameRegex = /filename=(.*)/;
    const regexResponse = filenameRegex.exec(link.download);
    const defaultFileName = 'response.csv';
    const fileName = regexResponse ?
      regexResponse[1] ? regexResponse[1] : defaultFileName :
      defaultFileName;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    return true;
  });

};
