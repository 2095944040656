import React from 'react';
import { Table } from 'semantic-ui-react';

export const emptyTableRows = (numOfRows: number, numOfCells: number) => {

  let arrayLength = 3;

  if ( numOfRows >= 3) {
    arrayLength = 0;
  } else if ( numOfRows < 3 && numOfRows >= 0) {
    arrayLength = arrayLength - numOfRows;
  }

  let arrayRows = Array.from({length: arrayLength}, (_, index) => (index + 1) -1);
  let arrayCells = Array.from({length: numOfCells}, (_, index) => (index + 1) -1);


  return arrayRows.map( raw => {
    return (
      <Table.Row key={raw.valueOf()}>
        {
          arrayCells.map( cell => {
            return (
              <Table.Cell key={cell.valueOf()}>
                &nbsp;
              </Table.Cell>
            )
          })
        }
      </Table.Row>
    )
  })
};

export const multiLanguageInnerTableCellRenderer = (fieldName: string, language: string) => (data: any) => {

  let result = '';

  if (fieldName) {
    result = data[`${fieldName}`];

    if (language) {
      const texts: { [key: string]: string } = {
        'de': result,
        'en': data[`${fieldName}En`],
        'fr': data[`${fieldName}Fr`],
        'it': data[`${fieldName}It`],
      };
      result = texts[language] || result;
    }
  }
  return <span title={result}>{result}</span>;
};

export const multiLanguageFieldCellRenderer = (fieldName: string, language: string) => (data: any) => {

  const {rowData} = data;

  if (!fieldName) {
    return emptyTableCell();
  }

  const defaultValue = rowData[`${fieldName}`];

  if (!language) {
    return defaultValue;
  }

  const texts: { [key: string]: string } = {
    "de": defaultValue,
    "en": rowData[`${fieldName}En`],
    "fr": rowData[`${fieldName}Fr`],
    "it": rowData[`${fieldName}It`]
  };

  if (!(texts[language] || defaultValue)) {
    return emptyTableCell();
  }

  return texts[language] || defaultValue;
};

export const emptyTableCell = () => {
  return <div>&nbsp;</div>;
};

export const shortLabel = (longDescription: string, shortDescription: string, textCenter?: boolean) => {

  const style = {
    paddingLeft: textCenter ? '15px' : '',
    cursor: 'pointer'
  };

  return <span style={style} title={longDescription}>
    {shortDescription}
  </span>;
};

export const emptyTableCellRenderer = () => (data: any) => {
  return emptyTableCell();
}

export const formatRawScoreNumbers = (rawScore: number | string) => {

  if (typeof rawScore === 'string') {
    return parseInt(rawScore).toFixed(2);
  }

  return rawScore.toFixed(2);
};

export const defaultContentCellRenderer = ({ cellData }: any) => {
  if (cellData) {
    return cellData;
  }
  return emptyTableCell();
};