import { CancelTokenSource } from 'axios';
import axios, { backendUrls } from 'service/http';
import {
  ExercisingRequest,
  PatientExercisingDto, UpsertExercisingDto,
  UpsertPatientExercisingDto,
} from 'ts-types/api.types';

const apiUri = `${backendUrls.apiRoot}/patient-exercising`;

export const getPatientExercising = (
  patientExercisingId: number,
  calculateOnlyCompletedSessions: boolean,
  source: CancelTokenSource): Promise<UpsertPatientExercisingDto> => {

  return axios
  .get(`${apiUri}/${patientExercisingId}`,  {params: {calculateOnlyCompletedSessions}, cancelToken: source.token })
  .then(response => response.data);
};

export const addPatientExercising = (
  upsertExerciseTemplateDto: Partial<UpsertPatientExercisingDto>,
  source: CancelTokenSource): Promise<PatientExercisingDto> => {

  return axios
  .post(apiUri, upsertExerciseTemplateDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const editPatientExercising = (
  exerciseTemplateId: number,
  upsertExerciseTemplateDto: Partial<UpsertPatientExercisingDto>,
  source: CancelTokenSource): Promise<PatientExercisingDto> => {

  return axios
  .put(`${apiUri}/${exerciseTemplateId}`, upsertExerciseTemplateDto, {cancelToken: source.token})
  .then(response => response.data);
};

export const getExercising = (request: Partial<ExercisingRequest>, source: CancelTokenSource):
  Promise<UpsertExercisingDto> => {
  return axios
  .post(`${apiUri}/exercising`, request, {cancelToken: source.token })
  .then(response => response.data);
};

export const saveExercising = (
  patientExercisingId: number,
  upsertExercisingDto: Partial<UpsertExercisingDto>,
  source: CancelTokenSource): Promise<String> => {

  return axios
  .put(`${apiUri}/exercising/${patientExercisingId}`, upsertExercisingDto, { cancelToken: source.token })
  .then(response => response.data);
};

export const saveExercisingEvaluation = (patientExercisingId: number, evaluation: string, source:CancelTokenSource):
  Promise<PatientExercisingDto> => {
  return  axios
  .put(`${apiUri}/${patientExercisingId}/save-exercise-evaluation`, evaluation, {cancelToken: source.token, params: { evaluation }})
  .then(response => response.data);
}

export const archivePatientExercising = (
  patientExercisingId: number,
  archive: boolean,
  source:CancelTokenSource): Promise<PatientExercisingDto> => {

  return  axios
    .put(`${apiUri}/${patientExercisingId}/archive`, {}, {params: {archive}, cancelToken: source.token})
    .then(response => response.data);
}

export const deletePatientExercisingArchive = (patientExercisingId: number, source:CancelTokenSource):
  Promise<PatientExercisingDto> => {
  return axios
    .delete(`${apiUri}/${patientExercisingId}`, {cancelToken: source.token})
    .then(response => response.data)
}