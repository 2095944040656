import React, { Component } from "react"
import { DividerProps } from 'semantic-ui-react';
import styled from 'styled-components';

const ContentWrapperDiv = styled.div`
    flex: 1 1 auto;

    display: flex;
    flex-direction: column;

    .page-header {
        margin-bottom: 2rem;
    }

    .error {
        margin-bottom: 1rem;
    }

    .page-actions {
        margin-bottom: 2rem;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: center;
        margin-top: 1rem;

        .ui.button {
            flex: 0 0 auto;
        }

        .spacer {
            display: none;
        }

        @media (min-width: 431px) {
            .spacer {
                display: block;
                flex-grow: 1;
            }
        }
`;

class ViewContentWrapperDiv extends Component<DividerProps> {

  render(): React.ReactNode {
    const {children, ...rest} = this.props;
    return (
        <ContentWrapperDiv {...rest}>
          {children}
        </ContentWrapperDiv>
    )
  }
}

export default ViewContentWrapperDiv;
