import PatientInfoRenderComponent from 'components/final-form/PatientInfoRenderComponent';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React, { CSSProperties, ReactNode } from 'react';
import { Grid, LoaderProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { PatientDto, UpsertPatientExercisingDto, UpsertPatientTestingDto } from 'ts-types/api.types';


interface TitleColumnProps {
  isIphone: boolean;
}

const StyledGrid = styled(Grid)<TitleColumnProps>`
    & #title-column {
        @media only screen and (max-width: 767px) {
            padding-top: ${({ isIphone }) => (isIphone ? '0.5rem' : '1rem')} !important;
            padding-bottom: ${({ isIphone }) => (isIphone ? '0.5rem' : '1rem')} !important;
        }
    }
`;

interface Props extends LoaderProps {
  titleElement: ReactNode,
  patient?: PatientDto,
  patientTesting?: Partial<UpsertPatientTestingDto>
  patientExercising?: UpsertPatientExercisingDto,
  style?: CSSProperties
}

const PatientInfoHeaderComponent = (props: Props) => {

  const isIphone = useIsIpadWidthOrBelow(430);

  const {
    patient,
    patientTesting,
    patientExercising,
    titleElement,
    style
  } = props;

  return (
    <Grid.Row style={style}>
      <Grid.Column>
        <StyledGrid stackable doubling columns={2} isIphone={isIphone}>
          <Grid.Column id='title-column' width={patientTesting ? 6 : 10}>
            {titleElement}
          </Grid.Column>
          <Grid.Column width={patientTesting ? 10 : 6}>
            <PatientInfoRenderComponent
              patient={patient}
              patientTesting={patientTesting}
              patientExercising={patientExercising}
            />
          </Grid.Column>
        </StyledGrid>
      </Grid.Column>
    </Grid.Row>
  );
}

export default PatientInfoHeaderComponent;