import { CancelTokenSource } from 'axios';
import { PatientDto, PatientSearchRequest, UpsertPatientDto } from 'ts-types/api.types';
import axios, { backendUrls } from 'service/http';

const apiUri = `${backendUrls.apiRoot}/patient`;

export const getPatient = (patientId: number, source: CancelTokenSource): Promise<UpsertPatientDto> => {
  return axios
  .get(`${apiUri}/${patientId}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getPatientDetails = (
  patientId: number,
  source: CancelTokenSource): Promise<PatientDto> => {
  return axios
  .get(`${apiUri}/${patientId}/details`, { cancelToken: source.token })
  .then(response => response.data);
};

export const searchPatients = (
  request: Partial<PatientSearchRequest>,
  source: CancelTokenSource): Promise<PatientDto[]> => {
  return axios
  .post(`${apiUri}/search`, request, { cancelToken: source.token })
  .then(response => response.data);
};

export const createPatient = (patient: Partial<UpsertPatientDto>, source: CancelTokenSource): Promise<PatientDto> => {
  return axios
  .post(apiUri, patient, { cancelToken: source.token })
  .then((response) => response.data);
};

export const updatePatient = (patientId: number, patient: Partial<UpsertPatientDto>, source: CancelTokenSource): Promise<PatientDto> => {
  return axios
  .put(`${apiUri}/${patientId}`, patient, { cancelToken: source.token })
  .then((response) => response.data);
};

export const deletePatientById = (id: number, source: CancelTokenSource): Promise<string> => {
  return axios
  .delete(`${apiUri}/${id}`, { cancelToken: source.token })
  .then(response => response.data);
};

export const getClinicExercisesNumber = (patientId: number,
                                         source: CancelTokenSource): Promise<number> => {
  return axios
  .get(`${apiUri}/${patientId}/exercises-number`, { cancelToken: source.token })
  .then(response => response.data);
};
