import { mapToDropdownOptionArray } from 'components/final-form/Select';
import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { searchClinics } from 'service/adminService';
import axios from 'service/http';
import styled from 'styled-components';
import { ClinicSearchRequest } from 'ts-types/api.types';

const StyledTabs = styled.div`
    display: flex;

  .tab {
    cursor: pointer;
    padding: 5px 20px 10px;
    color: var(--dark-gray);
    margin-bottom: 30px;
    font-weight: 600;
    border-bottom: 2px solid transparent;
    transition: 200ms all ease-in-out;
  }

  .tab.selected {
    border-bottom-color: var(--primary-color);
  }
`;

interface Props extends FieldRenderProps<any> {
  onClinicSelect: (clinicId: number) => void;
}

const cancelTokenSource = axios.CancelToken.source();

const AdminTabComponent = (props: Props) => {

  const { t } = useTranslation('teresa');
  const { input, onClinicSelect } = props;

  const [activeIx, setActiveIx] = useState<number>(input.value);
  const [clinicOptions, setClinicOptions] =
    useState<DropdownItemProps[]>([]);

  const handleOnTabClick = (index: number, tabName: string) => {

    if (tabName === 'clinic') {
      let searchValues: Partial<ClinicSearchRequest> = {
        searchKey: ''
      };

      searchClinics(searchValues, cancelTokenSource)
      .then(response => {
          const clinics = response.map((clinic) => ({
            key: clinic.id,
            value:  clinic.id,
            text: `${clinic.name} ${clinic.staffNumber}`,
            content:  (
              <div style={{display: 'inline'}}>
                <span style={{paddingRight: '10px'}}>
                  {clinic.name}
                </span>
                <div style={{float: 'right'}}>
                  {clinic.staffNumber}
                </div>
              </div>
            )
          }));

          setClinicOptions(clinics);
        },
      );
    }

    setActiveIx(index);

    input.onChange(index);
  }

  const tabLabels: string[] = ['showAll', 'active', 'inactive', 'doctor', 'clinic'];
  const tabs = tabLabels.map((tabName: string, index: number) => {
    return <div key={tabName}
                className={`tab ${activeIx === index && 'selected'}`}
    >
      {tabName === 'clinic'
        ? <Dropdown
          text={t('tab.clinic')}
          icon='chevron down'
          options={clinicOptions}
          onClick={() => {
            if (activeIx !== index) {
              handleOnTabClick(index, tabName)
            }
          }}
          onChange={(evt, data) => {
            onClinicSelect(data.value as number);
          }}
          selectOnBlur
          selectOnNavigation={false}
          fluid
        />
        : <div onClick={() => handleOnTabClick(index, tabName)}>
          {t(`tab.${tabName}`)}
        </div>
      }
    </div>
  });

  return (
    <StyledTabs>
      {tabs}
    </StyledTabs>
  );
};

export default AdminTabComponent;
