import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import DatePicker from 'components/final-form/DatePicker';
import Input from 'components/final-form/Input';
import PatientInfoHeaderComponent from 'components/final-form/PatientInfoHeaderComponent';
import SaveAndUpdateConfirmationMessage from 'components/final-form/SaveAndUpdateConfirmationMessage';
import LoaderComponent from 'components/LoaderComponent';
import StyledErrorMessage from 'components/StyledErrorMessage';
import TsaGrid from 'components/TsaGrid';
import UpdateTestingConfirmation from 'components/UpdateTestingConfirmation';
import UpsertContentWrapperDiv from 'components/UpsertContentWrapperDiv';
import { useClinicHeaderContext } from 'context/ClinicHeaderContext';
import createDecorator from 'final-form-focus';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, Form as FinalForm, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import PatientTestingQuestionSetSegment from 'routes/patienttesting/PatientTestingQuestionSetSegment';
import TemplateTestConfSegment from 'routes/test-template/TemplateTestConfSegment';
import { Grid } from 'semantic-ui-react';
import {
  getTestConfsForPatientTesting,
  getTestInTemplates,
  getTestTemplate,
  searchQuestionSet,
} from 'service/teresaConfService';
import { getPatientDetails } from 'service/patientService';
import { addPatientTesting, editPatientTesting, getPatientTesting } from 'service/patientTestingService';
import styled from 'styled-components';
import { ScopeType } from 'ts-types/api.enums';
import {
  TeresaConfQuestionSetSearchRequest,
  DomainDto,
  PatientDto,
  QuestionSetDto,
  TestConfDto,
  TestTemplateDto,
  UpsertPatientTestingDto,
  UpsertTestTemplateDto,
} from 'ts-types/api.types';
import { errorUtils } from 'util/errorUtils';
import { composeValidators, mustBeNumber, mustBePositiveNumber, required } from 'util/validatorUtils';

const StyledLeftDataDiv = styled.div`
  font-weight: 600;
  min-height: 35px;
  line-height: 35px;
  background-color: var(--very-light-gray);
  border-radius: 4px;
`;

const StyledRightDataDiv = styled.div`
  font-weight: 600;
  min-height: 35px;
  line-height: 35px;
  background-color: var(--very-light-gray);
  border-radius: 4px;
`;

const ScrollableTsaGrid = styled(TsaGrid)`
  position: fixed;
  overflow: auto;
  top: 110px;
  bottom: 75px;
`;

const FormButtonsWrapper = styled(TsaGrid)`
  position: fixed;
  bottom: 0;
  width: calc(100% - 255px);
  height: 75px;

  & .buttons-container {
    padding-top: 20px;
    border-top: 1px solid var(--very-light-blue);
  }
`;

interface UpsertTestTemplateForm extends UpsertTestTemplateDto, DomainDto, UpsertPatientTestingDto {
}

const cancelTokenSource = axios.CancelToken.source();

const UpsertPatientTestingForm = () => {

  const {setClinicId} = useClinicHeaderContext();

  const { t } = useTranslation('teresa');
  const { language } = useAuthContext();
  const { state } = useLocation();
  const focusOnErrors = createDecorator();
  const isIpad = useIsIpadWidthOrBelow(1280);
  const isIphone = useIsIpadWidthOrBelow(430);

  const { currentUser } = useAuthContext();
  const history = useHistory();
  const patientTestingId: number | undefined = state?.patientTestingId ? Number(state?.patientTestingId) : undefined;
  const patientId: number | undefined = state?.patientId ? Number(state?.patientId) : undefined;
  const testTemplateId: number | undefined = state.testTemplateId ? Number(state?.testTemplateId) : undefined;
  const prevPath: string | undefined = state.prevPath ? state?.prevPath : undefined;

  const [testTemplate, setTestTemplate] = useState<UpsertTestTemplateDto | undefined>(undefined);
  const [markedTestConf, setMarkedTestConf] = useState<TestConfDto | undefined>(undefined);
  const [testInTemplatesList, setTestInTemplatesList] = useState<string[]>([]);
  const [selectedTestConfList, setSelectedTestConfList] = useState<TestConfDto[]>([]);
  const [availableTestConfList, setAvailableTestConfList] = useState<TestConfDto[]>([]);
  const [selectedQuestionSets, setSelectedQuestionSets] = useState<number[]>([]);
  const [availableQuestionSets, setAvailableQuestionSets] = useState<number[]>([]);
  const [patientTesting, setPatientTesting] = useState<UpsertPatientTestingDto>();
  const [patient, setPatient] = useState<PatientDto>();
  const [questionSets, setQuestionSets] = useState<QuestionSetDto[]>([]);
  const [confirmationPopupMsg, setConfirmationPopupMsg] = useState<string | undefined>(undefined);
  const [numOfCompletedTests, setNumOfCompletedTests] = useState<number>(0);
  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [formDataLoaded, setFormDataLoaded] = useState<boolean>(true);
  const [testCompleted, setTestCompleted] = useState<boolean>(false);
  const [firstIncompleteQuestionSetId, setFirstIncompleteQuestionSetId] = useState<number>();

  const goToPreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    setClinicId(state?.clinicId ? Number(state.clinicId) : undefined)
    fetchData(true);
  }, []);

  useEffect(() => {
    if (markedTestConf) {
      getTestInTemplates(markedTestConf.id, cancelTokenSource)
      .then(list => {
        const testInTemplatesStringList: string[] = list
        .map(
          (template, index) =>
            index === 0 ? translateName(template) : ', ' + translateName(template),
        );
        setTestInTemplatesList(testInTemplatesStringList);
      });
    }
  }, [language]);

  useEffect(() => {
    let firstIncompleteQuestionSetId: number | undefined = undefined;
    if (patientTesting && patientTesting.questionSetDtos) {
      selectedQuestionSets.forEach(questionSetId => {
        let patientQuestionSetDto =
          patientTesting.questionSetDtos.find(pt => pt.questionSetId === questionSetId);
        if (!firstIncompleteQuestionSetId && (!patientQuestionSetDto || !patientQuestionSetDto.completed)) {
          firstIncompleteQuestionSetId = questionSetId;
        }
      });
    }

    setFirstIncompleteQuestionSetId(firstIncompleteQuestionSetId);

  }, [selectedQuestionSets, patientTesting]);

  useEffect(() => {
    if (selectedTestConfList.length && numOfCompletedTests === selectedTestConfList.length) {
      setTestCompleted(true);
    } else {
      setTestCompleted(false);
    }
  }, [selectedTestConfList]);

  const fetchData = async (active: boolean) => {
    setFormDataLoaded(false);

    try {

      const patientData = await getPatientDetails(patientId!, cancelTokenSource);

      setPatient(patientData);

      const testConfsResponse =
        await getTestConfsForPatientTesting(patientData.clinicId, undefined, cancelTokenSource);

      const testConfIds = testConfsResponse.map(t => t.id);

      let questionSetSearchValues: Partial<TeresaConfQuestionSetSearchRequest> = {
        clinicId: patientData.clinicId,
        searchKey: '',
        scopeType: ScopeType.TEST,
        orderByName: true,
      };

      const questionSetsResponse = await searchQuestionSet(questionSetSearchValues, cancelTokenSource);
      setQuestionSets(questionSetsResponse);
      const filteredQuestionSets = questionSetsResponse.filter(questionSet => !questionSet.deleted)
      const availableQuestionsSetIds = _.map(filteredQuestionSets, 'id');
      setAvailableQuestionSets(availableQuestionsSetIds);

      if (patientTestingId) {
        const patientTestingResponse = await getPatientTesting(patientTestingId, cancelTokenSource);
        setPatientTesting(patientTestingResponse);

        const questionSetIds: number[] = patientTestingResponse.questionSetIds;
        setSelectedQuestionSets(questionSetIds);
        setAvailableQuestionSets(availableQuestionsSetIds.filter(qsId => !questionSetIds.includes(qsId)));

        const numOfCompletedTests = patientTestingResponse.patientTests.filter(pt => pt.completed).length;
        setNumOfCompletedTests(numOfCompletedTests);

        const testConfigIds = patientTestingResponse.testConfDtoList.map(t => t.id);
        let newList = patientTestingResponse.testConfDtoList;
        newList = _.orderBy(newList, ['orderIndex'], ['asc']);

        setSelectedTestConfList(newList);
        setAvailableTestConfList(testConfsResponse.filter(tc => !testConfigIds.includes(tc.id)));
      } else if (testTemplateId) {
        const testTemplate = await getTestTemplate(testTemplateId, cancelTokenSource);
        setTestTemplate(testTemplate);
        if (testTemplate) {
          const testConfigIds = testTemplate.testConfList.map(t => t.id);
          let newList = testTemplate.testConfList.filter(t => testConfIds.includes(t.id));
          newList = _.orderBy(newList, ['orderIndex'], ['asc']);

          setSelectedTestConfList(newList);
          setAvailableTestConfList(testConfsResponse.filter(tc => !testConfigIds.includes(tc.id)));
        }
      } else {
        setSelectedTestConfList([]);
        setAvailableTestConfList(testConfsResponse);
      }
    } catch (e) {
      handleError(e.response.data);
    } finally {
      setFormDataLoaded(true);
    }
  };

  const handleError = useCallback((error: any) => {

    if (error) {
      let errMsg = undefined;
      const errorCode = error.errorCode;
      const knownErrors: Array<string> = [
        errorUtils.invalidEmail,
        errorUtils.testTemplateNotFound,
        errorUtils.patientTestConfListEmpty,
        errorUtils.invalidDate,
      ];

      const violations: Array<any> = error.violations;

      if (violations && violations.length > 0) {
        violations.forEach(violation => {
          if (knownErrors.includes(violation.errorCode)) {
            errMsg = t(`error.${violation.errorCode}`);
          }
        });
      }

      if (!errMsg) {
        if (knownErrors.includes(errorCode)) {
          errMsg = t(`error.${errorCode}`);
        } else {
          errMsg = t('error.general');
        }
      }

      setErrorMessage(errMsg);
    }

  }, []);

  const submitHandler = async (values: Partial<UpsertTestTemplateForm>) => {

    const request: Partial<UpsertPatientTestingDto> = {
      testTemplateId: testTemplateId,
      doctorId: currentUser?.id,
      patientId: patientId,
      creationDate: values.creationDate,
      status: patientTesting?.status,
      questionSetIds: selectedQuestionSets,
      testConfDtoList: selectedTestConfList,
      patientTests: patientTesting?.patientTests,
      patientAge: values.patientAge,
      patientSchoolYears: patient?.schoolYears,
    };

    const onSave = (testingId?: number, patientTestingQuestionSetId?: number) => {
      patientTestingId
        ? setSuccessMsg(t('patientTesting.updateSuccess'))
        : setSuccessMsg(t('patientTesting.addSuccess'));
      setTimeout(() => {

        if ((!testCompleted || firstIncompleteQuestionSetId) && selectedQuestionSets.length) {
          history.push('/answer-questions', {
            patientTestingId: testingId,
            patientTestingQuestionSetId: patientTestingQuestionSetId,
            patientId,
            prevPath: prevPath,
            clinicId: state.clinicId
          });
        } else if (testingId) {
          history.push('/start-testing', {
            patientTestingId: testingId,
            patientTestingQuestionSetId: patientTestingQuestionSetId,
            prevPath: prevPath,
            clinicId: state.clinicId
          });
        } else {
          prevPath === '/' ? history.push(prevPath) : history.push(prevPath!, { id: patientId, clinicId: state.clinicId });
        }
      }, 600);
    };

    try {
      if (patientTestingId) {
        const patientTestingDto = await editPatientTesting(patientTestingId, request, cancelTokenSource);
        onSave(patientTestingId, patientTestingDto.patientTestingQuestionSetId);
      } else {
        const addTestingResponse = await addPatientTesting(request, cancelTokenSource);
        onSave(addTestingResponse.id, addTestingResponse.patientTestingQuestionSetId);
      }
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const setData = (selectedTestConfs: Array<TestConfDto>, availableTestConfs: Array<TestConfDto>) => {
    setSelectedTestConfList(selectedTestConfs);
    setAvailableTestConfList(availableTestConfs);
  };

  const setQuestionSetData = (selected: number[], available: number[]) => {
    setSelectedQuestionSets(selected);
    setAvailableQuestionSets(available);
  };

  const translateName = (test: TestTemplateDto) => {
    const description = test.description;
    const questionSetNames: { [key: string]: string } = {
      'de': test.description || description,
      'en': test.descriptionEn || description,
      'fr': test.descriptionFr || description,
      'it': test.descriptionIt || description,
    };
    return questionSetNames[language];
  };

  const setSelectedTestInTemplates = async (test: TestConfDto) => {
    setMarkedTestConf(test);
    try {
      const list = await getTestInTemplates(test.id, cancelTokenSource);
      let testInTemplatesStringList: string[] = [];
      list.forEach(template => {
        testInTemplatesStringList.push(list.indexOf(template) === 0 ? translateName(template) : ', ' + translateName(template));
      });
      setTestInTemplatesList(testInTemplatesStringList);
    } catch (e) {
      handleError(e.response.data);
    }
  };

  const renderFinalForm = (): React.ReactNode => {
    return (
      <FinalForm
        onSubmit={(values: Partial<UpsertTestTemplateForm>) => submitHandler(values)}
        // @ts-ignore
        decorators={[focusOnErrors]}
        initialValues={{
          questionSetIds: patientTesting?.questionSetIds,
          testConfList: testTemplate?.testConfList,
          patientAge: patientTesting?.patientAge,
          creationDate: patientTesting?.creationDate ? patientTesting.creationDate : moment().format('YYYY-MM-DD')
        }}
        subscription={{ pristine: true, submitting: true, hasValidationErrors: true, errors: true }}
        render={(formProps: FormRenderProps<UpsertTestTemplateForm>) => renderTestTemplateForm(formProps)}
      />
    );
  };

  const renderTestTemplateForm = ({
                                    handleSubmit,
                                    submitting,
                                    form,
                                  }: FormRenderProps<UpsertTestTemplateForm>): React.ReactNode => {

    const selectedTestInTemplatesLeft = markedTestConf && selectedTestConfList.includes(markedTestConf)
      ? testInTemplatesList
      : [];
    const selectedTestInTemplatesRight = markedTestConf && availableTestConfList.includes(markedTestConf)
      ? testInTemplatesList
      : [];

    return (
      <form onSubmit={handleSubmit}>
        <TsaGrid>
          <PatientInfoHeaderComponent
            titleElement={
            <div className='title-h1' style={{marginBottom: 0}}>
              {t('testSet.title')}
            </div>
          }
            patient={patient}
            patientTesting={patientTesting}
          />
        </TsaGrid>
        <ScrollableTsaGrid style={isIphone
          ? {top: '170px', width: 'calc(100% - 10px)'}
          : isIpad
            ? { top: '170px', width: 'calc(100% - 40px)' }
            : {}
        }>
          {errorMessage &&
            <Grid.Row>
              <Grid.Column width={16}>
                <div className='error'>
                  <StyledErrorMessage onDismiss={() => setErrorMessage(undefined)}>
                    <div>{errorMessage}</div>
                  </StyledErrorMessage>
                </div>
              </Grid.Column>
            </Grid.Row>
          }
          <Grid.Row>
            <Grid.Column width={7}>
              <DataLabel>{t('patientTesting.patientAge')}</DataLabel>
              <Field
                name='patientAge'
                component={Input}
                validate={composeValidators(required, mustBeNumber, mustBePositiveNumber)}
              />
            </Grid.Column>
            <Grid.Column width={isIphone ? 2 : 1}/>
            <Grid.Column width={7}>
              <DataLabel>{t('patientTesting.testDateTime')}</DataLabel>
              <Field
                name='creationDate'
                component={DatePicker}
                validate={required}
                fluid
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={7} verticalAlign={'middle'}>
              <DataLabel>{t('questionSet.title')}</DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <PatientTestingQuestionSetSegment
              questionSetMap={_.keyBy(questionSets, 'id')}
              selectedQuestionSets={selectedQuestionSets}
              availableQuestionSets={availableQuestionSets}
              setData={setQuestionSetData}
              patientQuestionSets={patientTesting?.questionSetDtos}
            />
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={7} verticalAlign='middle'>
              <DataLabel>{t('testTemplate.testSelection')}</DataLabel>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <TemplateTestConfSegment
              selectedTestConfList={selectedTestConfList && selectedTestConfList.length > 0 ? selectedTestConfList : []}
              availableTestConfList={availableTestConfList}
              setData={setData}
              setSelectedTestInTemplates={setSelectedTestInTemplates}
              patientTests={patientTesting?.patientTests}
            />
          </Grid.Row>

          {!isIphone &&
            <>
              <Grid.Row>
                <Grid.Column width={7} verticalAlign={'middle'}>
                  <DataLabel>{t('testTemplate.selectedTestsInTemplate')}</DataLabel>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={7}>
                  <StyledLeftDataDiv>{selectedTestInTemplatesLeft}</StyledLeftDataDiv>
                </Grid.Column>
                <Grid.Column width={1} />
                <Grid.Column width={7}>
                  <StyledRightDataDiv>{selectedTestInTemplatesRight}</StyledRightDataDiv>
                </Grid.Column>
              </Grid.Row>
            </>
          }
        </ScrollableTsaGrid>
        <FormButtonsWrapper style={(isIpad || isIphone) ? { width: '100%' } : {}}>
          <Grid.Row textAlign='right'>
            <Grid.Column width={15}>
              <div className='buttons-container'>
                <CompositeButton
                  secondary
                  type='button'
                  floated='right'
                  className='action-button'
                  style={{ display: 'inline-block' }}
                  disabled={submitting || !!successMsg}
                  onClick={goToPreviousPage}
                >
                  {t('action.cancel')}
                </CompositeButton>

                {confirmationPopupMsg
                  ? <UpdateTestingConfirmation disabled={submitting || !!successMsg}
                                               updateConfirmationText={confirmationPopupMsg}
                                               submit={() => submitHandler(form.getState().values)} />
                  : <CompositeButton
                    primary
                    type='submit'
                    floated='right'
                    className='action-button'
                    style={{ display: 'inline-block', marginRight: '2rem' }}
                    disabled={submitting || !!successMsg}
                  >
                    {t('button.save')}
                  </CompositeButton>
                }

                {
                  successMsg &&
                  <SaveAndUpdateConfirmationMessage>
                    {successMsg}
                  </SaveAndUpdateConfirmationMessage>
                }
              </div>
            </Grid.Column>
          </Grid.Row>
        </FormButtonsWrapper>
      </form>
    );
  };

  return (
    <UpsertContentWrapperDiv>
      {formDataLoaded
        ? <React.Fragment>
          {renderFinalForm()}
        </React.Fragment>
        : <LoaderComponent message={t('testTemplate.loading')} />
      }
    </UpsertContentWrapperDiv>
  );
};

export default UpsertPatientTestingForm;