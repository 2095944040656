import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {

  min-height: 100vh;
  background-color: #f1f2f7;
  font-family: 'Manrope', sans-serif;

  .title-h1 {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    
    .addition {
      color: #5a5d74;
    }
  }

  .table-title {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .sub-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #767a95;

    & > .normal-text {
      font-weight: 300;
    }
  }

  .ui.button {
    font-family: 'Manrope', sans-serif;
  }

  .ui.large.button {
    padding: 0.7rem 4rem 0.7rem;
    font-weight: 500;
  }

  .ui.primary.button, .ui.primary.buttons .button {
    background-color: #5628ee;

    :active {
      background-color: #5628ee;
    }

    :hover { // we should decide for the hover color
      background-color: #5628ee;
    }

    :focus {
      background-color: #5628ee;
    }

    &.logout-btn {
      padding: .2rem 1.8rem .2rem !important;
      background-color: transparent;
      border: 1px solid #000;
      color: #000;

      :active {
        background-color: transparent;
        border-color: #000;
      }

      :hover {
        background-color: transparent;
        border-color: #000;
        text-decoration: underline;
      }

      :focus {
        background-color: transparent;
        border-color: #000;
      }
    }
  }

  .field > .ui.input {
    width: 100%;
    height: 100%;
  }

  .ui.input > input {
    border: 1px solid #ebeef2;
    background-color: #fefeff;
    padding: .68rem 0.675rem;
    border-radius: 5px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #767a95;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #767a95;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #767a95;
    }

    & > i.icon {
      color: #767a95;
    }
  }

  .ui.input.error > input {
    background-color: #fff6f6;
    border-color: #9f3a38;
    color: #9f3a38;
    box-shadow: none;
  }

  .ui.disabled.input > input {
    opacity: .45;
  }

  .ui.input.focus > input,
  .ui.input > input:focus {
    border: 1px solid #ebeef2;
    background-color: #fefeff;
  }

  .ui.disabled.input {
    opacity: 1;
  }

  label {
    color: #000000;
    font-size: 1.1589rem;
  }

  .ui.selection.dropdown, &.ui.selection.active.dropdown .menu {
    border: 1px solid #ebeef2;
    background-color: #fefeff;
    border-radius: 5px;
    box-shadow: unset;
    width: 100%;
    color: #767a95;
  }
  
  .ui.error.selection.dropdown {
    background-color: #fff6f6;
    border-color: #9f3a38;
    color: #9f3a38;
    box-shadow: none;
  }

  .ui.active.selection.dropdown {
    background-color: #FFFFFF;
  }

  .ui.selection.active.dropdown .menu {
    border-color: #ebeef2;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #5628ee !important;
  }

  .ui.form textarea {
    border: 1px solid #ebeef2;
    background-color: #fefeff;
    padding: 0.68rem 0.675rem;
    border-radius: 5px;

    &:focus {
      background-color: #fefeff;
    }
  }

  .ui.menu {
    font-family: 'Manrope', sans-serif;
  }
  
  .ui.header {
    font-family: 'Manrope', sans-serif;
  }

  .search-form {
    padding-left: 0.75rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      margin-right: 1rem;
    }

    .ui.input {
      min-width: 20rem;
    }

    button, a {
      margin-left: 1rem;
    }

    .checkbox {
      margin-left: 1rem;
    }
  }

  .search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    & .title-h1 {
      display: inline-block;
      margin-bottom: unset;
      padding-left: 0px !important;
    }

    & .search-form {
      margin-bottom: unset;
    }
  }

  .data-table {
    flex: 1 1 auto;
    min-height: 120px;

    .row-actions {
      i.icon,
      i.icons {
        color: #768aff;
      }
    }

    .table-loader.ui.inline.loader.active,
    .table-loader.ui.inline.loader.visible {
      display: block;
      top: 5rem;
    }
  }
   
    .button-row-container {
      padding-top: 0px !important;
      text-align: right;
    }
    
  .button-column-container > .ui.button.action-button {
    margin-top: 1rem !important;
  }
  
  .button-column-container > .ui.button {
    margin-bottom: 1rem !important;
  }
  
  .button-column-container {
    padding-right: 0px
  }
    
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .title-h1 {
      font-size: 1.5rem;
      margin-bottom: 1.2rem;
    }

    .table-title {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
  }
    
    .single-line-cell-class {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
      
      .un-stackable-cell-class {
          padding-inline: 3px !important;
      }
    
    .total-text {
      color: #767a95;
      margin-left: 1rem;
      font-weight: 600;
    }
  }

  .checkmark {
    padding-left: 2rem;
    color: var(--primary-color)
  }

  .no-select {
      user-select: none !important;
      -webkit-user-select: none !important;
      -ms-user-select: none !important;
      -moz-user-select: none !important;
  }
`;

export default GlobalStyle;