import { useAuthContext } from 'auth/AuthContext';
import axios from 'axios';
import CompositeButton from 'components/final-form/CompositeButton';
import InnerTableActionButton from 'components/InnerTableActionButton';
import VirtualizedTable from 'components/VirtualizedTable';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React, { useEffect, useMemo, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { getPatient } from 'service/patientService';
import { getRecentTestConfActivities } from 'service/patientTestingService';
import { PatientTestingActionType } from 'ts-types/api.enums';
import { PatientTestingActivityDto } from 'ts-types/api.types';
import { isoToDisplayDateTime } from 'util/dateUtils';
import { shortLabel } from 'util/tableUtils';

const TestConfSearchRecentView = () => {

  const cancelTokenSource = axios.CancelToken.source();
  const { state } = useLocation();
  const { push } = useHistory();
  const { t } = useTranslation('teresa');
  const { currentUser } = useAuthContext();

  const isIpad = useIsIpadWidthOrBelow();
  const isIphone = useIsIpadWidthOrBelow(430);

  const testConfId: number | undefined = state.testConfId ? Number(state.testConfId) : undefined;
  const testDuration: string | undefined = state?.testDuration ? String(state?.testDuration) : undefined;

  const [activities, setActivities] = useState<PatientTestingActivityDto[]>([]);

  useEffect(() => {
    testConfId && getRecentTestConfActivities(testConfId, cancelTokenSource).then(r => setActivities(r));
  }, []);

  const dateTimeCellRenderer = ({ rowData }: any) => {
    return <div>{isoToDisplayDateTime(rowData.activityDate)}</div>;
  };

  const doctorCellRenderer = ({ rowData }: any) => {
    return (
      <div>{`${rowData.doctor}`}</div>
    );
  };

  const getProgressLabel = () => {
    return (
      isIpad
        ? shortLabel(t('patient.details.testProgress'), t('patient.details.shortTestProgress'), true)
        : t('patient.details.testProgress')
    );
  };

  const testProgressCellRenderer = ({ rowData }: any) => {
    const testProgress = rowData.testProgress;
    const formattedTestProgress = rowData.testProgress ? rowData.testProgress.split('(')[0] : null;
    return (
      <div>{!testProgress ? 'n/a' : isIpad ? formattedTestProgress : testProgress}</div>
    );
  };


  const actionsCellRenderer = (data: any) => {
    const {
      rowData: {
        patientId, patientTestingId, patientTestingQuestionSetId, questionSetId, hasAnswers, hasTestResult, onlyQuestions,
      },
    } = data;

    const renderReportButton = (showAnyway: boolean, divider: boolean) => {
      return (showAnyway || (hasAnswers || hasTestResult)) && (
        <InnerTableActionButton
          message={t(`patientTesting.actionType.${PatientTestingActionType.CREATE_REPORT}`)}
          onConfirm={() => {
            getPatient(patientId, cancelTokenSource).then(patient =>
              push('/preview-results', {
                testDuration: testDuration,
                patientTestingQuestionSetId: patientTestingQuestionSetId,
                patientTestingId: patientTestingId,
                questionSetId: questionSetId,
                patient: patient,
                prevPath: '/extended-tests-search',
                clinicId: state.clinicId,
              })
            );
          }}
          divider={divider}
        />
      );
    };

    const renderExportButton = (isDeleteButtonExisting: boolean) => {
      return (
        !isIpad && !isMobile && !isTablet &&
        <InnerTableActionButton
          message={t('previewResult.export')}
          onConfirm={() => push('/export-testing', {
            patientId: patientId,
            patientTestingId: patientTestingId,
            prevPath: '/extended-tests-search',
            clinicId: state.clinicId,
          })}
          divider={isDeleteButtonExisting}
        />
      );
    };


    return (
      <div className='row-actions'>
        {!onlyQuestions && <InnerTableActionButton
          message={t('button.edit')}
          onConfirm={() => push('/start-testing', {
            patientTestingId: patientTestingId,
            patientTestingQuestionSetId: patientTestingQuestionSetId,
            prevPath: '/extended-tests-search',
            clinicId: state.clinicId,
          })}
          divider={true}
        />}
        {renderReportButton(true, !isIpad)}
        {renderExportButton(false)}
      </div>
    );
  };

  const activitiesRowGetter = ({ index }: any) => {

    if (activities.length > 0) {
      Object.assign(activities[index], { index: index + 1 });

      return activities[index];
    }
  };

  const activitiesRowRenderer = ({ className, columns, index, key, style }: any) => {
    const a11yProps = { 'aria-rowindex': index + 1 };

    return (
      <div
        {...a11yProps}
        className={className}
        key={key}
        role='row'
        style={style}
      >
        {columns}
      </div>
    );
  };

  const renderActivities = (): JSX.Element => {

    let columns: React.ReactNode[] = [
      {
        width: isIphone ? 115 : 150,
        flexShrink: 0,
        label: t('dashboard.dateTime'),
        dataKey: 'patientTestingActivityDate',
        cellRenderer: dateTimeCellRenderer,
      },
    ];

    if (!isIphone) {
      columns.push(
        {
          width: isIpad ? 30 : 100,
          flexGrow: 1,
          label: t('sidebar.user.roles.ROLE_DOCTOR'),
          dataKey: 'doctor',
          cellRenderer: doctorCellRenderer,
        },
      );
    }

    columns.push(
      {
        width: isIpad ? 30 : 100,
        flexGrow: 1,
        label: (isIpad) ? t('patient.originIdShort') : t('patient.originId'),
        dataKey: 'patient',
      },
      {
        width: isIpad ? 5 : 150,
        flexGrow: 1,
        label: getProgressLabel(),
        dataKey: 'testProgress',
        cellRenderer: testProgressCellRenderer,
      },
      {
        width: isIphone ? 150 : isIpad ? 309 : 300,
        label: t('patient.action'),
        dataKey: 'actionType',
        cellRenderer: actionsCellRenderer,
      },
    );

    return (
      <>
        <div id='patientDetailsTable' className='data-table'>
          <VirtualizedTable
            rowCount={activities.length}
            rowGetter={activitiesRowGetter}
            rowRender={activitiesRowRenderer}
            rowHeight={48}
            columns={columns}
          />
        </div>
        <div style={{marginBlock: '10px'}}>
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign={'right'}>
              <div className='page-actions'>
                <CompositeButton
                  type='button'
                  className='action-button'
                  secondary
                  onClick={() => push('/extended-tests-search')}
                  style={{ marginLeft: 'auto', marginRight: 0 }}
                >
                  {t('action.back')}
                </CompositeButton>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </div>
      </>
    );
  };

  return (
    <>
      {renderActivities()}
    </>
  );
};
export default TestConfSearchRecentView;