import CompositeButton from 'components/final-form/CompositeButton';
import DataLabel from 'components/final-form/DataLabel';
import InnerTableActionButton from 'components/InnerTableActionButton';
import TsaGrid from 'components/TsaGrid';
import useIsIpadWidthOrBelow from 'hooks/useIsIpadWidthOrBelow';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, Header, Input, InputOnChangeData, Modal, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { TriggerAccountActivationRequest } from 'ts-types/api.types';

const StyledPopupContentText = styled.div`
  margin-top: -35px;

  .ui.input > input {
    border: 1px solid var(--very-very-light-blue);
    background-color: white;
    padding: .68rem 0.675rem;
    border-radius: 5px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--dark-gray);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--dark-gray);
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: var(--dark-gray);
    }

    & > i.icon {
      color: var(--dark-gray);
    }
  }

  .ui.input.error > input {
    background-color: var(--very-very-light-red);
    border-color: var(--dark-red);
    color: var(--dark-red);
    box-shadow: none;
  }
`;

const StyledContainer = styled.div`
  .ui.button {
    font-family: 'Manrope', sans-serif;
    padding: 0.7rem 4rem 0.7rem !important;
  }

  & > .ui.large.button {
    font-weight: 500;
  }

  .ui.primary.button, .ui.primary.buttons .button {
    background-color: var(--primary-color);

    :active {
      background-color: var(--primary-color);
    }

    :hover { // we should decide for the hover color
      background-color: var(--primary-color);
    }

    :focus {
      background-color: var(--primary-color);
    }
  }

  .ui.secondary.button, .ui.secondary.buttons .button {
    background-color: var(--secondary-color);
  }

  .admin-label {
    font-size: 11px;
  }

  &.custom-border {
    position: relative;
    margin-right: 5px;
  }

  &.custom-border:after {
    content: " ";
    position: absolute;
    border-left: 1px var(--light-blue) solid;
    top: 25%;
    right: 0;
    height: 45%;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const VeryLightBlueGridRow = styled(Grid.Row)`
  color: var(--light-blue);
  margin-top: -20px;
`;

const PopupStyle = {
  borderRadius: 'unset',
  minWidth: '400px',
  padding: '15px',
};

interface Props {
  disabled?: boolean,
  triggerButtonText: string,
  position?:
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center',
  onOpen?: () => void,
  content: {
    id: number
    resend: boolean,
    func: (id: number, request: Partial<TriggerAccountActivationRequest>) => void,
    email?: string,
  },
  divider?: boolean,
  tableButton: boolean,
  displayModal?: boolean,
}

const SendActivationKeyPopup = (props: Props) => {

  const {
    disabled = false,
    triggerButtonText,
    onOpen,
    position,
    content,
    divider = false,
    tableButton,
    displayModal,
  } = props;
  const { func } = content;
  const { t } = useTranslation('teresa');
  const isIphone = useIsIpadWidthOrBelow(430);

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>(content.email);

  const setInputEmail = (evt: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    setEmail(data.value);
  };

  const popupTrigger = () => {
    return (
      !tableButton
        ? <CompositeButton
          type='button'
          className='action-button'
          primary
          onClick={() => togglePopup()}
        >
          {t(triggerButtonText)}
        </CompositeButton>
        : <InnerTableActionButton
            message={t(triggerButtonText)}
            onConfirm={togglePopup}
            divider={divider}
        />
    );
  };

  const togglePopup = () => {
    setPopupOpen(!popupOpen);
  };

  const renderContent = () => {
    return (
      <StyledContainer>
      <TsaGrid>
        <Grid.Row>
            <Grid.Column>
              <Header as='h4' color={'grey'}>
                {t('admin.activateAccountEmail')}
              </Header>
            </Grid.Column>
        </Grid.Row>
        <VeryLightBlueGridRow style={{ marginTop: '-20px' }}>
            <Grid.Column width={3} verticalAlign='middle'>
              <DataLabel>
                <span className='admin-label'>{t('admin.email')}</span>
              </DataLabel>
            </Grid.Column>
          </VeryLightBlueGridRow>
          <Grid.Row>
            <Grid.Column width={16} verticalAlign='middle'>
              <StyledPopupContentText>
                <Input
                  type='email'
                  placeholder=''
                  value={email}
                  onChange={setInputEmail}
                  fluid
                />
              </StyledPopupContentText>
            </Grid.Column>
          </Grid.Row>


        <Grid.Row style={{ marginTop: '-10px' }}>
          <Grid.Column width={8}>
            <Button
              primary
              type='button'
              className='action-button'
              size='small'
              floated='left'
              onClick={() => {
                const req = {
                  email: email,
                };
                func(content.id, req);
                togglePopup();
              }}
            >
              {t('admin.send')}
            </Button>
          </Grid.Column>
          <Grid.Column width={8}>
            <Button
              type='button'
              floated='right'
              className='action-button'
              size='small'
              secondary
              onClick={togglePopup}
            >
              {t('action.cancel')}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </TsaGrid>
      </StyledContainer>
    );
  };


  return (
    <React.Fragment>
      {displayModal
        ? popupTrigger()
        : <Popup
          style={PopupStyle}
          trigger={popupTrigger()}
          content={renderContent}
          open={popupOpen}
          onClose={() => !disabled && togglePopup()}
          onOpen={() => {
            if (!disabled) {
              togglePopup();

              if (onOpen) {
                onOpen();
              }
            }
          }}
          on='click'
          position={position || 'top center'}
        />
      }
      {displayModal &&
        <Modal
          open={popupOpen}
          style={isIphone ? {width: '25rem', borderRadius: '5px'} : { width: '40rem', borderRadius: '5px' }}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            {renderContent()}
          </Modal.Content>
        </Modal>
      }
    </React.Fragment>
  );
};

export default SendActivationKeyPopup;